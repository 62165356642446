import "./App.css";
import "./App.sass";

import { Component, Suspense } from "react";
import { withTranslation } from "react-i18next";

import { Navbar } from "./components/Navbar";
import { AssignmentContextProvider } from "./context/AssignmentContextProvider";
import { ExerciseContextProvider } from "./context/ExerciseContextProvider";
import { ModelContextProvider } from "./context/ModelContextProvider";
import ModelEditorModalContextProvider from "./context/ModelEditorModalContextProvider";
import { SchemeContextProvider } from "./context/SchemeContextProvider";
import { SubmissionContextProvider } from "./context/SubmissionContextProvider";
import { NewRoutes } from "./routes/Routes";

// i18n translations might still be loaded by the http backend, therefore use react's Suspense
class App extends Component {
  render() {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <AssignmentContextProvider>
          <SchemeContextProvider>
            <ExerciseContextProvider>
              <ModelContextProvider>
                <ModelEditorModalContextProvider>
                  <SubmissionContextProvider>
                    {/*
                    {localStorage.getItem("unique-token") == null ? (
                      <>
                        <Route exact path="/login" component={SignIn} />
                        <Route
                          exact
                          path="/"
                          render={() => <Redirect exact from="/" to="/login" />}
                        />
                      </>
                    ) : (
                      <div>
                        <Navbar />

                        <Switch>
                          <Routes />
                        </Switch>
                      </div>
                    )}
                    */}
                    <div>
                      <Navbar />

                      <NewRoutes />
                    </div>
                  </SubmissionContextProvider>
                </ModelEditorModalContextProvider>
              </ModelContextProvider>
            </ExerciseContextProvider>
          </SchemeContextProvider>
        </AssignmentContextProvider>
      </Suspense>
    );
  }
}

export default withTranslation()(App);
