// @flow

import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { b64DecodeUnicode, b64EncodeUnicode } from "../../../shared/b64";
import { AnswerField } from "../ExerciseFields/AnswerField";
import { AnswerList } from "./AnswerList";
import { LanguageField } from "../ExerciseFields/LanguageField";
import { NameField } from "../ExerciseFields/NameField";
import { QuestionField } from "../ExerciseFields/QuestionField";
import { SchemeField } from "../ExerciseFields/SchemeField";
import { FeedUpField } from "../ExerciseFields/FeedUpField";
import { FeedForwardPosField } from "../ExerciseFields/FeedForwardPosField";
import { FeedForwardNegField } from "../ExerciseFields/FeedForwardNegField";
import { emptyExercise } from "../../../types/Exercise";
import { useExerciseContext } from "../../../context/ExerciseContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Tooltip from "@kea-mod/common/tooltip";
import { ModelList } from "../../../pages/Modellist";
import MDEditor from "@uiw/react-md-editor";

type Props = {
  activeExercise: {
    creation: any;
    description: any;
    language: any;
    maxTotalScore: any;
    feedUp: any;
    feedForward_neg: any;
    feedForward_pos: any;
    feedForward_threshold: any;
  };
  activeAnswers: Object;
  activeModels: any;
  assignmentId: number;
};

export const EditExerciseUnderstandingComponent = (props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  let { id } = useParams();
  const exerciseContext = useExerciseContext();
  const [activeExercise, setActiveExercise] = useState<any>(emptyExercise);
  const [description, setDescription] = useState("");
  const [language, setLanguage] = useState("");
  const [answer, setAnswer] = useState("");
  const [isCorrect, setIsCorrect] = useState(false);
  const [maxScore, setMaxScore] = useState("0");
  const [feedForwardThreshold, setFeedForwardThreshold] = useState("");

  useEffect(() => {
    exerciseContext.all();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setActiveExercise(props.activeExercise);
    setDescription(b64DecodeUnicode(props.activeExercise.description));
    setLanguage(props.activeExercise.language.name);
    setMaxScore(props.activeExercise.maxTotalScore);
    let threshold = props.activeExercise.feedForward_threshold;
    if (threshold !== null) {
      setFeedForwardThreshold(threshold);
    }
  }, [props.activeExercise]);

  const handleChangeAnswer = (event: any) => {
    setAnswer(event.target.value);
  };

  const handleCheckAnswer = () => {
    setIsCorrect(!isCorrect);
  };

  const handleChangeLanguage = (event: any) => {
    setLanguage(event.target.value);
  };

  const handleChangeQuestion = (event: any) => {
    let value = event.target.value;

    setActiveExercise({
      ...activeExercise,
      understanding: { ...activeExercise.understanding, question: value },
    });
  };

  const handleChange = (event: any) => {
    let value = event.target.value;

    setActiveExercise({
      ...activeExercise,
      [event.target.name]: value,
    });
  };

  const handleChangeDescription = (event: any) => {
    setDescription(event);
  };

  const addAnswer = () => {
    let id = parseInt(activeExercise.id);

    if (answer.trim() === "") {
      alert("Please make sure that the field answer is not empty!");
    } else {
      let data = {
        answer: [
          {
            text: answer,
            isCorrect: isCorrect,
          },
        ],
      };
      exerciseContext.createAnswers(id, data);
      setAnswer("");
      setIsCorrect(false);
    }
  };

  const handleCheckboxChange = (event: any) => {
    setActiveExercise({
      ...activeExercise,
      [event.target.name]: event.target.checked,
    });
  };

  const handleChangeThreshold = (event: any) => {
    let value = event.target.value;
    setFeedForwardThreshold(value);
  };

  const addFeedFields = (data: any) => {
    //only add fields to data if not empty
    if (activeExercise.feedUp !== "") {
      data.feedUp = activeExercise.feedUp;
    }

    if (activeExercise.feedForward_pos !== "") {
      data.feedForward_pos = activeExercise.feedForward_pos;
    }

    if (activeExercise.feedForward_neg !== "") {
      data.feedForward_neg = activeExercise.feedForward_neg;
    }

    if (feedForwardThreshold !== "") {
      data.feedForward_threshold = parseFloat(feedForwardThreshold);
    }
  };

  const updateExercise = async () => {
    if (
      activeExercise.name.trim() === "" ||
      description.trim() === "" ||
      activeExercise.understanding.question.trim() === ""
    ) {
      alert("Please make sure that all fields have been filled out!");
    } else {
      let data = {
        name: activeExercise.name,
        type: "understanding",
        description: b64EncodeUnicode(description),
        language: language,
        understanding: { question: activeExercise.understanding.question },
        schemaId: parseInt(activeExercise.schemaId),
        assignmentId: props.assignmentId,
        directFeedback: activeExercise.directFeedback,
        maxTotalScore: maxScore,
      };

      addFeedFields(data);

      await exerciseContext.update(parseInt(activeExercise.id), data);

      navigate("/assignments/" + props.assignmentId);
    }
  };

  const goBackToAssignment = () => {
    navigate("/assignments/" + props.assignmentId);
  };
  function onChangeMaxScore(e: any) {
    let val = e.target.value;
    const v = e.target.value;
    const myArray = e.target.value.split(".");

    if (myArray[0].toString().length >= 11) {
      return;
    }
    if (myArray.length !== 1 && myArray[1].toString().length >= 3) {
      return;
    }

    if (e.target.validity.valid) {
      val = val.replace(/^0+/, "");
      if (val === "") {
        val = v;
      }

      if (val.startsWith(".")) {
        val = "0" + val;
      }

      setMaxScore(val);
    } else if (val === "" || val === "-") {
      val = val.replace(/^0+/, "");
      if (val === "") {
        val = v;
      }

      if (val.startsWith(".")) {
        val = "0" + val;
      }
      setMaxScore(val);
    }
  }
  let ScoreField = (
    <div>
      <div>
        <input
          size={5}
          type="text"
          className="input"
          value={maxScore}
          onChange={onChangeMaxScore}
          pattern="^[-+]?\d*\.?\d*$"
        />
      </div>
    </div>
  );

  return (
    <div className="container mt-6">
      <div className="section">
        <div className="columns is-centered">
          <div className="column is-three-quarters">
            <div className="box">
              <h4 className="title is-4">{t("exerciseModelUnderstanding")}</h4>
              <NameField activeExercise={activeExercise} h={handleChange} />
              <LanguageField language={language} h={handleChangeLanguage} />
              <div className="field">
                <label className="label" htmlFor="description">
                  {t("description")}
                  <Tooltip
                    icon="circle-info"
                    class="icon tooltip no-border is-white   has-tooltip-multiline has-tooltip-top"
                    content={t("descriptionMCTooltip")}
                  ></Tooltip>
                </label>
                <div data-color-mode="light">
                  <MDEditor height={250} value={description} onChange={handleChangeDescription} />
                </div>
              </div>
              <SchemeField activeExercise={activeExercise} h={handleChange} />
              <FeedUpField activeExercise={activeExercise} h={handleChange} />
              <FeedForwardPosField activeExercise={activeExercise} h={handleChange} />
              <FeedForwardNegField activeExercise={activeExercise} h={handleChange} />
              <div className="field">
                <label className="label" htmlFor="feedForward_threshold">
                  {t("feedForward_threshold")}
                </label>
                <input
                  type="text"
                  className="input"
                  id="feedForward_threshold"
                  name="feedForward_threshold"
                  value={feedForwardThreshold}
                  onChange={handleChangeThreshold}
                />
              </div>
              <div className="field">
                <div>
                  <div className="label">
                    {t("autoAssessmentEx")}
                    <Tooltip
                      icon="circle-info"
                      class="icon tooltip no-border is-white   has-tooltip-multiline has-tooltip-top"
                      content={t("autoAssessmentExDesc")}
                    ></Tooltip>
                  </div>
                </div>
                <input
                  name="directFeedback"
                  type="checkbox"
                  checked={activeExercise.directFeedback}
                  onChange={handleCheckboxChange}
                />
              </div>
              <div className="field">
                <div className="label">
                  {t("MaxScore")}
                  <Tooltip
                    icon="circle-info"
                    class="icon tooltip no-border is-white   has-tooltip-multiline has-tooltip-top"
                    content={t("maxscoreTooltip")}
                  ></Tooltip>
                </div>
                <div>{ScoreField}</div>{" "}
              </div>

              <div className="field">
                <label className="label" htmlFor="title">
                  {t("inputModel")}
                  <Tooltip
                    icon="circle-info"
                    class="icon tooltip no-border is-white   has-tooltip-multiline has-tooltip-top"
                    content={t("inputmodelTooltip")}
                  ></Tooltip>
                </label>
                <ModelList
                  modelList={props.activeModels}
                  modelingLanguage={language}
                  exerciseId={id}
                  exerciseType={activeExercise.type}
                  modelType={"input"}
                />
              </div>
              <QuestionField activeExercise={activeExercise} h={handleChangeQuestion} />
              <label className="label" htmlFor="answer">
                {t("answer")}
                <Tooltip
                  icon="circle-info"
                  class="icon tooltip no-border is-white   has-tooltip-multiline has-tooltip-top"
                  content={t("answerTooltip")}
                ></Tooltip>
              </label>
              <AnswerList answers={props.activeAnswers} exerciseId={activeExercise.id} />
              <AnswerField
                answer={answer}
                isCorrect={isCorrect}
                handleCheckAnswer={handleCheckAnswer}
                handleChangeAnswer={handleChangeAnswer}
                handleAddAnswer={addAnswer}
              />

              <div className="columns mt-5">
                <button className="button is-danger" onClick={goBackToAssignment}>
                  {t("cancel")}
                </button>
                <button className="button is-success" onClick={updateExercise}>
                  {t("save")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
