import http from "../http-common";

class AssessmentDataService {
  update(id, data) {
    return http.put(`/authorui/assessments/${id}`, data);
  }

  get(id) {
    return http.get(`/authorui/assessments/${id}`);
  }
  create(data) {
    return http.post("/authorui/assessments", data);
  }
  delete(id) {
    return http.delete(`/authorui/assessments/${id}`);
  }
}

export default new AssessmentDataService();
