// @flow

import { useState } from "react";

import { SubmissionContext } from "./SubmissionContext";
import SubmissionExerciseDataService from "../axios/services/submission-exercises.service";

interface Props {
  children: any;
}

export const SubmissionContextProvider = (props: Props): any => {
  const [submissions, setSubmissions] = useState([]);
  const [submissionsForExercise, setSubmissionsForExercise] = useState([]);
  const [submissionsForUser, setSubmissionsForUser] = useState([]);
  const [currentUser, setCurrentUser] = useState<string>("");
  const [assessment, setAssessment] = useState([]);
  const [recent_assessment, setRecent_assessment] = useState(-1);

  const startAssessment = (id: any) => {
    SubmissionExerciseDataService.create(id)
      .then((response: any) => {
        if (response.status !== 200) return;
        console.log(response.data);
      })
      .catch((e: any) => {
        console.log(e);
      });
  };
  const setRecentAssessment = (data: any) => {
    var date = "";
    var asID = -1;
    for (let i = 0; i < data.length; i++) {
      var ass = data[i];
      var status = ass.status;
      if (status !== "complete") {
        continue;
      }
      if (asID === -1 || ass.createdAt >= date) {
        asID = ass.id;
        date = ass.createdAt;
      }
    }

    setRecent_assessment(asID);
  };

  const getAssessment = async (id: Object): Promise<Object> => {
    return new Promise<Object>((resolve, reject) => {
      SubmissionExerciseDataService.get(id).then((response: any) => {
        if (response.status !== 200) return;
        setAssessment(response.data.data);
        setRecentAssessment(response.data.data);

        if (response.data.success) {
          return resolve(response.data.data);
        } else {
          /** TODO SHOULD HANDLE UNSUCCESSFULL FETCHING EXERCISES HERE. */
          reject({});
        }
      });
    });
  };

  const getAllSubmissions = (assignmentId: any) => {
    SubmissionExerciseDataService.getAllSubmissions(assignmentId)
      .then((response: any) => {
        if (response.status !== 200) return;
        setSubmissions(response.data.data);
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  const getAllSubmissionsForUser = (assignmentId: any, userId: any): Promise<Object> => {
    return new Promise<Object>((resolve, reject) => {
      SubmissionExerciseDataService.getAllSubmissionsForUser(assignmentId, userId).then((response: any) => {
        if (response.status !== 200) return;

        var submissionExercise = response.data.data[0].submissionExercise;
        var sortedByExerciseId = submissionExercise.sort((subm1: any, subm2: any) => {
          if (subm1.exerciseId > subm2.exerciseId) {
            return 1;
          }
          if (subm1.exerciseId < subm2.exerciseId) {
            return -1;
          }
          return 0;
        });

        setSubmissionsForUser(sortedByExerciseId);
        
        setCurrentUser(response.data.data[0].user.token);
        if (response.data.success) {
          return resolve(sortedByExerciseId);
        } else {
          /** TODO SHOULD HANDLE UNSUCCESSFULL FETCHING EXERCISES HERE. */
          reject({});
        }
      });
    });
  };

  const getAllSubmissionsForExercise = (id: any): Promise<Object> => {
    return new Promise<Object>((resolve, reject) => {
      SubmissionExerciseDataService.getAllSubmissionsForExercise(id).then((response: any) => {
        if (response.status !== 200) return;
        setSubmissionsForExercise(response.data.data);
        if (response.data.success) {
          return resolve(response.data.data);
        } else {
          /** TODO SHOULD HANDLE UNSUCCESSFULL FETCHING EXERCISES HERE. */
          reject({});
        }
      });
    });
  };

  const getNumberOfSubmissions = async (id: Object): Promise<Object> => {
    return new Promise<Object>((resolve, reject) => {
      SubmissionExerciseDataService.getAllSubmissionsForExercise(id).then((response: any) => {
        if (response.status !== 200) return;
        if (response.data.success) {
          return resolve(response.data.data.length);
        } else {
          /** TODO SHOULD HANDLE UNSUCCESSFULL FETCHING SUBMISSIONS HERE. */
          reject({});
        }
      });
    });
  };

  return (
    <SubmissionContext.Provider
      value={{
        submissions: submissions,
        submissionsForExercise: submissionsForExercise,
        submissionsForUser: submissionsForUser,
        currentUser: currentUser,
        assessment: assessment,
        recent_assessment: recent_assessment,
        getAllSubmissions: getAllSubmissions,
        getAllSubmissionsForExercise: getAllSubmissionsForExercise,
        getAllSubmissionsForUser: getAllSubmissionsForUser,
        getNumberOfSubmissions: getNumberOfSubmissions,
        startAssessment: startAssessment,
        getAssessment: getAssessment,
      }}
    >
      {props.children}
    </SubmissionContext.Provider>
  );
};
