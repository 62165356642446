import { createContext } from "react";

// Stelle sicher, dass die Form des Standardwertes, welcher an createContext
// überreicht wird mit der Form die der Konsument erwartet, übereinstimmt!
export const ModellingContext = createContext({
  token: "-1",
  modelType: "-1",
  setToken: (_a: any) => {},
  setModelType: (_a: any) => {},
  toggleModal: () => {},
});
