// @flow

import { useParams } from "react-router";
import { EditAssignmentContainer } from "../components/Assignments/EditAssignmentContainer";

export const EditAssignmentPage = () => {
  let { id } = useParams();
  const c = {
    id: id,
    // make sure all required component's inputs/Props keys&types match
  } as any;
  return <EditAssignmentContainer {...c} />;
};
