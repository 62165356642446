// @flow

import { createContext, useContext } from "react";
import type { Exercise } from "../types/Exercise";
import type { Answer } from "../types/Answer";
import { emptyExercise } from "../types/Exercise";
import { emptyAnswer } from "../types/Answer";

type Context = {
  exercises: any[];
  exercisesInAssignment: any[];
  exercise: Exercise;
  id: number;
  answers: Answer[];
  answer: Answer;
  all: () => void;
  filterExercises: (id: number) => void;
  deleteExercise: (id: number) => void;
  create: (data: Object) => Promise<number>;
  createExerciseForAssignment: (asid: number, data: Object) => Promise<number>;
  get: (id: number) => Promise<Exercise>;
  update: (id: number, data: Object) => Promise<number>;
  createAnswers: (id: number, data: Object) => void;
  updateAllAnswers: (id: number) => void;
  updateAnswer: (id: number, answerid: number, data: any) => void;
  deleteAnswer: (id: number, answerid: number) => void;
};

const defaultState: Context = {
  exercises: [],
  exercisesInAssignment: [],
  exercise: emptyExercise,
  id: -1,
  answers: [],
  answer: emptyAnswer,
  all: () => console.warn("ExerciseContext: not in scope."),
  filterExercises: (_id: any) => console.warn("ExerciseContext: not in scope."),
  deleteExercise: (_id: any) => console.warn("ExerciseContext: not in scope."),
  create: (_data: any) =>
    new Promise((resolve, _reject: any) => {
      resolve(-1);
    }),
  get: (_id: any) =>
    new Promise((resolve, _reject: any) => {
      resolve(emptyExercise);
    }),
  createExerciseForAssignment: (_id: any, _data: any) =>
    new Promise((resolve, _reject: any) => {
      resolve(-1);
    }),
  update: (_id, _data) =>
    new Promise((resolve, _reject) => {
      resolve(-1);
    }),
  createAnswers: (_id, _data) => console.warn("ExerciseContext: not in scope."),
  updateAllAnswers: (_id) => console.warn("ExerciseContext: not in scope."),
  updateAnswer: (_exid, _answId, _data) => console.warn("ExerciseContext: not in scope."),
  deleteAnswer: (_exid, _answId) => console.warn("ExerciseContext: not in scope."),
};

export const ExerciseContext = createContext<Context>(defaultState);

export const useExerciseContext = () => useContext(ExerciseContext);
