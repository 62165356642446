import Tooltip from "@kea-mod/common/tooltip";
import { emptyAssessment } from "@kea-mod/common/types/Assessment";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaExclamationTriangle } from "react-icons/fa";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import AssessmentDataService from "../../axios/services/assessment.service";
import FeedbackDataService from "../../axios/services/feedback.service";
import SubmissionExerciseDataService from "../../axios/services/submission-exercises.service";
import { CommentField } from "./AssessmentFields/CommentField";
import { FeedbackCreation } from "./AssessmentFields/FeedbackCreation";
import { FeedbackEdit } from "./AssessmentFields/FeedbackEdit";
import { MaxscoreField } from "./AssessmentFields/MaxscoreField";
import { TotalscoreField } from "./AssessmentFields/TotalscoreField";

export const ManualAssessment = (): any => {
  const { t } = useTranslation();
  let { subexid, exerciseId } = useParams();

  const navigate = useNavigate();
  const [assessment, setAssessment] = useState(emptyAssessment);
  const [feedbacklist, setFeedbacklist] = useState<any>([]);
  const [manualFeedback, setManualFeedback] = useState<any>([]);
  const [realFeedback, setRealFeedback] = useState<any>([]);
  const [deleteList, setDeleteList] = useState([]);
  const [ManFeedToRemove, setManFeedToRemove] = useState([]);
  const [getback, setGetBack] = useState(false);
  const [multi, setMulti] = useState(false);
  const [modalState, setModalstate] = useState(false);
  const [revised, setRevised] = useState(false);
  const [modalStateEdit, setModalstateEdit] = useState(false);
  const [id, setId] = useState(-1);
  const [feedtype, setFeedtype] = useState({
    id: -1,
    content: "",
    category: "",
    valuation: "NEGATIVE",
  });
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (getback) {
      navigate(-1); // go to previous page
    }
  }, [getback, navigate, exerciseId]);

  useEffect(() => {
    SubmissionExerciseDataService.get(subexid)
      .then((response: any) => {
        //console.log(response.data.data);
        let as = response.data.data;
        if (as.comment === null) {
          as["comment"] = "";
        }
        console.log(as);
        setId(as.id);
        setAssessment(as);
        setRevised(as.isRevised);
        //console.log(as);
        setInitialFeedbacklist(as);
      })
      .catch((e: any) => {
        console.log(e);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subexid, refresh]);

  function refreshManFeedback() {
    if (manualFeedback.length > 0) {
      let manFee = manualFeedback[manualFeedback.length - 1];
      feedbacklist.push(manFee);
    }
  }

  function setInitialFeedbacklist(assessment: any) {
    var Feedbacks = [];
    var manFeed = [];
    var realfeed = [];
    for (var o = 0; o < assessment.feedbacksmanual.length; o++) {
      let manual = assessment.feedbacksmanual[o];
      manual["grader"] = "Manual Feedback";
      Feedbacks.push(manual);
      manFeed.push(manual);
    }

    for (var i = 0; i < assessment.gradings.length; i++) {
      let grading = assessment.gradings[i];

      for (var a = 0; a < grading.feedbacks.length; a++) {
        let feedback = grading.feedbacks[a];
        if (grading.gradingservice.name === "Multiple Choice") {
          setMulti(true);
        } else {
          if (!(feedback.category === "internal" && feedback.valuation === "OTHER")) {
            feedback["grader"] = grading.gradingservice.name;
            Feedbacks.push(feedback);
            realfeed.push(feedback);
          }
        }
      }
    }
    Feedbacks.sort(sortByGrader);
    setFeedbacklist(Feedbacks);
    setManualFeedback(manFeed);
    setRealFeedback(realfeed);
  }
  function back() {
    deleteAfterBack();
  }
  const saveAssessment = () => {
    let com = assessment.comment;
    if (assessment.comment === null || assessment.comment === "") {
      com = " ";
    }
    let data = {
      comment: com,
      totalscore: assessment.totalscore,
      maxTotalScore: assessment.maxTotalScore,
      isRevised: revised,
    };

    console.log(data);
    AssessmentDataService.update(id, data)

      .then((_response: any) => {
        deleteAfterSave();
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  const toggleModal = () => {
    if (modalState) {
      setRefresh(!refresh);
    }
    setModalstate(!modalState);
  };

  const toggleModalEdit = () => {
    if (modalStateEdit) {
      setRefresh(!refresh);
    }
    setModalstateEdit(!modalStateEdit);
  };

  const handleChangeComment = (event: any) => {
    let value = event.target.value;

    setAssessment({
      ...assessment,
      comment: value,
    });
  };
  function onChangeTotalScore(e: any) {
    let val = e.target.value;
    const v = e.target.value;

    if (e.target.validity.valid) {
      val = val.replace(/^0+/, "");
      if (val === "") {
        val = v;
      }

      if (val.startsWith(".")) {
        val = "0" + val;
      }
      setAssessment({
        ...assessment,
        totalscore: val,
      });
    } else if (val === "" || val === "-") {
      val = val.replace(/^0+/, "");
      if (val === "") {
        val = v;
      }

      if (val.startsWith(".")) {
        val = "0" + val;
      }
      setAssessment({
        ...assessment,
        totalscore: val,
      });
    }
  }

  function onChangeMaxScore(e: any) {
    let val = e.target.value;
    const v = e.target.value;

    if (e.target.validity.valid) {
      val = val.replace(/^0+/, "");
      if (val === "") {
        val = v;
      }

      if (val.startsWith(".")) {
        val = "0" + val;
      }
      setAssessment({
        ...assessment,
        maxTotalScore: val,
      });
    } else if (val === "" || val === "-") {
      val = val.replace(/^0+/, "");
      if (val === "") {
        val = v;
      }

      if (val.startsWith(".")) {
        val = "0" + val;
      }
      setAssessment({
        ...assessment,
        maxTotalScore: val,
      });
    }
  }

  function saveData() {
    //update data before toggling modal
    let com = assessment.comment;
    if (assessment.comment === null || assessment.comment === "") {
      com = " ";
    }

    let data = {
      comment: com,
      totalscore: assessment.totalscore,
      maxTotalScore: assessment.maxTotalScore,
      isRevised: revised,
    };

    AssessmentDataService.update(id, data).catch((e: any) => {
      console.log(e);
    });
  }

  function createFeedback() {
    saveData();
    toggleModal();
  }

  const deleteAfterBack = async () => {
    for (var i = 0; i < ManFeedToRemove.length; i++) {
      let feedObj = ManFeedToRemove[i] as any;

      await FeedbackDataService.deleteManual(feedObj.id)
        .then(() => {})
        .catch((e: any) => {
          console.log(e);
        });
      if (i === ManFeedToRemove.length - 1) {
        setGetBack(true);
      }
    }
    if (ManFeedToRemove.length === 0) {
      setGetBack(true);
    }
  };

  const deleteAfterSave = async () => {
    for (var i = 0; i < deleteList.length; i++) {
      let feedObj = deleteList[i] as any;
      //console.log(feedObj);
      if (containsObject(feedObj, manualFeedback)) {
        //console.log("hier");
        await FeedbackDataService.deleteManual(feedObj.id)
          .then((_response: any) => {})
          .catch((e: any) => {
            console.log(e);
          });
      }
      if (containsObject(feedObj, realFeedback)) {
        await FeedbackDataService.delete(feedObj.id)
          .then((_response: any) => {})
          .catch((e: any) => {
            console.log(e);
          });
      }
      if (i === deleteList.length - 1) {
        setGetBack(true);
      }
    }
    if (deleteList.length === 0) {
      setGetBack(true);
    }
  };

  function sortByGrader(a: any, b: any) {
    if (a.grader < b.grader) {
      return -1;
    }
    if (a.grader > b.grader) {
      return 1;
    }
    return 0;
  }
  function editFeedback(feedback: any) {
    setFeedtype(feedback);
    saveData();
    toggleModalEdit();
  }
  function goToExercisePage() {
    navigate("/exercises/" + exerciseId);
  }
  function switcher() {
    setRevised(!revised);
  }

  function deleteFeedback(id: any) {
    //remember the feedbacks
    let feedObj = {} as any;
    let index = 0;
    let arr = [...feedbacklist];
    for (var i = 0; i < feedbacklist.length; i++) {
      let feedback = feedbacklist[i];
      if (feedback.id === id) {
        index = i;
        feedObj = feedback;
        let deleteL = [...deleteList] as any;
        deleteL.push(feedObj);
        setDeleteList(deleteL);
        break;
      }
    }
    arr.splice(index, 1);

    setFeedbacklist(arr);
  }

  function containsObject(obj: any, list: any) {
    var i;
    for (i = 0; i < list.length; i++) {
      if (list[i] === obj) {
        return true;
      }
    }

    return false;
  }
  function getEmptyMessageSubmission() {
    return <p className="is-size-7">{t("noFeedbacks")} </p>;
  }

  function getFeedbackTableItem(feedback: any) {
    return (
      <tr key={"Feedback" + feedback.id}>
        {/* <th>{feedback.id}</th> */}
        <th>{feedback.content}</th>
        <th>{feedback.category}</th>
        <th>{feedback.valuation}</th>
        <th>{feedback.grader}</th>
        <td>
          <button
            className="button is-primary"
            disabled={feedback.grader === "Multiple Choice"}
            onClick={() => editFeedback(feedback)}
          >
            {t("edit")}
          </button>
          {/* TODO: Make this button work (define proceed action via deleteFeedback and remove button below)
          <ConfirmButton id={feedback.id}
          name={feedback.content} 
          proceedAction={} 
          question={`deleteFeedbackQuestion`} 
          explanation={`deleteFeedbackExplanation`}
          color="button is-danger"
          buttonLabel={`delete`}/>
        */}
          <button className="button is-danger" onClick={() => deleteFeedback(feedback.id)}>
            {t("delete")}
          </button>
        </td>
      </tr>
    );
  }

  function getfeedbackTable() {
    return (
      <div className="table-container">
        <table className="table is-striped is-narrow is-hoverable is-fullwidth">
          <thead className="is-italic">
            <tr>
              {/* <td>{t("id")}</td> */}
              <td>{t("content")}</td>
              <td>{t("category")}</td>
              <td>{t("valuation")}</td>
              <td>{t("gradingService")}</td>
              <td>{t("options")}</td>
            </tr>
          </thead>
          <tbody>
            {feedbacklist.map((feedback: any) => {
              return getFeedbackTableItem(feedback);
            })}
          </tbody>
        </table>
      </div>
    );
  }
  const Modal: any = ({ children, closeModal, modalState, title }: any) => {
    if (!modalState) {
      return null;
    }

    return (
      <div className="modal is-active">
        <div
          className="modal-background"
          onClick={function (_event: any) {
            closeModal();
          }}
        />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">{title}</p>
            <button
              className="delete"
              onClick={function (_event: any) {
                closeModal();
              }}
            />
          </header>
          <section className="modal-card-body">
            <div className="content">{children}</div>
          </section>
          <footer className="modal-card-foot">
            <button
              className="button is-danger"
              onClick={function (_event: any) {
                closeModal();
              }}
            >
              {t("cancel")}
            </button>
          </footer>
        </div>
      </div>
    );
  };

  Modal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    modalState: PropTypes.bool.isRequired,
    title: PropTypes.string,
  };

  const ModalEdit: any = ({ children, closeModal, modalState, title }: any) => {
    if (!modalState) {
      return null;
    }

    return (
      <div className="modal is-active">
        <div
          className="modal-background"
          onClick={function (_event: any) {
            closeModal();
          }}
        />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">{title}</p>
            <button
              className="delete"
              onClick={function (_event: any) {
                closeModal();
              }}
            />
          </header>
          <section className="modal-card-body">
            <div className="content">{children}</div>
          </section>
          <footer className="modal-card-foot">
            <button
              className="button"
              onClick={function (_event: any) {
                closeModal();
              }}
            >
              {t("cancel")}
            </button>
          </footer>
        </div>
      </div>
    );
  };

  ModalEdit.propTypes = {
    closeModal: PropTypes.func.isRequired,
    modalState: PropTypes.bool.isRequired,
    title: PropTypes.string,
  };

  let renderFeedbackTable = (
    <div>
      <div>
        <div className="columns">
          <div className="column has-text-left">
            {feedbacklist.length > 0 && getfeedbackTable()}
            {feedbacklist.length === 0 && getEmptyMessageSubmission()}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="container mt-6">
      <div className="section">
        <div className="columns is-centered">
          <div className="column is-fullwidth">
            <div className="box">
              <div className="">
                <h4 className="title is-4">{t("ManualAssessment")}</h4>
                <CommentField activeAssessment={assessment} h={handleChangeComment} />
                <TotalscoreField activeAssessment={assessment} h={onChangeTotalScore} multiple={multi} />
                <MaxscoreField activeAssessment={assessment} h={onChangeMaxScore} />
                <div className="field">
                  <label className="mt-5 label">
                    {t("isRevised")}
                    <Tooltip
                      icon="circle-info"
                      class="icon tooltip no-border is-white has-tooltip-multiline has-tooltip-top"
                      content={t("isrevisedTooltip")}
                    ></Tooltip>
                  </label>
                  <input name="autoAssessment" type="checkbox" checked={revised} onChange={() => switcher()} />
                </div>

                <div className="field">
                  <label className="mt-5 label">
                    {t("Feedback")}
                    <Tooltip
                      icon="circle-info"
                      class="icon tooltip no-border is-white   has-tooltip-multiline has-tooltip-top"
                      content={t("feedbackTooltip")}
                    ></Tooltip>
                  </label>
                </div>
                <button className="button is-info mb-5" onClick={() => createFeedback()}>
                  {t("createFeedback")}
                </button>
                <Modal closeModal={toggleModal} modalState={modalState} title={t("createFeedback")}>
                  <FeedbackCreation
                    feedbacklist={manualFeedback}
                    toggleModal={toggleModal}
                    setFeedbacklist={setManualFeedback}
                    asid={assessment.id}
                    refreshManFeedback={refreshManFeedback}
                    ManFeedToRemove={ManFeedToRemove}
                    setManFeedToRemove={setManFeedToRemove}
                    sortByGrader={sortByGrader}
                  />
                </Modal>
                <ModalEdit closeModal={toggleModalEdit} modalState={modalStateEdit} title={t("editFeedback")}>
                  <FeedbackEdit toggleModal={toggleModalEdit} feedback={feedtype} />
                </ModalEdit>
                {multi ? (
                  <div text-align="center" vertical-align="middle">
                    <FaExclamationTriangle className="has-text-danger" />
                    {t("noeditwarning1")}
                    <button
                      className="button is-link is-inverted"
                      style={{ height: "24px" }}
                      onClick={() => goToExercisePage()}
                    >
                      {t("noeditwarning2")}
                    </button>
                    {t("noeditwarning3")}
                  </div>
                ) : (
                  <div> </div>
                )}
                {renderFeedbackTable}

                <button className="mt-5 button is-danger" onClick={() => back()}>
                  {t("back")}
                </button>

                <button className="button mt-5 is-success" onClick={() => saveAssessment()}>
                  {t("save")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManualAssessment;
