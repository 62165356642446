import Tooltip from "@kea-mod/common/tooltip";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSchemeContext } from "../../../context/SchemeContext";
import type { Exercise } from "../../../types/Exercise";
import type { Scheme } from "../../../types/Scheme";

type Props = {
  activeExercise: Exercise;
  h: Function;
};

export const SchemeField = (props: Props) => {
  const schemeContext = useSchemeContext();
  const { t } = useTranslation();

  const onChange = (event: any) => {
    props.h(event);
  };

  useEffect(() => {
    schemeContext.all();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="field">
      <label className="label" htmlFor="title">
        {t("scheme")}
        <Tooltip
          icon="circle-info"
          class="icon tooltip no-border is-white   has-tooltip-multiline has-tooltip-top"
          content={t("schemeTooltip")}
        ></Tooltip>
      </label>
      <div className="select">
        <select value={props.activeExercise.schemaId} onChange={onChange} id="scheme" name="schemaId">
          {props.activeExercise.schemaId !== "" && schemeContext.schemes.length > 0 ? (
            <option value={props.activeExercise.schemaId} hidden disabled>
              {
                schemeContext.schemes.filter(
                  (scheme: Scheme) => scheme.id === parseInt(props.activeExercise.schemaId),
                )[0].name
              }
            </option>
          ) : (
            <option value="" hidden disabled>
              {t("selectScheme")}
            </option>
          )}
          {schemeContext.schemes.map((option) => (
            <option key={option.id} id={option.id.toString()} value={option.id}>
              {option.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
