export const routes = {
  addassignment: "/add-assignment",
  addexercise: "/add-exercise/:id",
  editassignment: "/assignments/:id",
  addassignmenteditassignment: "/add-assignment/assignments/:id",
  editexercise: "/exercises/:id",
  assignments: "/assignments",
  impressum: "/impressum",
  modellingTool: "/modelling-tool",
  manualassess: "/manual-assessment/:subexid/:exerciseId",
  submissionlist: "/submissions/exercise/:exerciseId",
  studentsubmissions: "/submissions/student/:assignmentId/:userId",
  assessment: "/assessment/:id",
  submissions: "/subpreview/:id",
  feedbackMC: "/assessments-mc/:submissionExerciseId/:exerciseId/:asid",
  feedbackMU: "/assessments-mu/:submissionExerciseId/:exerciseId/:asid",
};
