// @flow

import { createContext, useContext } from "react";
import type { Assignment } from "../types/Assignment";

type Context = {
  assignments: Assignment[];
  assignmentId: number;
  updateAssignments: () => void;
  createAssignment: (data: Object) => Promise<number>;
  deleteAssignment: (id: number) => void;
  updateAssignment: (id: number, assignment: {}) => void;
};

const defaultState: Context = {
  assignments: [],
  assignmentId: -1,
  updateAssignments: () => console.warn("AssignmentContext: not in scope."),
  createAssignment: (_data: any) =>
    new Promise((resolve, _reject: any) => {
      resolve(-1);
    }),
  deleteAssignment: () => console.warn("AssignmentContext: not in scope."),
  updateAssignment: () => console.warn("AssignmentContext: not in scope."),
};

export const AssignmentContext = createContext<Context>(defaultState);

export const useAssignmentContext = () => useContext(AssignmentContext);
