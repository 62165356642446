import Tooltip from "@kea-mod/common/tooltip";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import FeedbackDataService from "../../../axios/services/feedback.service";
import { ValuationField } from "./ValuationField";

type Props = {
  feedbacklist: Array<Object>;
  toggleModal: Function;
  setFeedbacklist: Function;
  asid: number;
  refreshManFeedback: Function;
  setManFeedToRemove: Function;
  ManFeedToRemove: Array<Object>;
  sortByGrader: (a: Object, b: Object) => number;
};

export const FeedbackCreation = (props: Props) => {
  const { t } = useTranslation();

  const [newFeedback, setNewFeedback] = useState({
    id: -1,
    content: "",
    category: "",
    valuation: "NEGATIVE",
  });
  const onChangeContent = (event: any) => {
    let value = event.target.value;

    setNewFeedback({
      ...newFeedback,
      content: value,
    });
  };
  const onChangeCategory = (event: any) => {
    let value = event.target.value;

    setNewFeedback({
      ...newFeedback,
      category: value,
    });
  };
  const saveFeedback = () => {
    let b = props.feedbacklist;
    let c = props.ManFeedToRemove;
    let data = {
      content: newFeedback.content,
      category: newFeedback.category,
      valuation: newFeedback.valuation,
      assessmentId: props.asid,
    };
    FeedbackDataService.createManual(data)
      .then((response) => {
        //console.log(response);
        let resp = response.data.data;
        resp["grader"] = "Manual Feedback";
        b.push(resp);
        c.push(resp);
        b.sort(props.sortByGrader);
        props.setFeedbacklist(b);
        props.setManFeedToRemove(c);
        setNewFeedback({
          id: -1,
          content: "",
          category: "",
          valuation: "NEGATIVE",
        });
        props.refreshManFeedback();
        props.toggleModal();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const onChangeValuation = (event: any) => {
    let value = event.target.value;
    //console.log(value);

    setNewFeedback({
      ...newFeedback,
      valuation: value,
    });
  };

  return (
    <div>
      <div>
        <div className="field">
          <label className="label" htmlFor="content">
            {t("content")}
            <Tooltip
              icon="circle-info"
              class="icon tooltip no-border is-white   has-tooltip-multiline has-tooltip-right"
              content={t("contentTooltip")}
            ></Tooltip>
          </label>
          <input
            type="text"
            className="input"
            id="content"
            name="content"
            value={newFeedback.content}
            onChange={onChangeContent}
          />
        </div>
      </div>
      <div>
        <div className="field">
          <label className="label" htmlFor="category">
            {t("category")}
            <Tooltip
              icon="circle-info"
              class="icon tooltip no-border is-white   has-tooltip-multiline has-tooltip-top"
              content={t("categoryTooltip")}
            ></Tooltip>
          </label>
          <input
            type="text"
            className="input"
            id="category"
            name="category"
            value={newFeedback.category}
            onChange={onChangeCategory}
          />
        </div>
        <ValuationField activeFeedback={newFeedback} h={onChangeValuation} />

        <button className="button is-success" onClick={() => saveFeedback()}>
          {t("save")}
        </button>
      </div>
    </div>
  );
};
