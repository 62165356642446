import Tooltip from "@kea-mod/common/tooltip";
import MDEditor from "@uiw/react-md-editor";
import { useTranslation } from "react-i18next";
import type { Exercise } from "../../../types/Exercise";

type Props = {
  activeExercise: Exercise;
  h: Function;
};

export const DescriptionField = (props: Props) => {
  const { t } = useTranslation();

  const onChange = (event: any) => {
    props.h(event);
  };

  return (
    <div className="field">
      <label className="label" htmlFor="description">
        {t("description")}
        <Tooltip
          icon="circle-info"
          class="icon tooltip no-border is-white   has-tooltip-multiline has-tooltip-top"
          content={t("descriptionMCTooltip")}
        ></Tooltip>
      </label>
      <div data-color-mode="light">
        <MDEditor height={250} value={props.activeExercise.description} onChange={onChange} />
      </div>
    </div>
  );
};
