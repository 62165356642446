// @flow

import type { Language } from "./Language";

export type Exercise = {
  assignmentId: number;
  maxTotalScore: number;
  description: string;
  id: string;
  language: Language;
  languageId: number;
  name: string;
  type: string;
  visibility: string;
  schemaId: string;
  directFeedback: boolean;
  feedUp: string;
  feedForward_neg: string;
  feedForward_pos: string;
  feedForward_threshold: string;
  creation: {
    model: {
      graphdata: {};
      id: number;
      name: string;
    };
    scenario: "";
  };
  understanding: {
    answer: [
      {
        text: string;
        url: string;
        feedback: string;
        isCorrect: boolean;
      },
    ];
    question: string;
  };
};

export const emptyExercise: Exercise = {
  assignmentId: -1,
  description: "",
  id: "",
  name: "",
  language: { name: "", id: -1 },
  visibility: "",
  schemaId: "",
  languageId: -1,
  type: "",
  maxTotalScore: -1,
  directFeedback: false,
  feedUp: "",
  feedForward_neg: "",
  feedForward_pos: "",
  feedForward_threshold: "",
  creation: {
    model: {
      graphdata: {},
      id: -1,
      name: "",
    },
    scenario: "",
  },
  understanding: {
    answer: [
      {
        text: "",
        url: "",
        feedback: "",
        isCorrect: false,
      },
    ],
    question: "",
  },
};
