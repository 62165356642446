// @flow

import { createContext, useContext } from "react";

// Stelle sicher, dass die Form des Standardwertes, welcher an createContext
// überreicht wird mit der Form die der Konsument erwartet, übereinstimmt!

type Context = {
  modalVisible: boolean;
  type: string;
  exerciseId: string;
  modelType: string;
  modelingLanguage: string;
  modelId: string;
  exportEnabled: boolean;
  setModelType: (type: string) => void;
  setModelingLanguage: (type: string) => void;
  setType: (userId: string) => void;
  setExerciseId: (id: string) => void;
  toggleModal: () => void;
  setModelId: (modelid: string) => void;
  setExportEnabled: (enabled: boolean) => void;
  toggleModalpreview: () => void;
  toggleModalfeedback: () => void;
  toggleModalEdit: () => void;
};

const defaultState: Context = {
  modalVisible: false,
  type: "-1",
  exerciseId: "-1",
  modelType: "-1",
  modelingLanguage: "",
  modelId: "-1",
  exportEnabled: false,
  setType: (_type) => console.warn("ModelEditorModalContext: not in scope."),
  setExerciseId: (_id) => console.warn("ModelEditorModalContext: not in scope."),
  setModelType: (_type) => console.warn("ModelEditorModalContext: not in scope."),
  setModelingLanguage: (_modelingLanguage) => console.warn("ModelEditorModalContext: not in scope."),
  toggleModal: () => console.warn("ModelEditorModalContext: not in scope."),
  setModelId: (_modelid) => {
    console.warn("ModelEditorModalContext: not in scope.");
  },
  setExportEnabled: (_enabled) => {
    console.warn("ModelEditorModalContext: not in scope.");
  },
  toggleModalpreview: () => {
    console.warn("ModelEditorModalContext: not in scope.");
  },
  toggleModalfeedback: () => {
    console.warn("ModelEditorModalContext: not in scope.");
  },
  toggleModalEdit: () => {
    console.warn("ModelEditorModalContext: not in scope.");
  },
};

export const ModelEditorModalContext = createContext<Context>(defaultState);

export const useModelEditorModalContext = () => useContext(ModelEditorModalContext);
