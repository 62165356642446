// @flow

import { useEffect } from "react";

import { useExerciseContext } from "../context/ExerciseContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ConfirmButton from "../shared/components/ConfirmButton";

type Props = {
  exercises: any[];
  assignmentId: number;
};

export const ExerciseList = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const exerciseContext = useExerciseContext();
  let navigate = useNavigate();

  useEffect(() => {
    exerciseContext.all();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editExercise = (exerciseId: any) => {
    navigate("/exercises/" + exerciseId);
  };

  const getExerciseTableItem = (exercise: any) => {
    return (
      <tr key={"Exercise" + exercise.id}>
        <th>{exercise.name}</th>
        <th>{t(exercise.type)}</th>
        <th>{t(exercise.language.name)}</th>
        <td>
          <button className="button   is-primary" onClick={() => editExercise(exercise.id)}>
            {t("edit")}
          </button>
        </td>
        <td>
          <ConfirmButton
            id={parseInt(exercise.id)}
            name={exercise.name}
            proceedAction={exerciseContext.deleteExercise}
            question={`deleteConfirmationExercise`}
            explanation={`deleteConfirmationExerciseIrreversible`}
            color="button is-danger"
            buttonLabel={`delete`}
          />
        </td>
      </tr>
    );
  };

  const getExerciseTable = () => {
    return (
      <div className="table-container">
        <table className="table is-striped is-narrow is-hoverable is-fullwidth">
          <thead className="is-italic">
            <tr>
              <td>{t("name")}</td>
              <td>{t("type")}</td>
              <td>{t("language")}</td>
              <td>{t("options")}</td>
              <td>{t("")}</td>
            </tr>
          </thead>
          <tbody>
            {props.exercises.map((exercise) => {
              return getExerciseTableItem(exercise);
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const getEmptyMessage = () => {
    return <p className="is-size-7">{t("noExercises")} </p>;
  };

  let renderExerciseTable = (
    <div className="card my-2">
      <div className="card-content">
        <div className="columns">
          <div className="column has-text-left">
            {props.exercises.length > 0 && getExerciseTable()}
            {props.exercises.length === 0 && getEmptyMessage()}
          </div>
        </div>
      </div>
    </div>
  );

  return <div>{renderExerciseTable}</div>;
};
