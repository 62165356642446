// @flow

import { createContext, useContext } from "react";

type Context = {
  submissions: any[];
  submissionsForExercise: any[];
  submissionsForUser: any[];
  currentUser: string;
  assessment: any[];
  recent_assessment: number;
  startAssessment: (submissionId: number) => void;
  getAssessment: (submissionId: number) => Promise<Object>;
  getAllSubmissions: (assignmentId: number) => void;
  getAllSubmissionsForExercise: (id: number) => Promise<Object>;
  getAllSubmissionsForUser: (assignmentId: number, userId: any) => Promise<Object>;
  getNumberOfSubmissions: (id: number) => Promise<Object>;
};

const defaultState: Context = {
  submissions: [],
  submissionsForExercise: [],
  submissionsForUser: [],
  currentUser: "",
  assessment: [],
  recent_assessment: -1,
  startAssessment: (_submissionId) => console.warn("SubmissionContext: not in scope."),

  //getAssessment: (id) => console.warn("SubmissionContext: not in scope."),
  getAssessment: (_assessment: Object) =>
    new Promise((resolve, _reject) => {
      resolve({});
    }),
  getAllSubmissions: (_assignmentId) => console.warn("SubmissionContext: not in scope."),
  getAllSubmissionsForExercise: (_id) =>
    new Promise((resolve, _reject) => {
      resolve({});
    }),
  getAllSubmissionsForUser: (_assignmentId, _userId) =>
    new Promise((resolve, _reject) => {
      resolve({});
    }),
  getNumberOfSubmissions: (_id: Object) =>
    new Promise((resolve, _reject) => {
      resolve({});
    }),
};

export const SubmissionContext = createContext<Context>(defaultState);

export const useSubmissionContext = () => useContext(SubmissionContext);
