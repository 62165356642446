import http from "../http-common";

class ExerciseDataService {
  getAll() {
    return http.get("/authorui/exercises");
  }

  get(id) {
    return http.get(`/authorui/exercises/${id}`);
  }

  create(data) {
    return http.post("/authorui/exercises", data);
  }

  createExerciseForAssignment(asID, data) {
    return http.post(`/authorui/assignments/${asID}/exercises`, data);
  }

  update(id, data) {
    return http.put(`/authorui/exercises/${id}`, data);
  }

  delete(id) {
    return http.delete(`/authorui/exercises/${id}`);
  }

  createAnswers(id, data) {
    return http.post(`/authorui/exercises/${id}/answers`, data);
  }

  getAllAnswers(id) {
    return http.get(`/authorui/exercises/${id}/answers`);
  }
  updateAnswer(id, answerId, data) {
    return http.put(`/authorui/exercises/${id}/answers/${answerId}`, data);
  }
  deleteAnswer(id, answerId) {
    return http.delete(`/authorui/exercises/${id}/answers/${answerId}`);
  }
  assessAllExercises(id) {
    return http.post(`/authorui/exercises/${id}/assessments`);
  }
}

export default new ExerciseDataService();
