// @flow
import { useTranslation } from "react-i18next";

import { MdDeleteForever, MdModeEdit } from "react-icons/md";

type Props = {
  modelId: string;
  editModel: (id: string) => void;
  deleteModel: (id: string) => void;
  selected: boolean;
  // toggleSelected: (id: sting) => void,
};

export const defineObjectProperty = (obj: any, key: any, value: any) => {
  var config = {
    value: value,
    writable: true,
    enumerable: true,
    configurable: true,
  };
  Object.defineProperty(obj, key, config);
};

export const ModelCardComponent = (props: Props) => {
  const { t } = useTranslation();
  const getBoxStyle = () => {
    const style = {
      height: "150px",
    };
    if (props.selected) {
      defineObjectProperty(style, "borderStyle", "solid");
      defineObjectProperty(style, "borderColor", "hsl(217, 71%, 53%)");
      defineObjectProperty(style, "borderWidth", "2px");
    }
    return style;
  };

  return (
    <div className="column is-half">
      <div className="box m-0 p-0 is-clipped is-relative" style={{ ...getBoxStyle() }}>
        <iframe
          scrolling="no"
          className="m-0 p-0"
          width="100%"
          height="100%"
          style={{ pointerEvents: "none", userSelect: "none" }}
          src={
            (window as any)._env_.REACT_APP_EDITOR_UI_URL +
            ":" +
            (window as any)._env_.REACT_APP_EDITOR_UI_PORT +
            "/models/preview" +
            "?modelId=" +
            props.modelId +
            "&exportEnabled=true" +
            "&showButtons=false"
          }
          title="modellingtool"
        ></iframe>

        <div style={{ position: "absolute", top: 0 }}>
          <button
            onClick={() => {
              props.editModel(props.modelId);
            }}
            className="button is-rounded is-primary mt-2"
          >
            <span className="icon">
              <MdModeEdit />
            </span>
            <span>{t("editModel")}</span>
          </button>

          <button
            onClick={() => {
              props.deleteModel(props.modelId);
            }}
            className="button is-rounded is-danger mt-2"
          >
            <span className="icon">
              <MdDeleteForever />
            </span>
            <span>{t("deleteModel")}</span>
          </button>

          {/* {!props.selected && (
            <p className="control">
              <button
                className="button is-rounded is-link"
                onClick={() => props.toggleSelected(props.id)}
                IoAdd
              >
                <span className="icon">
                  <MdAdd />
                </span>
              </button>
            </p>
          )}
          {props.selected && (
            <p className="control">
              <button
                className="button is-rounded is-link"
                onClick={() => props.toggleSelected(props.id)}
              >
                <span className="icon">
                  <MdRemove />
                </span>
              </button>
            </p>
          )} */}
        </div>
      </div>
    </div>
  );
};
