// @flow

import { createContext, useContext } from "react";
import type { Model } from "../types/Model";
import { emptyModel } from "../types/Model";

type Context = {
  models: Model[];
  model: Model;
  update: (exerciseId: any, modelType: any) => void;
  create: (data: any) => void;
  delete: (id: number, exid: any, modeltype: any) => void;
  updateModel: (id: number, data: Object) => void;
  setModel: (model: Model) => void;
};

const defaultState: Context = {
  models: [],
  model: emptyModel,
  update: (_exerciseId, _modelType) => console.warn("ModelContext: not in scope."),
  create: () => console.warn("ModelContext: not in scope."),
  delete: (_id, _exid, _modeltype) => console.warn("ModelContext: not in scope."),
  updateModel: (_id, _data) => console.warn("ModelContext: not in scope."),
  setModel: (_model: Model) => console.warn("ModelContext: not in scope."),
};

export const ModelContext = createContext<Context>(defaultState);

export const useModelContext = () => useContext(ModelContext);
