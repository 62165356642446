import Tooltip from "@kea-mod/common/tooltip";
import { useTranslation } from "react-i18next";

type Props = {
  activeFeedback: any;

  h: Function;
};

export const ValuationField = (props: Props) => {
  const { t } = useTranslation();
  const options = [
    { id: 1, name: "NEGATIVE" },
    { id: 2, name: "POSITIVE" },
    { id: 3, name: "NEUTRAL" },
    { id: 4, name: "OTHER" },
  ];
  const onChange = (event: any) => {
    props.h(event);
  };

  return (
    <div className="field">
      <label className="label" htmlFor="title">
        {t("valuation")}
        <Tooltip
          icon="circle-info"
          class="icon tooltip no-border is-white   has-tooltip-multiline has-tooltip-right"
          content={t("valuationTooltip")}
        ></Tooltip>
      </label>
      <div className="select">
        <select value={props.activeFeedback.valuation} onChange={onChange} id="valuation" name="valuation">
          {options.map((option) => (
            <option key={option.id} id={option.id.toString()} value={option.name}>
              {option.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
