import Tooltip from "@kea-mod/common/tooltip";
import { Assessment } from "@kea-mod/common/types/Assessment";
import { useTranslation } from "react-i18next";

type Props = {
  activeAssessment: Assessment;
  h: Function;
  multiple: Boolean;
};

export const TotalscoreField = (props: Props) => {
  const { t } = useTranslation();
  const onChange = (event: any) => {
    props.h(event);
  };

  return (
    <div className="field">
      <label className="label" htmlFor="totalscore">
        {props.multiple ? (
          <div>
            {t("totalScore") + " (" + t("ABSOLUTE") + ")"}
            <Tooltip
              icon="circle-info"
              class="icon tooltip no-border is-white   has-tooltip-multiline has-tooltip-top"
              content={t("absolutescoreTooltip")}
            ></Tooltip>
          </div>
        ) : (
          <div>
            {t("totalScore") + " (" + t("RELATIVE") + ")"}
            <Tooltip
              icon="circle-info"
              class="icon tooltip no-border is-white   has-tooltip-multiline has-tooltip-top"
              content={t("relativescoreTooltip")}
            ></Tooltip>
          </div>
        )}
      </label>
      <input
        type="text"
        className="input"
        value={props.activeAssessment.totalscore}
        onChange={onChange}
        pattern="^[-+]?\d*\.?\d*$"
      />
    </div>
  );
};
