// Solution from https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings

export function b64EncodeUnicode(str) {
  try {
    return btoa(
      encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
        return String.fromCharCode(parseInt(p1, 16));
      }),
    );
  } catch (error) {
    console.error("There was an error b64-encoding 0the following string: ", str);
    return "";
  }
}

export function b64DecodeUnicode(str) {
  try {
    return decodeURIComponent(
      Array.prototype.map
        .call(atob(str), function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(""),
    );
  } catch (error) {
    console.error("There was an error b64-decoding the following string: ", str);
    return "";
  }
}
