// @flow

import { useState } from "react";

import type { Exercise } from "../types/Exercise";
import { ExerciseContext } from "./ExerciseContext";
import ExerciseDataService from "../axios/services/exercise.service";
import { emptyAnswer } from "../types/Answer";
import { emptyExercise } from "../types/Exercise";

interface Props {
  children: any;
}
export const ExerciseContextProvider = (props: Props): any => {
  const [exercises, setExercises] = useState([]);
  const [exercisesInAssignment, setExercisesInAssignment] = useState([]);
  const [id] = useState(-1);
  const [exercise, setExercise] = useState(emptyExercise);
  const [answers, setAnswers] = useState([]);
  const [answer, setAnswer] = useState(emptyAnswer);

  const updateExercises = () => {
    ExerciseDataService.getAll()
      .then((response: any) => {
        if (response.status !== 200) return;
        setExercises(response.data.data);
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  // get all exercises that are in the current assignment
  const filterExercises = (assignmentId: any) => {
    const filteredExercises = exercises.filter((exercise: any) => exercise.assignmentId === parseInt(assignmentId));
    if (filteredExercises === undefined) return;
    setExercisesInAssignment(filteredExercises);
    console.log(filteredExercises);
  };

  const deleteExercise = (id: any) => {
    ExerciseDataService.delete(id)
      .then((response: any) => {
        console.log(response);
        if (response.data.success) {
          updateExercises();
        } else {
          /** TODO SHOULD HANDLE UNSUCCESSFULL DELETING ASSIGNMENT HERE. */
        }
      })
      .catch((e: any) => {
        /** TODO SHOULD HANDLE ERROR DELETING ASSIGNMENT. */
        console.warn(e);
      });
  };

  const updateExercise = async (id: any, data: any): Promise<number> => {
    return new Promise<number>((resolve, reject) => {
      ExerciseDataService.update(id, data).then((response: any) => {
        console.log(response);
        if (response.data.success) {
          updateExercises();
          setExercise(response.data.data.current);
          return resolve(response.data.data.current.id);
        } else {
          /** TODO SHOULD HANDLE UNSUCCESSFULL FETCHING EXERCISES HERE. */
          reject(-1);
        }
      });
    });
  };

  /** const getExercise = (id) => {
    if (id === -1) return;
    ExerciseDataService.get(id)
      .then((response) => {
        console.log(response);
        if (response.status !== 200) return;
        if (response.data.success) {
          if (exercise != response.data.data) {
            setExercise(response.data.data);
            return response.data.data;
          }
        } else {
          
        }
      })
      .catch((e) => {
      
        console.warn(e);
      });
  };
*/
  const getExercise = async (id: number): Promise<Exercise> => {
    return new Promise<Exercise>((resolve, reject) => {
      ExerciseDataService.get(id).then((response: any) => {
        if (response.data.success) {
          setExercise(response.data.data);
          return resolve(response.data.data);
        } else {
          /** TODO SHOULD HANDLE UNSUCCESSFULL FETCHING EXERCISES HERE. */
          reject("no exercise found");
        }
      });
    });
  };

  const createExercise = async (data: Object): Promise<number> => {
    return new Promise<number>((resolve, reject) => {
      ExerciseDataService.create(data).then((response: any) => {
        console.log(response);
        if (response.data.success) {
          updateExercises();
          return resolve(response.data.data.id);
        } else {
          /** TODO SHOULD HANDLE UNSUCCESSFULL FETCHING EXERCISES HERE. */
          reject(-1);
        }
      });
    });
  };

  const createExerciseForAssignment = async (data: Object, _asID: any): Promise<number> => {
    return new Promise<number>((resolve, reject) => {
      ExerciseDataService.createExerciseForAssignment(data).then((response: any) => {
        console.log(response);
        if (response.data.success) {
          return resolve(response.data.data.id);
        } else {
          /** TODO SHOULD HANDLE UNSUCCESSFULL FETCHING EXERCISES HERE. */
          reject(-1);
        }
      });
    });
  };

  const createAnswers = (id: any, data: any) => {
    ExerciseDataService.createAnswers(id, data)
      .then((response: any) => {
        console.log(response);
        if (response.data.success) {
          updateAllAnswers(id);
        } else {
          /** TODO SHOULD HANDLE UNSUCCESSFULL DELETING ASSIGNMENT HERE. */
        }
      })
      .catch((e: any) => {
        /** TODO SHOULD HANDLE ERROR DELETING ASSIGNMENT. */
        console.warn(e);
      });
  };

  const updateAllAnswers = (id: any) => {
    ExerciseDataService.getAllAnswers(id)
      .then((response: any) => {
        if (response.status !== 200) return;
        setAnswers(response.data.data);
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  const updateAnswer = (id: any, answerid: any, data: any) => {
    ExerciseDataService.updateAnswer(id, answerid, data)
      .then((response: any) => {
        console.log(response);
        if (response.data.success) {
          updateAllAnswers(id);
          setAnswer(response.data.data.current);
          updateExercises();
          //window.alert("Exercise was updated sucessfully");
        } else {
          /** TODO SHOULD HANDLE UNSUCCESSFULL DELETING ASSIGNMENT HERE. */
        }
      })
      .catch((e: any) => {
        /** TODO SHOULD HANDLE ERROR DELETING ASSIGNMENT. */
        console.warn(e);
      });
  };

  const deleteAnswer = (id: any, answerid: any) => {
    ExerciseDataService.deleteAnswer(id, answerid)
      .then((response: any) => {
        console.log(response);
        if (response.data.success) {
          updateAllAnswers(id);
        } else {
          /** TODO SHOULD HANDLE UNSUCCESSFULL DELETING ASSIGNMENT HERE. */
        }
      })
      .catch((e: any) => {
        /** TODO SHOULD HANDLE ERROR DELETING ASSIGNMENT. */
        console.warn(e);
      });
  };

  return (
    <ExerciseContext.Provider
      value={{
        exercises: exercises,
        exercisesInAssignment: exercisesInAssignment,
        exercise: exercise,
        id: id,
        answers: answers,
        answer: answer,
        all: updateExercises,
        filterExercises: filterExercises,
        deleteExercise: deleteExercise,
        get: getExercise,
        update: updateExercise,
        create: createExercise,
        createAnswers: createAnswers,
        updateAllAnswers: updateAllAnswers,
        updateAnswer: updateAnswer,
        deleteAnswer: deleteAnswer,
        createExerciseForAssignment: createExerciseForAssignment,
      }}
    >
      {props.children}
    </ExerciseContext.Provider>
  );
};
