import http from "../http-common";

class AuthService {
  login(data) {
    return http.post("/auth/signin", data);
  }

  signOut() {
    http
      .post("/auth/signout")
      .then((res) => {
        document.cookie = "user.id=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        window.location.href = `${window._env_.REACT_APP_LANDINGPAGE_URL}:${window._env_.REACT_APP_LANDINGPAGE_PORT}`;
      })
      .catch((err) => {
        // Handle Error Here
        console.log(err);
        document.cookie = "user.id=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        window.location.href = `${window._env_.REACT_APP_LANDINGPAGE_URL}:${window._env_.REACT_APP_LANDINGPAGE_PORT}`;
      });
  }

  signUpAndSignIn(data) {
    http
      .post("/auth/signup", data)
      .then((res) => {
        //window.alert("You have successfully signed up!");
        this.signIn({
          token: data.token,
        });
      })
      .catch((e) => {
        window.alert("User already exists!");
      });
  }

  register(data) {
    return http.post("/auth/signup", data);
  }

  signIn(data) {
    http
      .post("/auth/signin", data)
      .then((res) => {
        if (res.data.data.roles.includes("ROLE_AUTHOR")) {
          //localStorage.setItem("unique-token", data.token);

          setTimeout(() => {}, 100);
          window.location.href = "/assignments";
        } else {
          AuthService.signOut();
        }
      })
      .catch((e) => {
        console.log(e);
        AuthService.signOut();
      });
  }
}

export default new AuthService();
