import "../sign-in-screen.css";

import AuthService from "../axios/services/auth.service";
import logo from "../shared/assets/KEA-Mod_Logo_RGB_S.png";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const SignIn = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [password, setPassword] = useState("");

  const performLogin = () => {
    const body = {
      token: name,
      password: password,
    };

    AuthService.login(body).then(
      (res: any) => {
        if (res.data.success === true) {
          if (res.data.data.roles.includes("ROLE_AUTHOR")) {
            localStorage.setItem("unique-token", res.data.token);
            navigate("/assignments");
          } else {
            localStorage.removeItem("unique-token");
          }
        }
      },
      (err: any) => {
        console.log(err);
      },
    );
  };

  const performSignup = () => {
    const body = {
      token: name,
      password: password,
      roles: ["author"],
    };

    AuthService.register(body).then(
      (res: any) => {
        if (res.data === undefined) {
          alert("something went wrong, please try again later.");
        }
        if (res.data.success === true) {
          performLogin();
        } else {
          alert("something went wrong, please try again later.");
        }
      },
      (err: any) => {
        if (err.response.status === 400) {
          alert("something went wrong, please try again later.");
        }
      },
    );
  };

  const onChangeName = (e: any) => {
    setName(e.target.value);
  };

  const onChangePW = (e: any) => {
    setPassword(e.target.value);
  };

  return (
    <div className={"center"}>
      <img src={logo} alt="KEA-Mod LehrendenUI" />

      <div className="field">
        <label className="label">{t("name")}</label>
        <input className="input" value={name} onChange={onChangeName} />
      </div>
      <div className="field">
        <label className="label">{t("Password")}</label>
        <input className="input" type="password" value={password} onChange={onChangePW} />
      </div>

      <button className="button is-primary" onClick={() => performLogin()}>
        {t("signIn")}
      </button>

      <button className="button" onClick={() => performSignup()}>
        {t("signUp")}
      </button>
    </div>
  );
};
