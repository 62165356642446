import { useTranslation } from "react-i18next";

type Props = {
  answer: string;
  isCorrect: boolean;
  handleCheckAnswer: any;
  handleChangeAnswer: any;
  handleAddAnswer: any;
};

export const AnswerField = (props: Props) => {
  const { t } = useTranslation();

  return (
    <div className="field">
      <div className="columns mt-1">
        <div className="column">
          <input
            type="text"
            className="input"
            id="answer"
            name="answer"
            value={props.answer}
            onChange={props.handleChangeAnswer}
          />
        </div>
        <div className="column">
          <div className="control mt-2">
            <label className="radio mr-2">
              <input
                className="mr-1"
                type="radio"
                name="answer"
                checked={props.isCorrect}
                onChange={props.handleCheckAnswer}
              />
              {t("true")}
            </label>
            <label className="radio">
              <input
                className="mr-1"
                type="radio"
                name="answer"
                checked={!props.isCorrect}
                onChange={props.handleCheckAnswer}
              />
              {t("false")}
            </label>
          </div>
        </div>
        <div className="column">
          <button className="button is-info" onClick={props.handleAddAnswer}>
            {t("addAnswer")}
          </button>
        </div>
      </div>
    </div>
  );
};
