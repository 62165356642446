export enum ModelingLanguage {
  UML_CD = "UML_CD",
  UML_COMPD = "UML_COMPD",
  UML_SD = "UML_SD",
  UML_OD = "UML_OD",
  UML_SEQ = "UML_SEQ",
  UML_SEQD_FRAME = "UML_SEQD_FRAME",
  UML_AD = "UML_AD",
  UML_AD_SWIMLANE = "UML_AD_SWIMLANE",
  EMPTY = "EMPTY",
  ER = "ER",
  EPC = "EPC",
  PN = "PN",
  BPMN = "BPMN",
  BPMN_EVENT = "BPMN_EVENT",
  BPMN_GATEWAY = "BPMN_GATEWAY",
  BPMN_SWIMLANE = "BPMN_SWIMLANE",
  BPMN_ACTIVITY = "BPMN_ACTIVITY",
  ANY = "ANY",
  NONE = "NONE",
}
