import { useEffect, useRef, useState } from "react";

import { debounce } from "debounce";
import { ModellingContext } from "./context/ModellingToolContext";

function ModellingProvider(props: any) {
  const modalRef = useRef(null);
  const modalBodyRef = useRef(null);
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  });

  const [token, setToken] = useState("-1");
  const [modelType, setModelType] = useState("");
  const [modalVisible, setModalVisible] = useState(false);

  const updateSize = debounce(() => {
    if (modalBodyRef.current) {
      setSize({
        width: (modalBodyRef.current as any).offsetWidth,
        height: (modalBodyRef.current as any).offsetHeight,
      });
    }
  }, 50);

  useEffect(() => {
    window.addEventListener("resize", updateSize);
    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, [updateSize]);

  const toggleModal = () => {
    console.log("toggle modal with the following fields..");
    console.log("token: ", token);
    console.log("modelType: ", modelType);
    setModalVisible(!modalVisible);
    updateSize();
  };

  return (
    <ModellingContext.Provider
      value={{
        token,
        modelType,
        toggleModal,
        setToken,
        setModelType,
      }}
    >
      {props.children}
      <div ref={modalRef} className={"fullscreenmodal modal" + (modalVisible ? " is-active" : "")}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <button className="delete" aria-label="close" onClick={toggleModal}></button>
          </header>
          <section
            ref={modalBodyRef}
            className="modal-card-body m-0 p-0"
            style={{ width: "100%", padding: "0", overflowY: "hidden" }}
          >
            <iframe
              src={
                modalVisible
                  ? (window as any)._env_.REACT_APP_EDITOR_UI_URL +
                    ":" +
                    (window as any)._env_.REACT_APP_EDITOR_UI_PORT +
                    "/models/" +
                    modelType +
                    "?token=" +
                    token
                  : ""
              }
              width={size.width}
              height={size.height}
              title="modellingtool"
            ></iframe>
          </section>
        </div>
      </div>
    </ModellingContext.Provider>
  );
}

export default ModellingProvider;
