// @flow

import { useState } from "react";

import { AssignmentContext } from "./AssignmentContext";
import { useNavigate } from "react-router-dom";
import AssignmentDataService from "../axios/services/assignment.service";

type Props = {
  children: any;
};

export const AssignmentContextProvider = (props: Props) => {
  const navigate = useNavigate();
  const [assignments, setAssignments] = useState([]);
  const [assignmentId, setAssignmentId] = useState(-1);

  const updateAssignments = () => {
    AssignmentDataService.getAll()
      .then((response: any) => {
        if (response.status !== 200) return;
        setAssignments(response.data.data);
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  const createAssignment = async (data: Object): Promise<number> => {
    return new Promise<number>((resolve, reject) => {
      AssignmentDataService.create(data).then((response: any) => {
        console.log(response);
        if (response.data.success) {
          updateAssignments();
          setAssignmentId(response.data.data.id);
          return resolve(response.data.data.id);
        } else {
          /** TODO SHOULD HANDLE UNSUCCESSFULL FETCHING EXERCISES HERE. */
          reject(-1);
        }
      });
    });
  };

  const updateAssignment = (id: any, assignment: any) => {
    AssignmentDataService.update(id, assignment)
      .then((response: any) => {
        console.log(response);
        if (response.data.success) {
          updateAssignments();
          navigate("/assignments"); //go back to all assignments after updating
        } else {
          /** TODO SHOULD HANDLE UNSUCCESSFULL DELETING ASSIGNMENT HERE. */
        }
      })
      .catch((e: any) => {
        /** TODO SHOULD HANDLE ERROR DELETING ASSIGNMENTS. */
        console.warn(e);
      });
  };

  const deleteAssignment = (id: any) => {
    AssignmentDataService.delete(id)
      .then((response: any) => {
        console.log(response);
        if (response.data.success) {
          updateAssignments();
        } else {
          /** TODO SHOULD HANDLE UNSUCCESSFULL DELETING ASSIGNMENT HERE. */
        }
      })
      .catch((e: any) => {
        /** TODO SHOULD HANDLE ERROR DELETING ASSIGNMENTS. */
        console.warn(e);
      });
  };

  return (
    <AssignmentContext.Provider
      value={{
        assignments: assignments,
        assignmentId: assignmentId,
        updateAssignments: updateAssignments,
        createAssignment,
        deleteAssignment,
        updateAssignment,
      }}
    >
      {props.children}
    </AssignmentContext.Provider>
  );
};
