import { useEffect, useMemo, useState } from "react";

import { DescriptionField } from "../ExerciseFields/DescriptionField";
import { LanguageField } from "../ExerciseFields/LanguageField";
import { NameField } from "../ExerciseFields/NameField";
import { ScenarioField } from "../ExerciseFields/ScenarioField";
import { SchemeField } from "../ExerciseFields/SchemeField";
import { FeedUpField } from "../ExerciseFields/FeedUpField";
import { FeedForwardPosField } from "../ExerciseFields/FeedForwardPosField";
import { FeedForwardNegField } from "../ExerciseFields/FeedForwardNegField";
import { FeedForwardThresholdField } from "../ExerciseFields/FeedForwardThresholdField";
import Tooltip from "@kea-mod/common/tooltip";
import { b64EncodeUnicode } from "../../../shared/b64";
import { emptyExercise } from "../../../types/Exercise";
import { useExerciseContext } from "../../../context/ExerciseContext";
import { useNavigate } from "react-router-dom";
import { useModelContext } from "../../../context/ModelContext";
import { useTranslation } from "react-i18next";
import { ModelList } from "../../../pages/Modellist";

type Props = {
  assignmentId: any;
};

export const AddExerciseCreationComponent = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const exerciseContext = useExerciseContext();
  const modelContext = useModelContext();

  const [activeExercise, setActiveExercise] = useState(emptyExercise);
  const [exerciseId, setExerciseId] = useState(-1);
  const [exerciseCreated, setExerciseCreated] = useState(false);
  const [language, setLanguage] = useState("");
  const [maxScore, setMaxScore] = useState("0");

  const activeModels = useMemo(() => {
    if (exerciseId === undefined) return [];
    return modelContext.models.filter((model: any) => model.parentId === exerciseId);
  }, [exerciseId, modelContext.models]);

  useEffect(() => {
    exerciseContext.all();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event: any) => {
    let value = event.target.value;

    setActiveExercise({
      ...activeExercise,
      [event.target.name]: value,
    });
  };

  const handleChangeLanguage = (event: any) => {
    setLanguage(event.target.value);
  };

  const handleChangeDescription = (event: any) => {
    setActiveExercise({
      ...activeExercise,
      description: event,
    });
  };

  const handleChangeScenario = (event: any) => {
    setActiveExercise({
      ...activeExercise,
      creation: {
        ...activeExercise.creation,
        scenario: event,
      },
    });
  };

  const handleCheckboxChange = (event: any) => {
    setActiveExercise({
      ...activeExercise,
      [event.target.name]: event.target.checked,
    });
  };

  const emptyCheck = () => {
    if (
      activeExercise.name.trim() === "" ||
      activeExercise.description === "" ||
      activeExercise.creation.scenario === "" ||
      language === "" ||
      activeExercise.schemaId === ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  const addFeedFields = (data: any) => {
    //only add fields to data if not empty
    if (activeExercise.feedUp !== "") {
      data.feedUp = activeExercise.feedUp;
    }

    if (activeExercise.feedForward_pos !== "") {
      data.feedForward_pos = activeExercise.feedForward_pos;
    }

    if (activeExercise.feedForward_neg !== "") {
      data.feedForward_neg = activeExercise.feedForward_neg;
    }

    if (activeExercise.feedForward_threshold !== "") {
      data.feedForward_threshold = parseFloat(activeExercise.feedForward_threshold);
    }
  };

  const createExercise = async () => {
    if (emptyCheck()) {
      alert("Please make sure that all fields have been filled out!");
    } else {
      let data: any = {
        name: activeExercise.name,
        type: "creation",
        language: language,
        description: b64EncodeUnicode(activeExercise.description),
        scenario: b64EncodeUnicode(activeExercise.creation.scenario),
        schemaId: parseInt(activeExercise.schemaId),
        assignmentId: parseInt(props.assignmentId),
        directFeedback: activeExercise.directFeedback,
        maxTotalScore: maxScore,
      };

      addFeedFields(data);

      let exerciseId = await exerciseContext.create(data);

      setExerciseId(exerciseId);
      setExerciseCreated(true);
    }
  };

  const goBackToAssignment = () => {
    navigate("/assignments/" + props.assignmentId);
  };

  const showExerciseButtons = () => {
    if (!exerciseCreated) {
      return (
        <div className="columns mt-2">
          <button className="button is-success" onClick={createExercise}>
            {t("createExercise")}
          </button>
          <button className="button is-danger" onClick={goBackToAssignment}>
            {t("cancel")}
          </button>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const showFinishButton = () => {
    if (exerciseCreated) {
      return (
        <div className="columns mt-4">
          <button className="button is-normal is-success" onClick={updateExercise}>
            {t("saveExercise")}
          </button>
          <button className="button is-danger" onClick={goBackToAssignment}>
            {t("cancel")}
          </button>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const updateExercise = () => {
    if (emptyCheck()) {
      alert("Please make sure that all fields have been filled out!");
    } else {
      let data = {
        name: activeExercise.name,
        type: "creation",
        language: language,
        description: b64EncodeUnicode(activeExercise.description),
        scenario: b64EncodeUnicode(activeExercise.creation.scenario),
        schemaId: parseInt(activeExercise.schemaId),
        assignmentId: parseInt(props.assignmentId),
        directFeedback: activeExercise.directFeedback,
        maxTotalScore: maxScore,
      };

      exerciseContext.update(exerciseId, data);
      navigate("/assignments/" + props.assignmentId);
    }
  };
  function onChangeMaxScore(e: any) {
    let val = e.target.value;
    const v = e.target.value;
    const myArray = e.target.value.split(".");

    if (myArray[0].toString().length >= 11) {
      return;
    }
    if (myArray.length !== 1 && myArray[1].toString().length >= 3) {
      return;
    }

    if (e.target.validity.valid) {
      val = val.replace(/^0+/, "");
      if (val === "") {
        val = v;
      }

      if (val.startsWith(".")) {
        val = "0" + val;
      }

      setMaxScore(val);
    } else if (val === "" || val === "-") {
      val = val.replace(/^0+/, "");
      if (val === "") {
        val = v;
      }

      if (val.startsWith(".")) {
        val = "0" + val;
      }
      setMaxScore(val);
    }
  }
  let ScoreField = (
    <div>
      <div>
        <input
          size={5}
          className="input"
          type="text"
          value={maxScore}
          onChange={onChangeMaxScore}
          pattern="^[-+]?\d*\.?\d*$"
        />
      </div>
    </div>
  );

  return (
    <div>
      <NameField activeExercise={activeExercise} h={handleChange} />
      <LanguageField language={language} h={handleChangeLanguage} />
      <DescriptionField activeExercise={activeExercise} h={handleChangeDescription} />
      <ScenarioField activeExercise={activeExercise} h={handleChangeScenario} />
      <SchemeField activeExercise={activeExercise} h={handleChange} />
      <FeedUpField activeExercise={activeExercise} h={handleChange} />
      <FeedForwardPosField activeExercise={activeExercise} h={handleChange} />
      <FeedForwardNegField activeExercise={activeExercise} h={handleChange} />
      <FeedForwardThresholdField activeExercise={activeExercise} h={handleChange} />
      <div className="field">
        <div className="label">
          {t("autoAssessmentEx")}
          <Tooltip
            icon="circle-info"
            class="icon tooltip no-border is-white   has-tooltip-multiline has-tooltip-top"
            content={t("autoAssessmentExDesc")}
          ></Tooltip>
        </div>
        <input
          name="directFeedback"
          type="checkbox"
          checked={activeExercise.directFeedback}
          onChange={handleCheckboxChange}
        />
      </div>
      <div className="field">
        <div className="label">
          {t("MaxScore")}
          <Tooltip
            icon="circle-info"
            class="icon tooltip no-border is-white   has-tooltip-multiline has-tooltip-top"
            content={t("maxscoreTooltip")}
          ></Tooltip>
        </div>
        <div>{ScoreField}</div>{" "}
      </div>
      {showExerciseButtons()}
      {exerciseCreated && (
        <div className="field">
          <label className="label">
            {t("sampleSolution")}
            <Tooltip
              icon="circle-info"
              class="icon tooltip no-border is-white   has-tooltip-multiline has-tooltip-top"
              content={t("samplesolutionsTooltip")}
            ></Tooltip>
          </label>
          <ModelList
            modelList={activeModels}
            modelingLanguage={language}
            exerciseId={exerciseId}
            exerciseType={"creation"}
            modelType={"sampleSolution"}
          />
        </div>
      )}
      {showFinishButton()}
    </div>
  );
};
