// @flow

export type Answer = {
  text: string;
  url: string;
  feedback: string;
  isCorrect: boolean;
};

export const emptyAnswer: Answer = {
  text: "",
  url: "",
  feedback: "",
  isCorrect: true,
};
