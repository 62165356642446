// @flow

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useSubmissionContext } from "../../context/SubmissionContext";
import ConfirmButton from "../../shared/components/ConfirmButton";
import Tooltip from "@kea-mod/common/tooltip";
import { FaRedo } from "react-icons/fa";

const dateOptions = {
  weekday: "short",
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
} as any;

export const StudentSubmissionComponent = () => {
  let { assignmentId, userId } = useParams();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const submissionContext = useSubmissionContext();
  const [assessmentMap, setAssessmentMap] = useState({}) as any;
  const [sum_totalscore, setSum_totalscore] = useState(0.0);
  const [sum_maxscore, setSum_maxscore] = useState(0);
  const [refresh, setRefresh] = useState(true);
  const [currentSubmissionsForUser, setCurrentSubmissionsForUser] = useState([]) as any;

  useEffect(() => {}, [refresh]);

  useEffect(() => {
    getSubmissionsForUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSubmissionsForUser = async () => {
    let allSubmissionsForUser = await submissionContext.getAllSubmissionsForUser(
      parseInt(assignmentId as string),
      userId,
    );
    setCurrentSubmissionsForUser(allSubmissionsForUser);
  };

  useEffect(() => {
    const submissionsForUser = submissionContext.submissionsForUser;
    if (submissionsForUser === undefined) return;
    setCurrentSubmissionsForUser(submissionsForUser);

    if (submissionsForUser.length !== 0) {
      checkforCompleteAssessment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submissionContext.submissionsForUser]);

  useEffect(() => {
    computeSumScores();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const checkforCompleteAssessment = async () => {
    var bar = assessmentMap as any;

    for (var i = 0; i < currentSubmissionsForUser.length; i++) {
      var submission = currentSubmissionsForUser[i];

      let ex_type = currentSubmissionsForUser[i].exercise.type;
      var booli = true;
      let assessment = (await submissionContext.getAssessment(submission.id)) as any;
      let as = { totalscore: "-", maxTotalScore: "-" } as any;

      if (!("message" in assessment && assessment["message"] === "incomplete")) {
        as["maxTotalScore"] = assessment.maxTotalScore;
      }

      as["totalscore"] = "-";

      if (assessment.status === "complete") {
        as = assessment;
        booli = false;
        if (ex_type === "creation") {
          as["totalscore"] = parseFloat((as["totalscore"] * as["maxTotalScore"]).toFixed(2));
        }
      }

      bar[submission.id] = { last_complete_assessment: as, status: booli };
    }
    setAssessmentMap(bar);
    setRefresh(!refresh);
  };

  const computeSumScores = () => {
    const keys = Object.keys(assessmentMap);
    let totalmax: any = 0.0;
    let maxmax: any = 0.0;
    //let counter = 0;
    //let countermax = 0;
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      let obj = assessmentMap[key]["last_complete_assessment"];

      if (obj.maxTotalScore === "-" || obj.maxTotalScore === undefined) {
        continue;
      }
      maxmax = maxmax + obj.maxTotalScore;
      //countermax = countermax + 1;
      if (!("gradings" in obj)) {
        continue;
      }
      //counter = counter + 1;

      totalmax = totalmax + parseFloat(obj.totalscore.toFixed(2));
    }

    //if (counter === 0 || countermax === 0) {
    //  totalmax = "-";
    //  maxmax = "-";
    //} else {
      totalmax = parseFloat((totalmax).toFixed(2));
      maxmax = parseFloat((maxmax).toFixed(2));
    //}

    setSum_totalscore(totalmax);
    setSum_maxscore(maxmax);
  };

  const manualAssessment = async (submission: any) => {
    localStorage.setItem("originStudentTable", "true");
    localStorage.setItem("exerciseNameForStudent", submission.exercise.name);
    let submissionId = submission.id;
    let exerciseId = submission.exerciseId;

    if (submission.id in assessmentMap && Object.keys(assessmentMap[submission.id]).length > 0) {
      navigate({
        pathname: "/manual-assessment/" + submissionId + "/" + exerciseId,
      });
    }
  };

  const showAssessment = async (submission: any) => {
    localStorage.setItem("originStudentTable", "true");
    localStorage.setItem("exerciseNameForStudent", submission.exercise.name);
    let submissionId = submission.id;
    let exerciseId = submission.exerciseId;
    let assessment = assessmentMap[submissionId]["last_complete_assessment"];
    if (submission.type === "creation") {
      navigate({
        pathname: "/assessments-mc/" + submissionId + "/" + exerciseId + "/" + assessment.id,
      });
    } else {
      navigate({
        pathname: "/assessments-mu/" + submissionId + "/" + exerciseId + "/" + assessment.id,
      });
    }
  };

  function getStudentSubmissionsTableItem(submission: any) {
    return (
      <tr key={"Submission" + submission.id}>
        <th>{submission.exercise.name}</th>
        <th>{t(submission.exercise.language.name)}</th>
        <th>{t(submission.exercise.type)}</th>
        <th>{new Date(submission.createdAt).toLocaleDateString(i18n.language, dateOptions)}</th>
        <td className="has-text-center has-text-weight-bold">
          {submission.id in assessmentMap &&
          "last_complete_assessment" in assessmentMap[submission.id] &&
          assessmentMap[submission.id]["last_complete_assessment"].maxTotalScore !== undefined
            ? assessmentMap[submission.id]["last_complete_assessment"].totalscore +
              "/" +
              assessmentMap[submission.id]["last_complete_assessment"].maxTotalScore
            : "-/-"}
        </td>
        <td>
          <ConfirmButton
            id={submission.id}
            name={submission.exercise.name}
            proceedAction={submissionContext.startAssessment}
            question={`startAssessmentSingleQuestionExercise`}
            explanation={`startAssessmentSingleExplanation`}
            color="button is-primary"
            buttonLabel={`startAssessment`}
          />
          <button
            className="button is-success"
            disabled={
              submission.id in assessmentMap && "status" in assessmentMap[submission.id]
                ? assessmentMap[submission.id]["status"]
                : true
            }
            onClick={() => manualAssessment(submission)}
          >
            {t("ManualAssessment")}
          </button>
          <button
            className="button is-info"
            disabled={
              submission.id in assessmentMap && "status" in assessmentMap[submission.id]
                ? assessmentMap[submission.id]["status"]
                : true
            }
            onClick={() => showAssessment(submission)}
          >
            {t("showAssessment")}
          </button>
        </td>
        <td className="has-text-center has-text-weight-bold">
          {submission.id in assessmentMap &&
          "last_complete_assessment" in assessmentMap[submission.id] &&
          assessmentMap[submission.id]["last_complete_assessment"].isRevised !== undefined ? (
            assessmentMap[submission.id]["last_complete_assessment"].isRevised ? (
              <input type="checkbox" checked disabled />
            ) : (
              <input type="checkbox" disabled />
            )
          ) : (
            ""
          )}
        </td>
      </tr>
    );
  }

  function getStudentSubmissionsTable() {
    return (
      <div className="table-container">
        <table className="table is-striped is-narrow is-hoverable is-fullwidth">
          <thead className="is-italic">
            <tr>
              <td>{t("name")}</td>
              <td>{t("modellinglang")}</td>
              <td>{t("exerciseType")}</td>
              <td>{t("created")}</td>
              <td>{t("totalScore") + "/" + t("maxTotalScore")}</td>
              <td>{t("options")}</td>
              <td>{t("Revised")}</td>
            </tr>
          </thead>
          <tbody>
            {currentSubmissionsForUser.map((submission: any) => {
              return getStudentSubmissionsTableItem(submission);
            })}
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td className="has-text-center has-text-weight-bold">
                {sum_totalscore}/{sum_maxscore} {t("totalScore")}
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  function getEmptyMessageStudentSubmissions() {
    return <p className="is-size-7">{t("noSubmissions")} </p>;
  }

  let renderStudentSubmissionsTable = (
    <div className="card my-2">
      <div className="card-content">
        <div className="columns">
          <div className="column has-text-left">
            {currentSubmissionsForUser.length > 0 && getStudentSubmissionsTable()}
            {currentSubmissionsForUser.length === 0 && getEmptyMessageStudentSubmissions()}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="section">
      <div className="card mt-6">
        <header className="card-header">
          <p className="card-header-title">{t("submissions")}</p>
        </header>
        <div className="card-content">
          <div className="content">
            <div className="columns">
              <div className="column has-text-weight-light is-italic">
                {t("allSubmissionsForStudent")}
                <div className="has-text-right">
                  <Tooltip
                    icon="circle-info"
                    class="icon tooltip no-border is-white has-tooltip-multiline has-tooltip-top"
                    content={t("CheckfornewFeedback")}
                  ></Tooltip>
                  <button className="button has-text-right is-primary" onClick={() => checkforCompleteAssessment()}>
                    <FaRedo />
                  </button>
                  <br />
                </div>
              </div>
            </div>
            {renderStudentSubmissionsTable}
          </div>
        </div>
      </div>
    </div>
  );
};
