import Tooltip from "@kea-mod/common/tooltip";
import { useTranslation } from "react-i18next";
import { ModelingLanguage } from "@kea-mod/common";

type Props = {
  language: string;
  h: Function;
};

export const LanguageField = (props: Props) => {
  const { t } = useTranslation();

  const onChange = (event: any) => {
    props.h(event);
  };

  return (
    <div className="field">
      <label className="label" htmlFor="title">
        {t("modellinglang")}
        <Tooltip
          icon="circle-info"
          class="icon tooltip no-border is-white   has-tooltip-multiline has-tooltip-top"
          content={t("languageTooltip")}
        ></Tooltip>
      </label>
      <div className="select">
        <select value={props.language} onChange={onChange} id="language" name="language">
          {props.language === "" ? (
            <option value="" disabled>
              {t("selectModellinglang")}
            </option>
          ) : (
            <option value="" hidden disabled></option>
          )}

          <option value={ModelingLanguage.ANY}>{t("ANY")} </option>
          <option value={ModelingLanguage.BPMN}>{t("BPMN")} </option>
          <option value={ModelingLanguage.EPC}>{t("EPC")}</option>
          <option value={ModelingLanguage.ER}>{t("ER")}</option>
          <option value={ModelingLanguage.PN}>{t("PN")}</option>
          <option value={ModelingLanguage.UML_CD}>{t("UML_CLASS")}</option>
          <option value={ModelingLanguage.UML_SEQ}>{t("UML_SEQUENCE")}</option>
          <option value={ModelingLanguage.UML_SD}>{t("UML_STATE")}</option>
          <option value={ModelingLanguage.UML_AD}>{t("UML_ACTIVITY")}</option>
        </select>
      </div>
    </div>
  );
};
