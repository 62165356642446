// @flow

import { useEffect } from "react";
import { useAssignmentContext } from "../context/AssignmentContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ConfirmButton from "../shared/components/ConfirmButton";

const dateOptions = {
  weekday: "short",
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
} as any;

export const AssignmentList = () => {
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();
  const assignmentContext = useAssignmentContext();
  useEffect(() => {
    assignmentContext.updateAssignments();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setEditAssignment = (id: any) => {
    navigate("" + id);
  };

  const setAssessment = (id: any) => {
    navigate("/assessment/" + id);
  };

  const addAssignment = () => {
    navigate("/add-assignment");
  };

  const getTableItem = (assignment: any) => {
    return (
      <tr key={assignment.id}>
        <th>{assignment.name}</th>

        <th>{new Date(assignment.updatedAt).toLocaleDateString(i18n.language, dateOptions)}</th>
        <th>{assignment.quickAccess}</th>

        <td>
          <button className="button   is-primary" onClick={() => setEditAssignment(assignment.id)}>
            {t("edit")}
          </button>
          <button className="button  " onClick={() => setAssessment(assignment.id)}>
            {t("assessment")}
          </button>

          <ConfirmButton
            id={assignment.id}
            name={assignment.name}
            proceedAction={assignmentContext.deleteAssignment}
            question={`deleteConfirmationAssignment`}
            explanation={`deleteConfirmationAssignmentIrreversible`}
            color="is-danger"
            buttonLabel={`delete`}
          />
        </td>
      </tr>
    );
  };

  const getAssignmentTable = () => {
    return (
      <div className="table-container">
        <table className="table is-striped is-narrow is-hoverable is-fullwidth">
          <thead className="is-italic">
            <tr>
              <td>{t("name")}</td>
              <td>{t("updated")}</td>
              <td className="is-hidden-mobile">{t("quickAccessCode")}</td>
              <td className="is-hidden-mobile">{t("options")}</td>
            </tr>
          </thead>
          <tbody>
            {assignmentContext.assignments.map((assignment: any) => {
              return getTableItem(assignment);
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const getEmptyMessage = () => {
    return <p className="is-size-7">{t("noAssignment")} </p>;
  };

  let renderAssignmentTable = (
    <div className="card my-2">
      <div className="card-content">
        <div className="content">
          <div className="columns"></div>
          <div className="columns">
            <div className="column has-text-left">
              {assignmentContext.assignments.length > 0 && getAssignmentTable()}
              {assignmentContext.assignments.length === 0 && getEmptyMessage()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="section">
      <div className="card mt-6">
        <header className="card-header">
          <p className="card-header-title">{t("assignments")}</p>
        </header>
        <div className="card-content">
          <div className="content">
            <div className="columns">
              <div className="column has-text-weight-light is-italic">{t("allAssignments")}</div>
            </div>
            <button className="button   is-info" onClick={() => addAssignment()}>
              {t("newAssignment")}
            </button>
            {renderAssignmentTable}
          </div>
        </div>
      </div>
    </div>
  );
};
