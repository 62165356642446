import Tooltip from "@kea-mod/common/tooltip";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import FeedbackDataService from "../../../axios/services/feedback.service";
import { ValuationField } from "./ValuationField";

type Props = {
  toggleModal: Function;
  feedback: any;
};

export const FeedbackEdit = (props: Props) => {
  const { t } = useTranslation();

  const [newFeedback, setNewFeedback] = useState<any>(props.feedback);
  const onChangeContent = (event: any) => {
    let value = event.target.value;

    setNewFeedback({
      ...newFeedback,
      content: value,
    });
  };

  const onChangeCategory = (event: any) => {
    let value = event.target.value;

    setNewFeedback({
      ...newFeedback,
      category: value,
    });
  };

  const updateFeedback = async () => {
    let data = {
      content: newFeedback.content,
      category: newFeedback.category,
      valuation: newFeedback.valuation,
    };

    if (props.feedback.grader === "Manual Feedback") {
      await FeedbackDataService.updateManual(props.feedback.id, data)
        .then(() => {
          props.toggleModal();
          return;
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      await FeedbackDataService.update(props.feedback.id, data)
        .then(() => {
          props.toggleModal();
          return;
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const onChangeValuation = (event: any) => {
    let value = event.target.value;
    //console.log(value);

    setNewFeedback({
      ...newFeedback,
      valuation: value,
    });
  };

  return (
    <div>
      <div>
        <div className="field">
          <label className="label" htmlFor="content">
            {t("content")}
            <Tooltip
              icon="circle-info"
              class="icon tooltip no-border is-white   has-tooltip-multiline has-tooltip-right"
              content={t("contentTooltip")}
            ></Tooltip>
          </label>
          <input
            type="text"
            className="input"
            id="content"
            name="content"
            value={newFeedback.content}
            onChange={onChangeContent}
          />
        </div>
      </div>
      <div>
        <div className="field">
          <label className="label" htmlFor="category">
            {t("category")}
            <Tooltip
              icon="circle-info"
              class="icon tooltip no-border is-white   has-tooltip-multiline has-tooltip-top"
              content={t("categoryTooltip")}
            ></Tooltip>
          </label>
          <input
            type="text"
            className="input"
            id="category"
            name="category"
            value={newFeedback.category}
            onChange={onChangeCategory}
          />
        </div>
        <ValuationField activeFeedback={newFeedback} h={onChangeValuation} />

        <button className="button is-success" onClick={() => updateFeedback()}>
          {t("update")}
        </button>
      </div>
    </div>
  );
};
