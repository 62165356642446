// @flow

import { useEffect, useState } from "react";
import { useExerciseContext } from "../../context/ExerciseContext";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useAssignmentContext } from "../../context/AssignmentContext";
import { useSubmissionContext } from "../../context/SubmissionContext";
import Tooltip from "@kea-mod/common/tooltip";
export const AssessmentComponent = () => {
  const { t } = useTranslation();
  let { id } = useParams(); //assignmentId
  const navigate = useNavigate();
  const exerciseContext = useExerciseContext();
  const assignmentContext = useAssignmentContext();
  const submissionContext = useSubmissionContext();
  const [numberOfSubmissions, setNumberOfSubmissions] = useState([]);

  useEffect(() => {
    exerciseContext.all(); //update all exercises in context
    assignmentContext.updateAssignments();
    submissionContext.getAllSubmissions(parseInt(id as string));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    exerciseContext.filterExercises(parseInt(id as string)); //filter all exercises that are in the current assignment

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exerciseContext.exercises]);

  useEffect(() => {
    let exercisesInAssignment = exerciseContext.exercisesInAssignment;

    let numberOfSubmissionsArray = [] as any;

    const getNumberOfSubmissions = async () => {
      for (let i = 0; i < exercisesInAssignment.length; i++) {
        let numberOfSubmissions = await submissionContext.getNumberOfSubmissions(exercisesInAssignment[i].id);
        numberOfSubmissionsArray.push(numberOfSubmissions);
      }
      setNumberOfSubmissions(numberOfSubmissionsArray);
    };

    getNumberOfSubmissions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exerciseContext.exercisesInAssignment]);

  function getExerciseTableItem(exercise: any, index: any) {
    return (
      <tr key={"Exercise" + exercise.id}>
        <th>{exercise.name}</th>
        <th>{t(exercise.type)}</th>
        <th>{t(exercise.language.name)}</th>
        {numberOfSubmissions.length > 0 ? <th>{numberOfSubmissions[index]}</th> : <td>{"loading..."}</td>}
        <td>
          <button className="button is-success" onClick={() => navigate("/submissions/exercise/" + exercise.id)}>
            {t("submissions")}
          </button>
        </td>
      </tr>
    );
  }

  function getExerciseTable() {
    return (
      <div className="table-container">
        <table className="table is-striped is-narrow is-hoverable is-fullwidth">
          <thead className="is-italic">
            <tr>
              <td>{t("name")}</td>
              <td>{t("type")}</td>
              <td>{t("modellinglang")}</td>
              <td>{t("numberOfSubmissions")}</td>
              <td>{t("options")}</td>
            </tr>
          </thead>
          <tbody>
            {exerciseContext.exercisesInAssignment.map((exercise: any, index: any) => {
              return getExerciseTableItem(exercise, index);
            })}
          </tbody>
        </table>
      </div>
    );
  }

  function getEmptyMessageExercises() {
    return <p className="is-size-7">{t("noExercises")} </p>;
  }

  let renderExerciseTable = (
    <div className="card my-2">
      <div className="card-content">
        <div className="columns">
          <div className="column has-text-left">
            {exerciseContext.exercisesInAssignment.length > 0 && getExerciseTable()}
            {exerciseContext.exercisesInAssignment.length === 0 && getEmptyMessageExercises()}
          </div>
        </div>
      </div>
    </div>
  );

  function getStudentsTableItem(student: any) {
    let name = student.user.token;
    console.log(student);
    if (student.user.matriculationNumber) {
      name = student.user.givenName + " " + student.user.familyName + " (" + student.user.matriculationNumber + ")";
    }
    console.log(name);
    return (
      <tr key={"Student" + student.userId}>
        <th>{name}</th>
        <th>{student.submissionExercise.length}</th>
        <td>
          <button
            className="button is-success"
            onClick={() => {
              localStorage.setItem("currentUser", name);
              localStorage.setItem("currentUserId", student.userId);
              navigate("/submissions/student/" + id + "/" + student.userId);
            }}
          >
            {t("submissions")}
          </button>
        </td>
      </tr>
    );
  }

  function getStudentsTable() {
    return (
      <div className="table-container">
        <table className="table is-striped is-narrow is-hoverable is-fullwidth">
          <thead className="is-italic">
            <tr>
              <td>{t("name")}</td>
              <td>{t("numberOfSubmissions")}</td>
              <td>{t("options")}</td>
            </tr>
          </thead>
          <tbody>
            {submissionContext.submissions.map((student: any) => {
              return getStudentsTableItem(student);
            })}
          </tbody>
        </table>
      </div>
    );
  }

  function getEmptyMessageStudents() {
    return <p className="is-size-7">{t("noSubmissions")} </p>;
  }

  let renderStudentsTable = (
    <div className="card my-2">
      <div className="card-content">
        <div className="columns">
          <div className="column has-text-left">
            {submissionContext.submissions.length > 0 && getStudentsTable()}
            {submissionContext.submissions.length === 0 && getEmptyMessageStudents()}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="section">
      <div className="card mt-6">
        <header className="card-header">
          <p className="card-header-title">
            {t("assessment")}
            <Tooltip
              icon="circle-info"
              class="mt-3 icon tooltip no-border is-white has-tooltip-multiline"
              content={t("assessmentcardtooltip")}
            ></Tooltip>
          </p>
        </header>
        <div className="card-content">
          <div className="content">
            <div className="columns">
              <div className="column has-text-weight-light is-italic">{t("allExercisesInAssignment")}</div>
            </div>
            {renderExerciseTable}
          </div>
        </div>
      </div>

      <div className="card mt-6">
        <header className="card-header">
          <p className="card-header-title">
            {t("students")}
            <Tooltip
              icon="circle-info"
              class="mt-3 icon tooltip no-border is-white has-tooltip-multiline"
              content={t("studentcardtooltip")}
            ></Tooltip>
          </p>
        </header>
        <div className="card-content">
          <div className="content">
            <div className="columns">
              <div className="column has-text-weight-light is-italic">{t("studentSubmissionsForAssignment")}</div>
            </div>
            {renderStudentsTable}
          </div>
        </div>
      </div>
    </div>
  );
};
