import { useTranslation } from "react-i18next";

function ConfirmDialog({ SetOpen, SetClose, name, question, explanation, buttonCancelText, buttonProceedText }: any) {
  const { t } = useTranslation();
  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card" style={{ width: "40%", height: "40%" }}>
        <header className="modal-card-head">
          <p className="modal-card-title">{t("confirmationRequest")}</p>
          <button className="delete" aria-label="close" onClick={SetClose}></button>
        </header>
        <section className="modal-card-body">
          {t(`${question}`)}
          <ul style={{ listStyleType: "disc", margin: "5%" }}>
            <li>{name}</li>
          </ul>
          {t(`${explanation}`)}
        </section>
        <footer className="modal-card-foot">
          <button className="button is-danger " onClick={SetClose}>
            {t(`${buttonCancelText}`)}
          </button>
          <button className="button is-primary" onClick={SetOpen}>
            {t(`${buttonProceedText}`)}
          </button>
        </footer>
      </div>
    </div>
  );
}

export default ConfirmDialog;
