// @flow

import { AddModelCard } from "./AddModelCard";
import { ModelCardComponent } from "./ModelCardComponent";

type Props = {
  models: Array<any>;
  previewModel: (id: string) => void;
  openModellingTool: () => void;
  editModel: (id: string) => void;
  deleteModel: (id: string) => void;
  exerciseType: string;
};

export const ModelCardsComponent = (props: Props) => {
  return (
    <div className="section">
      <div className="columns is-multiline is-centered">
        {props.models.map((model) => {
          return (
            <ModelCardComponent
              key={model.id}
              selected={false}
              modelId={model.id}
              editModel={props.editModel}
              deleteModel={props.deleteModel}
            />
          );
        })}
        {/* only allow to create one input model for model understanding exercises */}
        {props.exerciseType === "understanding" && props.models.length === 1 ? (
          ""
        ) : (
          <AddModelCard openModellingTool={props.openModellingTool} />
        )}
      </div>
    </div>
  );
};
