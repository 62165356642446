// @flow

import { useEffect } from "react";
import { ModelCardsComponent } from "../components/Models/ModelCardsComponent";

import { useModelContext } from "../context/ModelContext";
import { useModelEditorModalContext } from "../context/ModelEditorModalContext";

type Props = {
  modelList: any;
  modelingLanguage: string;
  exerciseId: any;
  exerciseType: string;
  modelType: string;
};

export const ModelList = (props: Props) => {
  const modelContext = useModelContext();
  const ModelEditorModalContext = useModelEditorModalContext();

  useEffect(() => {
    modelContext.update(props.exerciseId, props.modelType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openModellingTool = () => {
    ModelEditorModalContext.setModelingLanguage(props.modelingLanguage);
    ModelEditorModalContext.setType(props.modelType);
    ModelEditorModalContext.setExerciseId(props.exerciseId);
    ModelEditorModalContext.toggleModal();
  };

  const modelPreview = (modelId: any) => {
    ModelEditorModalContext.setModelId(modelId);
    ModelEditorModalContext.setExportEnabled(true);
    ModelEditorModalContext.toggleModalpreview();
  };

  const editModel = (modelId: any) => {
    ModelEditorModalContext.setModelingLanguage(props.modelingLanguage);
    ModelEditorModalContext.setModelId(modelId);
    ModelEditorModalContext.toggleModalEdit();
  };

  const deleteModel = (modelId: any) => {
    modelContext.delete(modelId, props.exerciseId, props.modelType);
  };

  return (
    <div>
      <ModelCardsComponent
        editModel={editModel}
        deleteModel={deleteModel}
        previewModel={modelPreview}
        models={props.modelList}
        openModellingTool={openModellingTool}
        exerciseType={props.exerciseType}
      />
    </div>
  );
};
