import Tooltip from "@kea-mod/common/tooltip";
import { useTranslation } from "react-i18next";
import type { Exercise } from "../../../types/Exercise";

type Props = {
  activeExercise: Exercise;
  h: Function;
};

export const FeedUpField = (props: Props) => {
  const { t } = useTranslation();

  const onChange = (event: any) => {
    props.h(event);
  };

  return (
    <div className="field">
      <label className="label" htmlFor="feedUp">
        {t("feedUp")}
        <Tooltip
          icon="circle-info"
          class="icon tooltip no-border is-white   has-tooltip-multiline has-tooltip-top"
          content={t("feedUp_tooltip")}
        ></Tooltip>
      </label>
      <input
        type="text"
        className="input"
        id="feedUp"
        name="feedUp"
        value={props.activeExercise.feedUp}
        onChange={onChange}
      />
    </div>
  );
};
