import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LngDetector from "i18next-browser-languagedetector";
// translations. should be moved to separate files
const resources = {
  en: {
    translation: {
      feedForward: "Next steps",
      feedUp: "Learning outcomes",
      feedForward_pos: "Feed-forward (positive)",
      feedForward_neg: "Feed-forward (negative)",
      feedForward_threshold: "Feed-forward (threshold)",
      feedUp_tooltip: "Learning outcomes",
      feedForward_pos_tooltip: "Feed-forward (positive)",
      feedForward_neg_tooltip: "Feed-forward (negative)",
      feedForward_threshold_tooltip: "Feed-forward (threshold)",
      deleteFeedbackQuestion: "Do you really want to delete the following feedback message?",
      deleteFeedbackExplanation: "",
      contentTooltip: "Here you should enter the content of the feedback message.",
      categoryTooltip:
        "You have to enter a self-chosen category here (usually a single word). All feedback messages will be displayed grouped by category.",
      valuationTooltip:
        "You have to configure whether the feedback message is positive, neural or negative. Other should be reserved for system messages.",
      commentTooltip:
        "This field is optional. You can enter a comment here that will be displayed on the feedback page next to the result score.",
      absolutescoreTooltip:
        "If you want to correct the calculated total score, you can enter an absolute score here. Please note that it should be greater or equal to zero and lesser or equal to the maximum total score.",
      relativescoreTooltip:
        "If you want to correct the calculated total score, you can enter a relative score between 0 and 1 here. That means if the maximum total score is 10 points and you want to award 4 points, you have to enter 0.4. ",
      maxscoreAssTooltip:
        "Here you can correct the maximum total score that is initially derived from the maximum total score configured for the corresponding exercise. Warning: Keep in mind that this should always be changed in every student submission!",
      isrevisedTooltip:
        "If the automatic assessment of the assignment after submission has been disabled, this result will only be published when you activate this option here.",
      feedbackTooltip:
        "Here all feedback to the submission is shown. You can add, edit and remove each feedback message.",
      descriptionMCTooltip:
        "Describe in detail what you expect from your students in this exercise, so they know what to do here. If possible make a reference to corresponding learning objectives.",
      languageTooltip:
        "Setting a modeling language here classifies this exercise and also restricts the available modeling elements in the modeling tool. The restriction is active while creating an input model, sample solutions or for students entering solution models in the context of this exercise. ",
      scenarioTooltip:
        "The scenario represents the existing or planned section of the real world (as-is) or (to-be) that students should model.",
      schemeTooltip:
        "Assessment schemes represent the configuration of the automatic assessment for this exercise. In the current development stage you have to choose from the given list that represents a few pre-defined assessment schemes. Some of them are exercise-specific and should not be used for other exercises. If you want to create exercise-specific assessment schemes for new exercises or an individual combination and weighting of several aspects, please contact us via info@keamod.de and we will assist you further. ",
      maxscoreTooltip: "Specify the amount of maximal points a student can get for this exercise.",
      samplesolutionsTooltip:
        "Sample solutions can be handed over to specific grading services (if specified in the assessment scheme) to be used for a comparison during the automatic assessment. This is currently supported by BPMN Semantic Checker and Semantic Grader only. If you want to check submissions manually, use the Dummy Grader (100%) scheme.",
      inputmodelTooltip:
        "The input model is shown to students when they work on this exercise. The answer items should be related to this model.",
      questionTooltip:
        "Phrase describing the task with regard to the following answer items, e.g. ‘Are the following statements about the given model true or false’",
      answerTooltip:
        "Answer items are statements about the given model which are either true or false. Try not to include answer items which can be true or false under specific assumptions. Also if you include two answer items that are mutually exclusive, students can guess that either one of them is true and the other false.",
      descriptionTooltip:
        "Here you can add an description of the assignment. It is advisable to communicate learning objectives and context information to your students.",
      datesTooltip: "Students can only work on an assignment during the time period between start date and end date.",
      qacTooltip:
        "Distribute this quick access code to your students so they can add this assignment to their open assignments list.",
      chooseModelType: "Choose one of the following exercise types for the new exercise you want to create:",
      toolTipMC: "In model creation exercises you have to create a model for a given scenario description",
      toolTipMU: "In model understanding exercises you have to select appropriate statements about a given model",
      assessmentcardtooltip:
        "This component is grouped by the exercises. Within an exercise there are the submissions for each student",
      studentcardtooltip:
        "This component is grouped by the students. Within a student there are the submissions for each exercise",
      noAnswer: "No answer",
      creation: "Model creation",
      understanding: "Model understanding",
      totalAssignment: "Assignment total",
      BPMN: "BPMN",
      EPC: "EPC",
      ANY: "Any",
      PN: "Petri net",
      UML_CD: "UML class diagram",
      UML_CLASS: "UML class diagram",
      UML_SD: "UML state diagram",
      UML_STATE: "UML state diagram",
      UML_SEQ: "UML sequence diagram",
      UML_SEQUENCE: "UML sequence diagram",
      UML_AD: "UML activity diagram",
      UML_ACTIVITY: "UML activity diagram",
      ER: "ER diagram",
      headerTitleTeacher: "Author UI",
      headerTitleStudent: "Student UI",
      assignment: "Assignment",
      assignments: "Assignments",
      newAssignment: "Create new assignment",
      assignmentList: "Assignments",
      exercise: "Exercise",
      exercises: "Exercises",
      newExercise: "Create exercise",
      newScheme: "Create scheme",
      exerciselist: "Exercises ",
      modellist: "Models ",
      schemelist: "Schemes ",
      task: "Task",
      search: "Search",
      deleteAll: "Delete all",
      title: "Title",
      description: "Description",
      status: "Status",
      edit: "Edit",
      chooseTask: "Choose task",
      chooseScheme: "Choose scheme",
      chooseModel: "Choose model",
      signIn: "Sign in",
      published: "Published",
      pending: "Pending",
      unpublish: "Unpublish",
      delete: "Delete",
      update: "Update",
      updated: "Updated",
      chooseAssignment: "Please click on an assignment",
      submitSuccessfull: "Successfully submitted!",
      send: "Send",
      enterTitle: "Enter title",
      artifacts: "Artifacts",
      create: "Create",
      newSampleSolution: "Create new sample solution",
      created: "Created",
      add: "Add",
      name: "Name",
      type: "Type",
      modellinglang: "Modeling language",
      modellingTool: "Modeling tool",
      models: "Models",
      language: "Language",
      scenario: "Scenario",
      modelCreation: "Model creation",
      modelUnderstanding: "Model understanding",
      bpmn: "BPMN",
      epc: "EPC",
      petrinet: "Petri net",
      umlClass: "UML class diagram",
      umlSequence: "UML sequence diagram",
      sampleSolution: "Sample solution(s)",
      newModel: "Create new sample solution",
      createModelButton: "Create",
      model: "Model",
      modelId: "Model ID",
      chooseFile: "Choose file",
      answer: "Answer items",
      wronganswers: "Wrong answers",
      correctanswers: "Correct answers",
      scheme: "Assessment scheme",
      schemes: "Assessment schemes",
      visibility: "Visibility",
      private: "private",
      internal: "internal",
      public: "public",
      question: "Question",
      signUp: "Sign up",
      content: "Content",
      logOut: "Logout",
      existing_model: "Existing model",
      assessment: "Assessment",
      Assessment: "Assessment",
      startAssessment: "Start assessment",
      showAssessment: "Results",
      currentScheme: "The currently active assessment scheme is: ",
      explainButton:
        "With the buttons for 'start assessment' you can either start the automatic assessment for all submissions or for a specific submission of this exercise. The active assessment scheme will be used. If you want to change the active assessment scheme, go to the configuration page of the corresponding exercise. ",
      selectSubmission: "Select submission",
      refresh: "refresh",
      selectModellinglang: "Select modeling language",
      selectScheme: "Select assessment scheme",
      selectSampleSolution: "Select sample solution",
      selectVisibility: "Select visibility",
      addAnswer: "Add answer item",
      answers: "Answer items",
      correct: "Correct",
      pn: "Petri net",
      uml_cd: "UML class diagram",
      uml_sd: "UML state diagram",
      uml_seq: "UML sequence diagram",
      ModelsEmpty: "No models selected",
      startDate: "Start date",
      endDate: "End date",
      addModel: "Add model",
      editModel: "Edit model",
      deleteModel: "Delete model",
      createExercise: "Create new exercise",
      createFeedback: "Create new feedback",
      quickAccessCode: "Quick Access Code",
      options: "Options",
      noExercises: "There are no exercises yet!",
      saveAssignment: "Save Assignment",
      saveExercise: "Save Exercise",
      noSubmissions: "There are no submissions yet!",
      noFeedbacks: "There are no feedbacks yet!",
      back: "Back",
      noAssessment: "There is no assessment yet!",
      noAssignment: "There are no assignments yet!",
      submissions: "Submissions",
      cancel: "Cancel",
      allSubmissionsForExercise: "Here you can see all submissions for this exercise.",
      allSubmissionsForStudent: "Here you can see all submissions for the chosen student.",
      allExercisesInAssignment: "Here you can find all exercises of this assignment. ",
      allAssignments:
        "Here you can create new assignments and edit them. It is also possible to click on 'Assessment' to see all exercises included in the assignment and the corresponding submissions.",
      comment: "Comment",
      totalScore: "Total score",
      maxTotalScore: "Maximum total score",
      noModel: "There is no sample solution yet!",
      autoAssessmentAss: "Automatic assessment on submission",
      autoAssessmentEx: "Automatic assessment on saving solution",
      save: "Save",
      noComment: "No comment",
      ManualAssessment: "Manual revision",
      category: "Category",
      valuation: "Valuation",
      autoAssessmentExDesc:
        "Setting this option to yes, solutions for this exercise will be assessed automatically (i.e. feedback is generated) each time the student saves his/her solution. This allows students to work towards a good-solution step-by-step (multiple-try feedback).",
      autoAssessmentAssDesc:
        "Setting this option to yes, all solutions for all exercises in this assignment will be assessed automatically (i.e. feedback is generated) when the student submits the assignment. If you want to revise the automatic assessment before it is published to students, you should not activate this option. Instead you can go to 'Assessment' and start the automatic assessment manually and revise the results before they are published.",
      noAnswers: "There are no answer items yet!",
      solution: "Solution",
      noSolution: "There is no solution yet!",
      exerciseModelCreation: "Exercise Model Creation",
      exerciseModelUnderstanding: "Exercise Model Understanding",
      true: "True",
      false: "False",
      integratedFeedback: "Integrated Feedback",
      ShowModel: "Show Model",
      preview: "Preview",
      MaxScore: "Maximum totalscore",
      SwitchRoleStudent: "Go to student view",
      newInputModel: "Create input model",
      inputModel: "Input model",
      proceed: "Yes, proceed",
      deleteConfirmationAssignment: "Do you really want to delete the following assignment?",
      deleteConfirmationAssignmentIrreversible:
        "This process is irreversible. All corresponding exercises, submissions and assessments will be lost.",
      confirmationRequest: "Confirmation Request",
      deleteConfirmationExercise: "Do you really want to delete the following exercise?",
      deleteConfirmationExerciseIrreversible:
        "This process is irreversible. All corresponding submissions and assessments will be lost.",
      FeedbackForExercise: "Feedback for exercise",
      gradingServices: "Grading Services",
      gradingService: "Grading Service",
      PositionX: "Position X",
      PositionY: "Position Y",
      requested: "Started",
      finished: "Finished",
      percentage: "Percentage",
      Score: "Score",
      weight: "Weight",
      result: "Result",
      partialScore: "Partial score",
      resulttype: "Result type",
      RELATIVE: "relative",
      ABSOLUTE: "absolute",
      editFeedback: "Edit Feedback",
      noeditwarning1: "If you want to revise multiple choice answers, please go to the ",
      noeditwarning2: "exercise configuration",
      noeditwarning3: "and rerun the assessment afterwards.",

      assessAllSubmissions: "Start assessment for all submissions",

      loggedInAs: "Logged in as",
      Revised: "Published",
      numberOfSubmissions: "Number of submissions",
      numberOfPublished: "Number of published submissions",
      isRevised: "Publish results",
      avgScores: "(average total score)",
      user: "User",
      startAssessmentQuestion:
        "Do you really want to trigger an assessment for all submissions of the following exercise?",
      startAssessmentSingleQuestion:
        "Do you really want to trigger an assessment for the following person's submission?",
      startAssessmentSingleQuestionExercise:
        "Do you really want to trigger an assessment for the submission of the following exercise?",
      startAssessmentExplanation:
        "If you continue, the previous result will be overwritten. Any corrections you have already made will also be lost. If you don't want this to happen, you can also trigger an assessment specifically for individual submissions.",
      startAssessmentSingleExplanation:
        "If you continue, the previous result will be overwritten. Any corrections you have already made will also be lost. ",
      CheckfornewFeedback:
        "If you have started the automatic assessment, it takes a while until all assessments have been performed. Use the reload button to the right to refresh the results from time to time until all assessments are finished.",
      students: "Students",
      studentSubmissionsForAssignment:
        "Here you can find all students that already submitted exercises of the assignment.",
      exerciseType: "Exercise type",
    },
  },
  de: {
    translation: {
      feedForward: "Nächste Schritte",
      feedUp: "Lernziele (Feed-Up)",
      feedForward_pos: "Feed-Forward (positiv)",
      feedForward_neg: "Feed-Forward (negativ)",
      feedForward_threshold: "Feed-Forward Schwellwert",
      feedUp_tooltip: "Lernziele (Feed-Up)",
      feedForward_pos_tooltip: "Feed-Forward (positiv)",
      feedForward_neg_tooltip: "Feed-Forward (negativ)",
      feedForward_threshold_tooltip: "Feed-Forward Schwellwert",
      CheckfornewFeedback:
        "Wenn Sie die automatisierte Bewertung angestoßen haben, dauert es eine Weile bis alle Ergebnisse berechnet wurden. Verwenden Sie den 'Neu Laden'-Button rechts, um diese Seite gelegentlich neuzuladen, bis alle Ergebnisse eingetragen wurden.",
      deleteFeedbackQuestion: "Möchten Sie die folgende Feedback-Nachricht wirklich löschen?",
      deleteFeedbackExplanation: "",
      startAssessmentQuestion:
        "Möchten Sie wirklich die Bewertung für alle Einreichungen zur folgenden Aufgabe anstoßen?",
      startAssessmentSingleQuestion:
        "Möchten Sie wirklich eine Bewertung für die Einreichung der folgenden Person anstoßen?",
      startAssessmentSingleQuestionExercise:
        "Möchten Sie wirklich eine Bewertung für die Einreichung der folgenden Aufgabe anstoßen?",
      startAssessmentExplanation:
        "Wenn Sie fortfahren, werden alle vorherigen Ergebnisse überschrieben. Auch eventuelle Korrekturen, die Sie bereits vorgenommen haben, gehen dabei verloren. Wenn Sie dies nicht möchten, können Sie auch gezielt für einzelne Einreichungen die automatisierte Bewertung anstoßen.",
      startAssessmentSingleExplanation:
        "Wenn Sie fortfahren, wird das vorherige Ergebnis überschrieben. Auch eventuelle Korrekturen, die Sie bereits vorgenommen haben, gehen dabei verloren. ",
      user: "Erstellt von",
      avgScores: "(Durchschnittliche Gesamtpunktzahl)",
      explainButton:
        "Mit den Buttons 'Bewertung starten' können Sie die automatisierte Bewertung für alle oder für eine bestimmte Einreichung anstoßen. Dabei wird das aktive Bewertungsschema verwendet. Wenn Sie dieses ändern möchten, gehen Sie zur Konfigurationsseite der betreffenden Aufgabe.",
      contentTooltip: "Hier sollten Sie den Inhalt der Feedback-Nachricht festlegen.",
      categoryTooltip:
        "Sie müssen hier eine selbstgewählte Kategorie eingeben (typischerweise ein einzelnes Wort, z.B. 'Syntax'). Alle Feedback-Nachrichten werden nach Kategorie gruppiert angezeigt.",
      valuationTooltip:
        "Hier müssen Sie einstellen, ob die Feedback-Nachricht eine positive, negative oder neutrale Beurteilung enthält. Die Kategorie 'other' sollte für Systemmeldungen reserviert werden. ",
      commentTooltip:
        "Dieses Feld ist optional. Sie können hier einen Kommentar einfügen, der auf der Feedback-Seite neben dem Bewertungsergebnis angezeigt wird.",
      absolutescoreTooltip:
        "Wenn Sie die berechnete Gesamtpunktzahl korrigieren möchten, können Sie hier einen absoluten Wert eingeben. Bitte beachten Sie dabei, dass dieser Wert größer gleich 0 und kleiner gleich der maximalen Gesamtpunktzahl sein sollte.",
      relativescoreTooltip:
        "Wenn Sie die berechnete Gesamtpunktzahl korrigieren möchten, können Sie hier einen relativen Wert zwischen 0 und 1 eingeben. Wenn beispielsweise die maximale Gesamtpunktzahl 10 Punkte beträgt und Sie 4 Punkte vergeben möchten, geben Sie hier 0.4 ein.",
      maxscoreAssTooltip:
        "Hier können Sie die maximale Gesamtpunktzahl anpassen, die ursprünglich von der maximalen Gesamtpunktzahl abgeleitet wurde, die für die zugehörige Aufgabe konfiguriert wurde. Warnung: Beachten Sie bitte, dass dies stets in allen Einreichungen angepasst werden sollte!",
      isrevisedTooltip:
        "Wenn die automatisierte Bewertung der Aufgabenreihe bei Einreichung deaktiviert wurde, wird dieses Ergebnis erst veröffentlicht, wenn Sie diese Option hier aktivieren.",
      feedbackTooltip:
        "Hier werden alle Feedback-Nachrichten zu dieser Einreichung angezeigt. Sie können neue Feedback-Nachrichten hinzufügen und bestehende bearbeiten oder löschen.",
      isRevised: "Ergebnisse veröffentlichen",
      descriptionMCTooltip:
        "Beschreiben Sie im Detail, was von Studierenden bei der Bearbeitung dieser Aufgabe erwartet wird. Gegebenenfalls können hier auch Hinweise auf bestimmte Lernziele wiederholt werden. ",
      languageTooltip:
        "Das Festlegen einer Modellierungssprache dient der Klassifikation der Aufgabe und schränkt geichzeitig die verfügbaren Modellierungselemente im Modellierungseditor ein. Die Einschränkung gilt dabei im gesamten Kontext dieser Aufgabe für die Erstellung eines gegebenen Modells, Musterlösungen und für Studierende, die eine Lösung für eine 'Modell verstehen'-Aufgabe eingeben. ",
      scenarioTooltip:
        "Das Szenario beschreibt den zu modellierenden existierenden oder zu entwickelnden (to-be oder as-is) Sachverhalt.",
      schemeTooltip:
        "Bewertungsschemata legen die Konfiguration der automatisierten Bewertung dieser Aufgabe fest. Im aktuellen Entwicklungsstadium können Sie aus einer Liste vorgegebener Bewertungsschemata auswählen. Einige davon sind aufgabenspezifisch und sollten nicht für andere Aufgaben verwendet werden. Wenn Sie aufagbenspezifische Bewertungsschemata für neue eigene Aufgaben erstellen möchten oder eine individuelle Kombination und Gewichtung verschiedener Aspekte vornehmen möchten, kontaktieren Sie uns unter info@keamod.de, damit wir Sie dabei unterstützen können. ",
      maxscoreTooltip:
        "Geben Sie die maximale Punktzahl an, die Studierende bei der Bearbeitung dieser Aufgabe erreichen können",
      samplesolutionsTooltip:
        "Musterlösungen können bestimmten Bewertungsdiensten übergeben werden (wenn dies im Bewertungsschema spezifiziert wurde) um einen Vergleich während der automatisierten Bewertung zu erreichen. Dies wird momentan nur durch den BPMN Semantic Checker und den Semantic Grader unterstützt. Wenn Sie die Einreichungen manuell bewerten möchten, wählen Sie hier den Dummy Grader (100%) als Bewertungsschema aus.",
      inputmodelTooltip: "Das gegebene Modell wird Studierenden angezeigt, wenn sie diese Aufgabe bearbeiten.",
      questionTooltip:
        "Legen Sie eine konkrete Frage oder Aufgabenstellung in Bezug auf die vorgegebenen Antwortoptionen fest, z.B. ‘Sind die folgenden Aussagen zum gegebenen Modell richtig oder falsch?’",
      answerTooltip:
        "Antwortoptionen sind Aussagen zum gegebenen Modell, die entweder wahr oder falsch sind. Versuchen Sie, keine Antwortoptionen festzulegen, die unter gegebenen Umständen sowohl wahr als auch falsch sein können. Ebenso sollten Sie keine zwei Antwortoptionen festlegen, die sich gegenseitig ausschließen, da Studierende daraus bereits schließen können, dass jeweils eine dieser Antwortoptionen wahr und die andere falsch ist. ",
      descriptionTooltip:
        "Legen Sie eine Beschreibung für die Aufgabenreihe fest. Es empfiehlt sich, Lernziele und Kontextinformationen gegenüber Studierenden transparent zu kommunizieren.",
      datesTooltip:
        "Studierende können eine Aufgabenreihe nur in der festgelegten Zeitspanne zwischen Start- und Enddatum bearbeiten. ",
      qacTooltip:
        "Verteilen Sie diesen Zugriffscode an Ihre Studierenden, damit diese die Aufgabenreihe abonnieren können. ",
      assessAllSubmissions: "Bewertung für alle Einreichungen starten",
      assessmentcardtooltip:
        "In dieser Komponente wurde nach Aufgaben gruppiert. Innerhalb der Aufgaben befinden sich die jeweiligen Einreichungen der Studenten",
      studentcardtooltip:
        "In dieser Komponente wurde nach Studenten gruppiert. Innerhalb eines Studenten befinden sich die jeweiligen Einreichungen zu den Aufgaben",
      Revised: "Veröffentlicht",
      loggedInAs: "Eingeloggt als",

      noAnswer: "Keine Antwort",
      noeditwarning1: "Falls Sie Multiple Choice Antworten bearbeiten möchten, verwenden Sie bitte die ",
      noeditwarning2: "Bearbeitungsseite der Aufgabe",
      noeditwarning3: "und stoßen Sie bitte eine neue Bewertung an.",
      currentScheme: "Das derzeit aktive Bewertungsschema ist: ",
      creation: "Modell erstellen",
      percentage: "Anteil",
      Score: "Gesamtpunkte",
      requested: "Angelegt",
      RELATIVE: "relativ",
      ABSOLUTE: "absolut",
      editFeedback: "Feedback anpassen",
      weight: "Gewichtung",
      result: "Ergebnis",
      resulttype: "Ergebnistyp",
      partialScore: "Teilpunkte",
      finished: "Fertiggestellt",
      understanding: "Modell verstehen",
      totalAssignment: "Aufgabenreihe gesamt",
      ANY: "Beliebig",
      BPMN: "BPMN",
      EPC: "EPK",
      PN: "Petri Netz",
      UML_CD: "UML Klassendiagramm",
      UML_CLASS: "UML Klassendiagramm",
      UML_SD: "UML Zustandsdiagramm",
      UML_STATE: "UML Zustandsdiagramm",
      UML_SEQ: "UML Sequenzdiagramm",
      UML_SEQUENCE: "UML Sequenzdiagramm",
      UML_AD: "UML Aktivitätsdiagramm",
      UML_ACTIVITY: "UML Aktivitätsdiagramm",
      ER: "ER-Diagramm",
      gradingService: "Bewertungsdienst",
      gradingServices: "Bewertungsdienste",
      PositionX: "Position X",
      PositionY: "Position Y",
      inputModel: "Gegebenes Modell",
      Assessment: "Bewertung",
      FeedbackForExercise: "Feedback zur Aufgabe",
      newInputModel: "Gegebenes Modell erstellen",
      newSampleSolution: "Musterlösung erstellen",
      headerTitleTeacher: "Author UI",
      headerTitleStudent: "Student UI",
      assignment: "Aufgabenreihe",
      assignments: "Aufgabenreihen",
      newAssignment: "Aufgabenreihe erstellen",
      back: "Zurück",
      ManualAssessment: "Manuelle Revision",
      assignmentList: "Aufgabenreihen",
      exercise: "Aufgabe",
      exercises: "Aufgaben",
      newExercise: "Aufgabe erstellen",
      newScheme: "Schema erstellen",
      schemelist: "Schemata",
      modellist: "Modelle",
      valuation: "Beurteilung",
      save: "Speichern",
      noComment: "Kein Kommentar",
      exerciselist: "Aufgaben",
      search: "Suchen",
      deleteAll: "Alle löschen",
      title: "Titel",
      description: "Beschreibung",
      status: "Status",
      edit: "Bearbeiten",
      chooseTask: "Aufgabe auswählen",
      chooseModel: "Modell auswählen",
      chooseScheme: "Schema auswählen",
      signIn: "Anmelden",
      published: "Veröffentlicht",
      pending: "In Bearbeitung",
      unpublish: "Veröffentlichung zurückziehen",
      delete: "Löschen",
      update: "Aktualisieren",
      updated: "Aktualisiert",
      chooseAssignment: "Bitte eine Aufgabe auswählen",
      submitSuccessfull: "Erfolgreich erstellt!",
      send: "Senden",
      enterTitle: "Titel eingeben",
      artifacts: "Artefakte",
      create: "Erstellen",
      created: "Erstellungsdatum",
      add: "Hinzufügen",
      name: "Name",
      type: "Typ",
      modellinglang: "Modellierungssprache",
      modellingTool: "Modellierungseditor",
      language: "Sprache",
      scenario: "Szenario",
      modelCreation: "Modell erstellen",
      modelUnderstanding: "Modell verstehen",
      chooseModelType: "Wählen Sie für die neu zu erstellende Aufgabe einen der folgenden Aufgabentypen aus:",
      any: "Beliebig",
      bpmn: "BPMN",
      epc: "EPK",
      petrinet: "Petri Netz",
      umlClass: "UML Klassendiagramm",
      umlSequence: "UML Sequenzdiagramm",
      sampleSolution: "Musterlösung(en)",
      newModel: "Musterlösung erstellen",
      createModelButton: "Erstellen",
      model: "Modell",
      models: "Modelle",
      modelId: "Modell ID",
      chooseFile: "Datei auswählen",
      answer: "Antwortoptionen",
      wronganswers: "Falsche Antwortoptionen",
      correctanswers: "Korrekte Antwortoptionen",
      schemes: "Schemata",
      scheme: "Bewertungsschema",
      visibility: "Sichtbarkeit",
      private: "privat",
      internal: "intern",
      public: "öffentlich",
      question: "Frage/Aufgabenstellung",
      signUp: "Registrieren",
      logOut: "Abmelden",
      existing_model: "Bestehendes Modell",
      assessment: "Bewertung",
      startAssessment: "Bewertung starten",
      showAssessment: "Ergebnisse",
      selectSubmission: "Einreichung auswählen",
      refresh: "aktualisieren",
      selectModellinglang: "Modellierungssprache auswählen",
      selectScheme: "Bewertungsschema auswählen",
      selectSampleSolution: "Musterlösung auswählen",
      selectVisibility: "Sichtbarkeit auswählen",
      addAnswer: "Antwortoption hinzufügen",
      answers: "Antwortoptionen",
      correct: "Richtig",
      pn: "Petri Netz",
      uml_cd: "UML Klassendiagramm",
      uml_sd: "UML Zustandsdiagramm",
      uml_seq: "UML Sequenzdiagramm",
      ModelsEmpty: "Keine Models ausgewählt",
      startDate: "Startdatum",
      endDate: "Enddatum",
      createExercise: "Aufgabe erstellen",
      addModel: "Modell hinzufügen",
      deleteModel: "Modell löschen",
      editModel: "Modell bearbeiten",
      createFeedback: "Feedback erstellen",
      noFeedbacks: "Kein Feedback vorhanden",
      quickAccessCode: "Zugriffscode",
      options: "Optionen",
      noExercises: "Es wurden noch keine Aufgaben erstellt!",
      saveAssignment: "Aufgabenreihe speichern",
      saveExercise: "Aufgabe speichern",
      noSubmissions: "Es sind noch keine Einreichungen vorhanden!",
      noAssessment: "Es ist noch keine Bewertung verfügbar!",
      noAssignment: "Es wurden noch keine Aufgabenreihen erstellt!",
      submissions: "Einreichungen",
      cancel: "Abbrechen",
      allSubmissionsForExercise: "Hier sind alle Einreichungen für diesen Studenten aufgelistet: ",
      allSubmissionsForStudent: "Hier sind alle Einreichungen für den ausgewählten Studenten aufgelistet.",
      allExercisesInAssignment: "Hier sind alle in der Aufgabenreihe enthaltenen Aufgaben aufgelistet. ",
      allAssignments:
        "Hier können Aufgabenreihen erstellt und bearbeitet werden. Außerdem kann auf 'Bewertung' geklickt werden, um alle enthaltenen Aufgaben der jeweiligen Aufgabenreihe mit den dazugehörigen Einreichungen ansehen zu können.",
      comment: "Kommentar",
      totalScore: "Gesamtpunktzahl",
      maxTotalScore: "Maximale Gesamtpunktzahl",
      content: "Inhalt",
      category: "Kategorie",
      noModel: "Es wurde noch keine Musterlösung erstellt!",
      autoAssessmentAss: "Automatisierte Bewertung bei Einreichung",
      autoAssessmentEx: "Automatisierte Bewertung bei der Bearbeitung",
      autoAssessmentExDesc:
        "Ist diese Option aktiviert, werden Lösungen zu dieser Aufgabe automatisiert bewertet (d.h. Feedback wird generiert) - jedes Mal wenn ein/e Student/in seine/ihre Lösung speichert. Bei diesem sogenannten multiple-try-Feedback können Studierende ihre Lösung im Anschluss immer wieder überarbeiten und sich sukzessive einer guten Lösung nähern.",
      autoAssessmentAssDesc:
        "Ist diese Option aktiviert, werden alle Lösungen zu allen Aufgaben in dieser Aufgabenreihe automatisiert bewertet (d.h. Feedback wird generiert) sobald ein/e Student/in seine/ihre Aufgabenreihe einreicht. Wenn Sie das generierte Feedback jedoch zuvor kontrollieren möchten, dürfen Sie diese Option nicht aktivieren. Sie haben dann unter 'Bewertung' die Möglichkeit, für alle Einreichungen die automatisierte Bewertung manuell anzustoßen und die Ergebnisse gegebenenfalls vor der Veröffentlichung zu korrigieren.",
      noAnswers: "Es wurden noch keine Antwortoptionen erstellt!",
      noSolution: "Es wurde noch keine Lösung erstellt!",
      exerciseModelCreation: "Aufgabentyp »Modell Erstellen«",
      exerciseModelUnderstanding: "Aufgabentyp »Modell Verstehen«",
      true: "Richtig",
      false: "Falsch",
      integratedFeedback: "Integriertes Feedback",
      ShowModel: "Modell anzeigen",
      preview: "Vorschau",
      MaxScore: "Maximale Gesamtpunktzahl",
      SwitchRoleStudent: "Zur Studierendenansicht",
      solution: "Lösung",
      proceed: "Ja, fortfahren",
      deleteConfirmationAssignment: "Möchten Sie die folgende Aufgabenreihe wirklich löschen?",
      deleteConfirmationAssignmentIrreversible:
        "Dieser Schritt lässt sich nicht rückgängig machen. Alle zur Aufgabenreihe gehörenden Aufgaben, Einreichungen und Bewertungen werden ebenfalls gelöscht.",
      confirmationRequest: "Sicherheitsabfrage",
      deleteConfirmationExercise: "Möchten Sie die folgende Aufgabe wirklich löschen?",
      deleteConfirmationExerciseIrreversible:
        "Dieser Schritt lässt sich nicht rückgängig machen. Alle zur Aufgabe gehörenden Einreichungen und Bewertungen werden ebenfalls gelöscht.",
      numberOfSubmissions: "Anzahl der Einreichungen",
      numberOfPublished: "Anzahl der veröffentlichten Einreichungen",
      toolTipMC:
        "Bei 'Modell erstellen'-Aufgaben muss zu einem gegebenen Szenario ein passendes Modell erstellt werden. ",
      toolTipMU:
        "Bei 'Modell verstehen'-Aufgaben müssen passende Aussagen zu einem gegebenen Modell ausgewählt werden. ",
      students: "Studenten",
      studentSubmissionsForAssignment:
        "Hier sind alle Studenten aufgelistet, die bereits Aufgaben der Aufgabenreihe eingereicht haben.",
      exerciseType: "Aufgabentyp",
    },
  },
};

i18n
  .use(LngDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    detection: ["en-US", "de"],

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
