// @flow

import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAssignmentContext } from "../context/AssignmentContext";
import { useExerciseContext } from "../context/ExerciseContext";
import { useTranslation } from "react-i18next";
export const OrientationBar = () => {
  const { t } = useTranslation();

  // TODO: Is often undefined
  //console.log("submissionId",submissionId)
  const assignmentContext = useAssignmentContext();
  const exerciseContext = useExerciseContext();
  const [activeAssignment, setActiveAssignment] = useState({}) as any;
  const [activeExercise, setActiveExercises] = useState({}) as any;
  const [activeAssessment, setActiveAssessment] = useState({}) as any;
  const [activeSubmission, setActiveSubmission] = useState({}) as any;

  let id = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
  const writeToLocalSTorage = (item_name: string, item: any) => {
    localStorage.setItem(item_name, item);
  };
  if (window.location.href.includes("/assessment/")) {
    var assessmentId = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
    writeToLocalSTorage("assessmentId", assessmentId);
  }
  if (window.location.href.includes("submissions/exercise")) {
    var submissionId = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
    writeToLocalSTorage("submissionId", submissionId);
  }

  useEffect(() => {
    const getActiveAssessment = () => {
      if (window.location.href.indexOf("assessment") === -1) return;
      const activeAssessment = assignmentContext.assignments.find((assignment: any) => assignment.id === parseInt(id));

      return activeAssessment;
    };
    const assessment = getActiveAssessment();
    if (assessment === undefined) return;
    setActiveAssessment(assessment);
    writeToLocalSTorage("assessmentName", activeAssessment.name);
  }, [activeAssessment.name, assignmentContext.assignments, id]);

  useEffect(() => {
    const getActiveAssignment = () => {
      if (window.location.href.indexOf("assignment") === -1) return;
      const activeAssignment = assignmentContext.assignments.find((assignment: any) => assignment.id === parseInt(id));

      return activeAssignment;
    };
    const assignment = getActiveAssignment();
    if (assignment === undefined) return;
    setActiveAssignment(assignment);
    writeToLocalSTorage("assignmentName", activeAssignment.name);
  }, [activeAssignment.name, assignmentContext.assignments, id]);

  useEffect(() => {
    const getActiveExercises = () => {
      if (window.location.href.indexOf("exercise") === -1) return;
      const activeExercise = exerciseContext.exercises.find((exercise: any) => exercise.id === parseInt(id));
      return activeExercise;
    };
    const foundExercises = getActiveExercises();
    if (foundExercises === undefined) return;

    setActiveExercises(foundExercises);
  }, [exerciseContext.exercises, id]);

  useEffect(() => {
    const getActiveSubmissions = () => {
      if (window.location.href.indexOf("submissions/exercise") === -1) return;
      const activeSubmissions = exerciseContext.exercises.find((exercise: any) => exercise.id === parseInt(id));
      return activeSubmissions;
    };
    const foundSubmission = getActiveSubmissions();
    if (foundSubmission === undefined) return;

    setActiveSubmission(foundSubmission);
    writeToLocalSTorage("exerciseName", activeSubmission.name);
  }, [exerciseContext.exercises, id, activeSubmission.name]);

  return (
    <div>
      <nav
        className="navbar is-white fixed-top navbar-expand-lg"
        style={{ margin: "0.5% 0%" }}
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand text-dark">
          {window.location.href.indexOf("/modelling-tool") !== -1 ? (
            <p className="navbar-item has-text-weight-bold">{t("modellingTool")}</p>
          ) : (
            <Link to={"/assignments"} className=" navbar-item has-text-weight-bold">
              {t("assignments")}
            </Link>
          )}

          {id === "add-assignment" ? (
            <p className="navbar-item has-text-weight-bold">
              {" »   "}
              {t("newAssignment")}
            </p>
          ) : (
            ""
          )}
          {window.location.href.indexOf("/assignments/") !== -1 ? (
            <>
              <span className="navbar-item has-text-weight-bold"> {" » "}</span>
              <Link to={`/assignments/${activeAssignment.id}`} className="navbar-item has-text-weight-bold">
                {`${activeAssignment.name}  `}
              </Link>
            </>
          ) : (
            ""
          )}

          {window.location.href.indexOf("/exercises/") !== -1 ? (
            <>
              <span className="navbar-item has-text-weight-bold"> {" » "}</span>
              <Link to={`/assignments/${activeExercise.assignmentId}`} className="navbar-item has-text-weight-bold">
                {`${localStorage.getItem("assignmentName")}   `}
              </Link>
              <span className="navbar-item has-text-weight-bold"> {" » "}</span>
              <Link to={`/exercises/${id}`} className="navbar-item has-text-weight-bold">
                {`${activeExercise.name}`}
              </Link>
            </>
          ) : (
            ""
          )}

          {window.location.href.indexOf("/assessment/") !== -1 ? (
            <>
              {" "}
              <span className="navbar-item has-text-weight-bold"> {" » "}</span>
              <Link to={`/assessment/${localStorage.assessmentId}`} className="navbar-item has-text-weight-bold">
                {" "}
                {activeAssessment.name !== undefined ? `${activeAssessment.name}` : " "}
              </Link>
            </>
          ) : (
            " "
          )}
          {window.location.href.indexOf("submissions/exercise") !== -1 ? (
            <>
              <span className="navbar-item has-text-weight-bold"> {" » "}</span>
              <Link to={`/assessment/${localStorage.assessmentId}`} className="navbar-item has-text-weight-bold">
                {`${localStorage.getItem("assessmentName")}`}
              </Link>
              <span className="navbar-item has-text-weight-bold"> {" » "}</span>
              <Link
                to={`/submissions/exercise/${localStorage.submissionId}`}
                className="navbar-item has-text-weight-bold"
              >
                {activeSubmission.name !== undefined ? `${activeSubmission.name}` : " "}
              </Link>
            </>
          ) : (
            ""
          )}

          {window.location.href.indexOf("submissions/student") !== -1 ? (
            <>
              <span className="navbar-item has-text-weight-bold"> {" » "}</span>
              <Link to={`/assessment/${localStorage.assessmentId}`} className="navbar-item has-text-weight-bold">
                {`${localStorage.getItem("assessmentName")}`}
              </Link>
              <span className="navbar-item has-text-weight-bold"> {" » "}</span>
              <Link
                to={`/submissions/student/${localStorage.assessmentId}/${localStorage.currentUserId}`}
                className="navbar-item has-text-weight-bold"
              >
                {`${localStorage.getItem("currentUser")}`}
              </Link>
            </>
          ) : (
            ""
          )}

          {/* nochmal checken ; /submissions/exercise/${localStorage.submissionId*/}

          {window.location.href.indexOf("assessments-") !== -1 ||
          window.location.href.indexOf("manual-assessment") !== -1 ||
          window.location.href.indexOf("/subpreview/") !== -1 ? (
            localStorage.getItem("originStudentTable") ? (
              <>
                <span className="navbar-item has-text-weight-bold">{" » "}</span>
                <Link to={`/assessment/${localStorage.assessmentId}`} className="navbar-item has-text-weight-bold">
                  {`${localStorage.getItem("assessmentName")}  `}
                </Link>
                <span className="navbar-item has-text-weight-bold">{" » "}</span>
                <Link
                  to={`/submissions/student/${localStorage.assessmentId}/${localStorage.currentUserId}`}
                  className="navbar-item has-text-weight-bold"
                >
                  {`${localStorage.getItem("currentUser")}`}
                </Link>
                <span className="navbar-item has-text-weight-bold">{" » "}</span>
                <p className="navbar-item has-text-weight-bold">
                  {`${localStorage.getItem("exerciseNameForStudent")} `}
                </p>
              </>
            ) : (
              <>
                <span className="navbar-item has-text-weight-bold">{" » "}</span>
                <Link to={`/assessment/${localStorage.assessmentId}`} className="navbar-item has-text-weight-bold">
                  {`${localStorage.getItem("assessmentName")}`}
                </Link>
                <span className="navbar-item has-text-weight-bold">{" » "}</span>
                <Link
                  to={`/submissions/exercise/${localStorage.submissionId}`}
                  className="navbar-item has-text-weight-bold"
                >
                  {`${localStorage.getItem("exerciseName")} `}
                </Link>
                <span className="navbar-item has-text-weight-bold">{" » "}</span>
                <p className="navbar-item has-text-weight-bold">{`${localStorage.getItem("manual_revision_user")}`}</p>
              </>
            )
          ) : (
            ""
          )}

          {window.location.href.indexOf("add-exercise") !== -1 ? (
            <>
              <span className="navbar-item has-text-weight-bold"> {" » "}</span>
              <Link to={`/assignments/${id}`} className="navbar-item has-text-weight-bold">
                {`${localStorage.assignmentName}`}
              </Link>
              <span className="navbar-item has-text-weight-bold"> {" » "}</span>
              <p className="navbar-item has-text-weight-bold">{t("newExercise")}</p>
            </>
          ) : (
            ""
          )}
          {window.location.href.indexOf("/impressum") !== -1 ? (
            <>
              <span className="navbar-item has-text-weight-bold"> {" » "}</span>
              <Link to={`/impressum`} className="navbar-item has-text-weight-bold">
                {t("Impressum")}
              </Link>
            </>
          ) : (
            ""
          )}
        </div>
      </nav>
    </div>
  );
};
