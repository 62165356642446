import { useState } from "react";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import Tooltip from "@kea-mod/common/tooltip";
import { AddExerciseCreationComponent } from "./ModelCreation/AddExerciseCreationComponent";
import { AddExerciseUnderstandingComponent } from "./ModelUnderstanding/AddExerciseUnderstandingComponent";

export const AddExerciseContainer = () => {
  let { id } = useParams();
  const { t } = useTranslation();
  const [choice, setChoice] = useState(-1);

  const exerciseChoice = () => {
    if (choice === -1) {
      return (
        <div className="box has-text-centered">
          <div>{t("chooseModelType")}</div>
          {/* button linked to component exercise-creation */}
          <button className="button mt-5 is-primary" onClick={() => setChoice(1)}>
            {t("modelCreation")}
          </button>
          <Tooltip
            icon="circle-info"
            class="icon tooltip mt-5 no-border is-white has-tooltip-multiline has-tooltip-bottom"
            content={t("toolTipMC")}
          ></Tooltip>
          {/* button linked to component exercise-understanding */}
          <button className="button mt-5 is-primary" onClick={() => setChoice(2)}>
            {t("modelUnderstanding")}
          </button>
          <Tooltip
            icon="circle-info"
            class="icon tooltip no-border is-white has-tooltip-multiline has-tooltip-bottom"
            content={t("toolTipMU")}
          ></Tooltip>
        </div>
      );
    } else if (choice === 1) {
      return (
        <div className="column is-three-quarters">
          <div className="box">
            <h4 className="title is-4">{t("exerciseModelCreation")}</h4>

            <AddExerciseCreationComponent assignmentId={id} />
          </div>
        </div>
      );
    } else if (choice === 2) {
      return (
        <div className="column is-three-quarters">
          <div className="box">
            <h4 className="title is-4">{t("exerciseModelUnderstanding")}</h4>
            <AddExerciseUnderstandingComponent assignmentId={id} />
          </div>
        </div>
      );
    }
    return <></>;
  };

  return (
    <div className="container mt-6">
      <div className="section">
        <div className="columns is-centered">{exerciseChoice()}</div>
      </div>
    </div>
  );
};
