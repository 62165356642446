// @flow
import { useState } from "react";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope, faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
//import { Link, useHistory } from "react-router-dom";
import logo from "../shared/assets/logo.png";
import Tooltip from "@kea-mod/common/tooltip";
//import { OrientationBar } from "./OrientationBar";
//import Tooltip from "../shared/components/tooltip";
import authService from "../axios/services/auth.service";
import sessionService from "../axios/services/session.service";
import { OrientationBar } from "./OrientationBar";
//import { useHistory } from "react-router-dom";
library.add(faEnvelope, faInfo);

export const Navbar = (): any => {
  document.body.classList.add("has-navbar-fixed-top");
  const [isActive, setIsActive] = useState<boolean>(false);
  const { t, i18n } = useTranslation();
  const location = useLocation();
  //const history = useHistory();

  const toggleSetIsActive = () => {
    setIsActive(!isActive);
  };

  const goToStudentUI = () => {
    window.location.href = (window as any)._env_.REACT_APP_STUDENT_UI_URL + "/assignments";
  };

  const logOut = () => {
    toggleSetIsActive();
    authService.signOut();
  };
  const locationURIStringArray = location.pathname.split("/");

  return (
    <>
      <nav className="navbar is-fixed-top is-white" role="navigation" aria-label="main navigation">
        <div className="collapse navbar-collapse flex-column ml-lg-0 ml-3" id="navbarCollapse">
          <ul className="navbar-nav">
            <div className={classNames("navbar-menu", { "is-active": isActive })}>
              <div className="navbar-brand">
                <Link onClick={toggleSetIsActive} to={"/assignments"} className="navbar-item">
                  <img src={logo} alt="KEA-Mod" />
                  <span className="has-text-weight-light">{t("headerTitleTeacher")}</span>
                </Link>
              </div>
              <div className="navbar-start">
                <Link
                  onClick={toggleSetIsActive}
                  to={"/assignments"}
                  className={classNames("navbar-item", {
                    "has-text-weight-bold": locationURIStringArray[1] === "assignments",
                  })}
                >
                  {t("assignments")}
                </Link>

                <Link
                  onClick={toggleSetIsActive}
                  to={"/modelling-tool"}
                  className={classNames("navbar-item", {
                    "has-text-weight-bold": locationURIStringArray[1] === "modelling-tool",
                  })}
                >
                  {t("modellingTool")}
                </Link>
              </div>
            </div>
          </ul>
          <ul className="navbar-nav flex-row mb-2">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <OrientationBar />
              </ol>
            </nav>
          </ul>
        </div>

        <div className="navbar-end">
          <div className="collapse navbar-collapse flex-column ml-lg-0 ml-3" id="navbarCollapse">
            <ul className="navbar-end">
              <div className="navbar-item">
                <div className="buttons">
                  <button
                    onClick={() => {
                      toggleSetIsActive();
                      i18n.changeLanguage("de-DE");
                    }}
                    className={classNames("button is-white mx-1", {
                      "has-text-weight-semibold": i18n.language === "de-DE",
                      "has-text-weight-light": i18n.language !== "de-DE",
                    })}
                  >
                    DE
                  </button>
                  <button
                    onClick={() => {
                      toggleSetIsActive();
                      i18n.changeLanguage("en-US");
                    }}
                    className={classNames("button is-white mx-1", {
                      "has-text-weight-semibold": i18n.language === "en-US",
                      "has-text-weight-light": i18n.language !== "en-US",
                    })}
                  >
                    EN
                  </button>
                  <Tooltip
                    icon="user"
                    class="button is-white has-tooltip-multiline   has-tooltip-left"
                    content={t("loggedInAs") + " " + sessionService.getName()}
                  ></Tooltip>
                </div>
              </div>
              <div className="ml-0 navbar-item">
                <a href="mailto:info@keamod.de">
                  <FontAwesomeIcon icon="envelope" />
                </a>
              </div>
              <div className="navbar-item">
                <a href="https://docs.keamod.de" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon="info" />
                </a>
              </div>
              {/*<div className="navbar-item" onClick={() => goToImpressum()}>
                <a href="/#" onClick={(e) => e.preventDefault()}>
                  <FontAwesomeIcon icon="info" />
                </a>
              </div>*/}

              <div className="navbar-item">
                <div className="button is-info" onClick={goToStudentUI}>
                  {t("SwitchRoleStudent")}
                </div>
              </div>
              <div className="navbar-item">
                <div className="button is-danger" onClick={logOut}>
                  {t("logOut")}
                </div>
              </div>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};
