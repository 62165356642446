import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCircleInfo, faUser, faCheckSquare, faSquare, faSpinner } from "@fortawesome/free-solid-svg-icons";
library.add(faCircleInfo, faUser, faCheckSquare, faSquare, faSpinner);

const Tooltip = (props: any) => {
  return (
    <button className={props.class} data-tooltip={props.content}>
      <FontAwesomeIcon icon={props.icon} />
    </button>
  );
};
export default Tooltip;
