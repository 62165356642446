import { useState } from "react";
import { useTranslation } from "react-i18next";
import ConfirmDialog from "./ConfirmationModalCard";

function ConfirmButton(props: any) {
  const { t } = useTranslation();

  const [popup, setPopup] = useState({
    show: false, // initial values set to false and null
    id: null,
    name: "",
  });

  const handleAction = (id: any, name: any) => {
    setPopup({
      show: true,
      id,
      name,
    });
  };

  const SetOpen = () => {
    if (popup.show && popup.id) {
      props.proceedAction(popup.id);
      setPopup({
        show: false,
        id: null,
        name: "",
      });
    }
  };

  const SetClose = () => {
    setPopup({
      show: false,
      id: null,
      name: "",
    });
  };

  return (
    <>
      {popup.show && (
        <ConfirmDialog
          SetOpen={SetOpen}
          SetClose={SetClose}
          name={popup.name}
          question={props.question}
          explanation={props.explanation}
          buttonCancelText="cancel"
          buttonProceedText="proceed"
        />
      )}
      <button
        className={"button " + props.color}
        onClick={() => {
          handleAction(props.id, props.name);
        }}
      >
        {t(props.buttonLabel)}
      </button>
    </>
  );
}

export default ConfirmButton;
