// @flow
import { useEffect, useState } from "react";

import type { Assignment } from "../../types/Assignment";
import { EditAssignmentComponent } from "./EditAssignmentComponent";
import { useAssignmentContext } from "../../context/AssignmentContext";
import { useExerciseContext } from "../../context/ExerciseContext";
import { useNavigate } from "react-router-dom";

type Props = {
  id: any;
};

export const EditAssignmentContainer = (props: Props) => {
  const navigate = useNavigate();
  const assignmentContext = useAssignmentContext();
  const exerciseContext = useExerciseContext();
  const [activeAssignment, setActiveAssignment] = useState<Assignment>({
    description: "",
    endDate: "",
    exercises: [],
    id: -1,
    name: "",
    startDate: "",
    quickAccess: -1,
    updatedAt: "",
    autoAssessment: false,
  });

  useEffect(() => {
    assignmentContext.updateAssignments();
    exerciseContext.all();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [activeExercises, setActiveExercises] = useState([]);

  useEffect(() => {
    const getActiveAssignment = () => {
      if (props.id === undefined) return;
      const activeAssignment = assignmentContext.assignments.find(
        (assignment: any) => assignment.id === parseInt(props.id),
      );
      return activeAssignment;
    };

    const assignment = getActiveAssignment();
    if (assignment === undefined) return;

    setActiveAssignment(assignment);
  }, [assignmentContext.assignments, props.id]);

  useEffect(() => {
    const getActiveExercises = () => {
      if (exerciseContext.exercises === undefined) return;
      const allExercises = exerciseContext.exercises.filter(
        (exercise: any) => exercise.assignmentId === parseInt(props.id),
      );
      return allExercises;
    };

    const foundExercises = getActiveExercises() as any;
    if (foundExercises === undefined) return;
    setActiveExercises(foundExercises);
  }, [exerciseContext.exercises, props.id]);

  const deleteAssignment = () => {
    assignmentContext.deleteAssignment(props.id);
    navigate("/assignments/");
  };

  return (
    <div>
      <EditAssignmentComponent
        assignmentId={activeAssignment.id}
        activeAssignment={activeAssignment}
        activeExercises={activeExercises}
        deleteAssignment={deleteAssignment}
        updateAssignment={assignmentContext.updateAssignment}
      />
    </div>
  );
};
