import { useEffect, useState } from "react";

import { AnswerField } from "../ExerciseFields/AnswerField";
import { AnswerList } from "./AnswerList";
import { DescriptionField } from "../ExerciseFields/DescriptionField";
import { LanguageField } from "../ExerciseFields/LanguageField";

import { NameField } from "../ExerciseFields/NameField";
import { QuestionField } from "../ExerciseFields/QuestionField";
import { SchemeField } from "../ExerciseFields/SchemeField";
import { FeedUpField } from "../ExerciseFields/FeedUpField";
import { FeedForwardPosField } from "../ExerciseFields/FeedForwardPosField";
import { FeedForwardNegField } from "../ExerciseFields/FeedForwardNegField";
import { FeedForwardThresholdField } from "../ExerciseFields/FeedForwardThresholdField";
import { b64EncodeUnicode } from "../../../shared/b64";
import { emptyExercise } from "../../../types/Exercise";
import { useExerciseContext } from "../../../context/ExerciseContext";
import { useNavigate } from "react-router-dom";
import { useModelContext } from "../../../context/ModelContext";
import { useTranslation } from "react-i18next";
import Tooltip from "@kea-mod/common/tooltip";
import { ModelList } from "../../../pages/Modellist";

type Props = {
  assignmentId: any;
};

export const AddExerciseUnderstandingComponent = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const exerciseContext = useExerciseContext();
  const modelContext = useModelContext();

  const [activeExercise, setActiveExercise] = useState(emptyExercise);
  const [exerciseId, setExerciseId] = useState(-1);
  const [exerciseCreated, setExerciseCreated] = useState(false);
  const [language, setLanguage] = useState("");
  const [answer, setAnswer] = useState("");
  const [isCorrect, setIsCorrect] = useState(false);
  const [maxScore, setMaxScore] = useState("0");

  useEffect(() => {
    exerciseContext.all();
    setDefaultAssessmentScheme();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDefaultAssessmentScheme = () => {
    setActiveExercise({
      ...activeExercise,
      schemaId: "27", //id of Multiple Choice Assessment Scheme
    });
  };

  const handleChange = (event: any) => {
    let value = event.target.value;

    setActiveExercise({
      ...activeExercise,
      [event.target.name]: value,
    });
  };

  const handleChangeQuestion = (event: any) => {
    let value = event.target.value;

    setActiveExercise({
      ...activeExercise,
      understanding: { ...activeExercise.understanding, question: value },
    });
  };

  const handleCheckboxChange = (event: any) => {
    setActiveExercise({
      ...activeExercise,
      [event.target.name]: event.target.checked,
    });
  };

  const handleChangeLanguage = (event: any) => {
    setLanguage(event.target.value);
  };

  const handleChangeDescription = (event: any) => {
    setActiveExercise({
      ...activeExercise,
      description: event,
    });
  };

  const handleChangeAnswer = (event: any) => {
    setAnswer(event.target.value);
  };

  const handleCheckAnswer = () => {
    setIsCorrect(!isCorrect);
  };

  const emptyCheck = () => {
    if (
      activeExercise.name.trim() === "" ||
      activeExercise.description.trim() === "" ||
      language === "" ||
      activeExercise.understanding.question.trim() === "" ||
      activeExercise.schemaId === ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  const addFeedFields = (data: any) => {
    //only add fields to data if not empty
    if (activeExercise.feedUp !== "") {
      data.feedUp = activeExercise.feedUp;
    }

    if (activeExercise.feedForward_pos !== "") {
      data.feedForward_pos = activeExercise.feedForward_pos;
    }

    if (activeExercise.feedForward_neg !== "") {
      data.feedForward_neg = activeExercise.feedForward_neg;
    }

    if (activeExercise.feedForward_threshold !== "") {
      data.feedForward_threshold = parseFloat(activeExercise.feedForward_threshold);
    }
  };

  const createExercise = async () => {
    if (emptyCheck()) {
      alert("Please make sure that all fields have been filled out!");
    } else {
      let data = {
        name: activeExercise.name,
        type: "understanding",
        description: b64EncodeUnicode(activeExercise.description),
        language: language,
        question: activeExercise.understanding.question,
        schemaId: parseInt(activeExercise.schemaId),
        assignmentId: parseInt(props.assignmentId),
        directFeedback: activeExercise.directFeedback,
        maxTotalScore: maxScore,
      };

      addFeedFields(data);

      let exerciseId = await exerciseContext.create(data);
      if (exerciseId === undefined) return;
      setExerciseId(exerciseId);
      setExerciseCreated(true);
      exerciseContext.updateAllAnswers(exerciseId);
    }
  };

  const goBackToAssignment = () => {
    navigate("/assignments/" + props.assignmentId);
  };

  const showExerciseButtons = () => {
    if (!exerciseCreated) {
      return (
        <div className="columns mt-4">
          <button className="button is-success" onClick={createExercise}>
            {t("createExercise")}
          </button>
          <button className="button is-danger" onClick={goBackToAssignment}>
            {t("cancel")}
          </button>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const showModelList = () => {
    const getActiveModels = () => {
      if (exerciseId === undefined) return;
      const models = modelContext.models.filter((model: any) => model.parentId === parseInt(exerciseId.toString()));
      return models;
    };

    const models = getActiveModels();
    if (models === undefined) return;

    return (
      <div className="field">
        <label className="label" htmlFor="title">
          {t("inputModel")}
          <Tooltip
            icon="circle-info"
            class="icon tooltip no-border is-white   has-tooltip-multiline has-tooltip-top"
            content={t("inputmodelTooltip")}
          ></Tooltip>
        </label>
        <ModelList
          modelList={models}
          modelingLanguage={language}
          exerciseId={exerciseId}
          exerciseType={"understanding"}
          modelType={"input"}
        />
      </div>
    );
  };

  let modelList = (
    <div>
      {/* show Model List only if Exercise was already created */}
      {exerciseCreated && showModelList()}
    </div>
  );

  const addAnswer = () => {
    let id = parseInt(exerciseId.toString());

    if (answer.trim() === "") {
      alert("Please make sure that the field answer is not empty!");
    } else {
      let data = {
        answer: [
          {
            text: answer,
            isCorrect: isCorrect,
          },
        ],
      };
      exerciseContext.createAnswers(id, data);
      setAnswer("");
      setIsCorrect(false);
    }
  };

  const showAnswerList = () => {
    return (
      <div>
        <AnswerField
          answer={answer}
          isCorrect={isCorrect}
          handleCheckAnswer={handleCheckAnswer}
          handleChangeAnswer={handleChangeAnswer}
          handleAddAnswer={addAnswer}
        />

        <AnswerList answers={exerciseContext.answers} exerciseId={exerciseId.toString()} />
      </div>
    );
  };

  let answerList = (
    <div>
      {/* show Answer List only if Exercise was already created */}
      {exerciseCreated && showAnswerList()}
    </div>
  );

  const showFinishButton = () => {
    if (exerciseCreated) {
      return (
        <div className="columns mt-4">
          <button className="button is-danger" onClick={goBackToAssignment}>
            {t("cancel")}
          </button>
          <button className="button is-normal is-success" onClick={updateExercise}>
            {t("saveExercise")}
          </button>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const updateExercise = () => {
    if (emptyCheck()) {
      alert("Please make sure that all fields have been filled out!");
    } else {
      let data = {
        name: activeExercise.name,
        type: "understanding",
        description: b64EncodeUnicode(activeExercise.description),
        language: language,
        understanding: { question: activeExercise.understanding.question },
        schemaId: parseInt(activeExercise.schemaId),
        assignmentId: parseInt(props.assignmentId),
        maxTotalScore: maxScore,
      };

      exerciseContext.update(exerciseId, data);
      navigate("/assignments/" + props.assignmentId);
    }
  };
  function onChangeMaxScore(e: any) {
    let val = e.target.value;
    const v = e.target.value;
    const myArray = e.target.value.split(".");

    if (myArray[0].toString().length >= 11) {
      return;
    }
    if (myArray.length !== 1 && myArray[1].toString().length >= 3) {
      return;
    }

    if (e.target.validity.valid) {
      val = val.replace(/^0+/, "");
      if (val === "") {
        val = v;
      }

      if (val.startsWith(".")) {
        val = "0" + val;
      }

      setMaxScore(val);
    } else if (val === "" || val === "-") {
      val = val.replace(/^0+/, "");
      if (val === "") {
        val = v;
      }

      if (val.startsWith(".")) {
        val = "0" + val;
      }
      setMaxScore(val);
    }
  }
  let ScoreField = (
    <div>
      <div>
        <input
          size={5}
          type="text"
          className="input"
          value={maxScore}
          onChange={onChangeMaxScore}
          pattern="^[-+]?\d*\.?\d*$"
        />
      </div>
    </div>
  );

  return (
    <div>
      <NameField activeExercise={activeExercise} h={handleChange} />
      <LanguageField language={language} h={handleChangeLanguage} />
      <DescriptionField activeExercise={activeExercise} h={handleChangeDescription} />
      <SchemeField activeExercise={activeExercise} h={handleChange} />
      <FeedUpField activeExercise={activeExercise} h={handleChange} />
      <FeedForwardPosField activeExercise={activeExercise} h={handleChange} />
      <FeedForwardNegField activeExercise={activeExercise} h={handleChange} />
      <FeedForwardThresholdField activeExercise={activeExercise} h={handleChange} />
      <div className="field">
        <div>
          <div className="label">
            {t("autoAssessmentEx")}
            <Tooltip
              icon="circle-info"
              class="icon tooltip no-border is-white   has-tooltip-multiline has-tooltip-top"
              content={t("autoAssessmentExDesc")}
            ></Tooltip>
          </div>
        </div>
        <input
          name="directFeedback"
          type="checkbox"
          checked={activeExercise.directFeedback}
          onChange={handleCheckboxChange}
        />
      </div>
      <div className="field">
        <div className="label">
          {t("MaxScore")}
          <Tooltip
            icon="circle-info"
            class="icon tooltip no-border is-white   has-tooltip-multiline has-tooltip-top"
            content={t("maxscoreTooltip")}
          ></Tooltip>
        </div>
        <div>{ScoreField}</div>
      </div>
      {modelList}
      <QuestionField activeExercise={activeExercise} h={handleChangeQuestion} />
      {answerList}
      {showExerciseButtons()}
      {showFinishButton()}
    </div>
  );
};
