// @flow

import { useEffect } from "react";

import { useExerciseContext } from "../../../context/ExerciseContext";
import { useTranslation } from "react-i18next";

type Props = {
  answers: any;
  exerciseId: string;
};

export const AnswerList = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const exerciseContext = useExerciseContext();

  useEffect(() => {
    exerciseContext.all();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteAnswer = (answerId: any) => {
    let exId = parseInt(props.exerciseId);
    exerciseContext.deleteAnswer(exId, answerId);
  };

  const getAnswerTableItem = (answer: any) => {
    return (
      <tr key={"Answer" + answer.id}>
        <th>{answer.text}</th>
        <th>{t(String(answer.isCorrect))}</th>
        {/* <td>
          <button
            className="button   is-primary"
            onClick={() => editAnswer(answer.id)}
          >
            {t("edit")}
          </button>
        </td> */}
        <td>
          <button className="button   is-danger" onClick={() => deleteAnswer(answer.id)}>
            {t("delete")}
          </button>
        </td>
      </tr>
    );
  };

  const getAnswerTable = () => {
    return (
      <div className="table-container">
        <table className="table is-striped is-narrow is-hoverable is-fullwidth">
          <thead className="is-italic">
            <tr>
              <td>{t("answer")}</td>
              <td>{t("solution")}</td>
              <td>{t("options")}</td>
              <td>{t("")}</td>
            </tr>
          </thead>
          <tbody>
            {props.answers.map((answer: any) => {
              return getAnswerTableItem(answer);
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const getEmptyMessage = () => {
    return <p className="is-size-7">{t("noAnswers")} </p>;
  };

  let renderAnswerTable = (
    <div className="card my-2">
      <div className="card-content">
        <div className="columns">
          <div className="column has-text-left">
            {props.answers.length > 0 && getAnswerTable()}
            {props.answers.length === 0 && getEmptyMessage()}
          </div>
        </div>
      </div>
    </div>
  );

  return <div>{renderAnswerTable}</div>;
};
