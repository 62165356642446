// @flow

export type Model = {
  id: number;
  name: string;
  graphData: Object;
  interactionData: Object;
  createdAt: string;
  updatedAt: string;
  userId: number;
  type: string;
  parentModel: string;
  parentId: number;
};

export const emptyModel: Model = {
  id: -1,
  name: "",
  graphData: {},
  interactionData: {},
  createdAt: "",
  updatedAt: "",
  userId: -1,
  type: "free",
  parentModel: "",
  parentId: -1,
};
