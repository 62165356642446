// @flow

import { useState, useEffect } from "react";

import SubmissionExerciseDataService from "../../axios/services/submission-exercises.service";

import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { b64DecodeUnicode } from "@kea-mod/common/components/b64";
import ModelDataService from "@kea-mod/common/axios/services/ModelDataService";

import { ModelCardComponentMU } from "@kea-mod/common/components/ModelCardComponentMU";
import MDEditor from "@uiw/react-md-editor";
import ExerciseDataService from "@kea-mod/common/axios/services/ExerciseDataService";
export const SubmissionPage = () => {
  let { id } = useParams();
  const { t } = useTranslation();

  const [submission_exercise, setSubmission_exercise] = useState({}) as any;

  const [modelId, setModelId] = useState(-1);
  const [description, setDescription] = useState("");
  const [scenario, setScenario] = useState("");

  const [submissionFlag, setSubmissionFlag] = useState(false);
  const [understandingFlag, setUnderstandingFlag] = useState(false);
  const [exerciseId, setExerciseId] = useState(-1);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  useEffect(() => {
    SubmissionExerciseDataService.getSubmissionExercise(id)
      .then((response: any) => {
        setSubmission_exercise(response.data.data);
        console.log(response.data.data);
        setDescription(b64DecodeUnicode(response.data.data.exercise.description) as string);
        setExerciseId(response.data.data.exerciseId);
        if (response.data.data.type === "creation") {
          setSubmissionFlag(true);
          setScenario(b64DecodeUnicode(response.data.data.exercise.creation.scenario) as string);
        } else {
          setUnderstandingFlag(true);
          //getModelUnderstanding();
        }
      })
      .catch((e: any) => {
        console.log(e);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!understandingFlag) {
      return;
    }
    ExerciseDataService.get(exerciseId, "authorui")
      .then((response: any) => {
        let res = response.data.data;
        setModelId(res.understanding.models.inputs[0]?.id);
      })
      .catch((e: any) => {
        console.log(e);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [understandingFlag]);

  useEffect(() => {
    if (!submissionFlag) {
      return;
    }
    ModelDataService.getModelforSubex(exerciseId, "studentSolution")
      .then((response: any) => {
        let models = response.data.data;
        for (let i = 0; i < models.length; i++) {
          let model = models[i];

          // eslint-disable-next-line eqeqeq
          if (model.parentId === parseInt(id as string)) {
            setModelId(model.id);

            break;
          }
        }
      })
      .catch((e: any) => {
        console.log(e);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submissionFlag]);

  return (
    <div className="card">
      <header className="card-header">
        <p className="card-header-title">
          {t("FeedbackForExercise")} »{submission_exercise.exercise ? submission_exercise.exercise.name : ""}«
        </p>
      </header>
      <div className="card-content">
        <div className="content" data-color-mode="light">
          <MDEditor.Markdown source={description} />
        </div>
        <div className="content" data-color-mode="light">
          <MDEditor.Markdown source={scenario} />
        </div>
        <div className="content">
          {modelId !== -1 && modelId !== undefined ? (
            <ModelCardComponentMU modelId={modelId.toString()} />
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </div>
  );
};
