import { debounce } from "debounce";
import { useEffect, useRef, useState } from "react";
import { useModelContext } from "./ModelContext";
import { ModelEditorModalContext } from "./ModelEditorModalContext";

function ModellingProvider(props: any) {
  const modelContext = useModelContext();
  const modalRef = useRef(null);
  const modalBodyRef = useRef(null);
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  });

  const [modelId, setModelId] = useState("-1");
  const [modelType, setModelType] = useState("-1");
  const [modelingLanguage, setModelingLanguage] = useState("");
  const [type, setType] = useState("-1");
  const [exerciseId, setExerciseId] = useState("-1");
  const [modalVisible, setModalVisible] = useState(false);
  const [exportEnabled, setExportEnabled] = useState(false);
  const [modalVisiblefeedback, setModalVisibleFeedback] = useState(false);
  const [modalVisiblepreview, setModalVisiblepreview] = useState(false);
  const [modalVisibleEdit, setModalVisibleEdit] = useState(false);
  const updateSize = debounce(() => {
    if (modalBodyRef.current) {
      setSize({
        width: (modalBodyRef.current as any).offsetWidth,
        height: (modalBodyRef.current as any).offsetHeight,
      });
    }
  }, 50);

  useEffect(() => {
    window.addEventListener("resize", updateSize);
    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, [updateSize]);

  const toggleModal = () => {
    console.log("toggle modal with the following fields..");
    console.log("modelType: ", modelType);
    setModalVisible(!modalVisible);
    if (modalVisible) {
      modelContext.update(exerciseId, type);
    }

    updateSize();
  };
  const toggleModalfeedback = () => {
    setModalVisibleFeedback(!modalVisiblefeedback);
    updateSize();
  };
  const toggleModalpreview = () => {
    setModalVisiblepreview(!modalVisiblepreview);
    updateSize();
  };
  const toggleModalEdit = () => {
    setModalVisibleEdit(!modalVisibleEdit);
    updateSize();
  };

  return (
    <ModelEditorModalContext.Provider
      value={{
        modalVisible: modalVisible,
        type: type,
        modelingLanguage: modelingLanguage,
        modelId: modelId,
        setModelId: setModelId,
        exerciseId: exerciseId,
        modelType: modelType,
        toggleModal: toggleModal,
        setType: setType,
        setExerciseId: setExerciseId,
        setModelType: setModelType,
        setModelingLanguage: setModelingLanguage,
        exportEnabled: exportEnabled,
        setExportEnabled: setExportEnabled,
        toggleModalpreview: toggleModalpreview,
        toggleModalfeedback: toggleModalfeedback,
        toggleModalEdit: toggleModalEdit,
      }}
    >
      {props.children}
      <div
        ref={modalRef}
        className={
          "fullscreenmodal modal" +
          (modalVisible || modalVisiblefeedback || modalVisiblepreview || modalVisibleEdit ? " is-active" : "")
        }
      >
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            {modalVisible ? <button className="delete" aria-label="close" onClick={toggleModal}></button> : <div></div>}
            {modalVisiblepreview ? (
              <button className="delete" aria-label="close" onClick={toggleModalpreview}></button>
            ) : (
              <div></div>
            )}
            {modalVisiblefeedback ? (
              <button className="delete" aria-label="close" onClick={toggleModalfeedback}></button>
            ) : (
              <div></div>
            )}
            {modalVisibleEdit ? (
              <button className="delete" aria-label="close" onClick={toggleModalEdit}></button>
            ) : (
              <div></div>
            )}
          </header>
          <section
            ref={modalBodyRef}
            className="modal-card-body m-0 p-0"
            style={{ width: "100%", padding: "0", overflowY: "hidden" }}
          >
            <iframe
              src={
                modalVisible
                  ? (window as any)._env_.REACT_APP_EDITOR_UI_URL +
                    ":" +
                    (window as any)._env_.REACT_APP_EDITOR_UI_PORT +
                    "/models/create" +
                    "?parentModel=exercise&type=" +
                    type +
                    "&parentId=" +
                    exerciseId +
                    "&modelingLanguage=" +
                    modelingLanguage
                  : modalVisiblepreview
                  ? (window as any)._env_.REACT_APP_EDITOR_UI_URL +
                    ":" +
                    (window as any)._env_.REACT_APP_EDITOR_UI_PORT +
                    "/models/preview" +
                    "?modelId=" +
                    modelId +
                    "&exportEnabled=" +
                    exportEnabled
                  : modalVisiblefeedback
                  ? (window as any)._env_.REACT_APP_EDITOR_UI_URL +
                    ":" +
                    (window as any)._env_.REACT_APP_EDITOR_UI_PORT +
                    "/feedback" +
                    "?modelId=" +
                    modelId +
                    "&submissionExerciseId=" +
                    exerciseId
                  : modalVisibleEdit
                  ? (window as any)._env_.REACT_APP_EDITOR_UI_URL +
                    ":" +
                    (window as any)._env_.REACT_APP_EDITOR_UI_PORT +
                    "/models/update" +
                    "?modelId=" +
                    modelId +
                    "&modelingLanguage=" +
                    modelingLanguage
                  : ""
              }
              width={size.width}
              height={size.height}
              title="modellingtool"
            ></iframe>
          </section>
        </div>
      </div>
    </ModelEditorModalContext.Provider>
  );
}

export default ModellingProvider;
