// @flow

import { useState } from "react";

import { ModelContext } from "./ModelContext";
import ModelDataService from "@kea-mod/common/axios/services/ModelDataService";

import { emptyModel } from "../types/Model";

type Props = {
  children: any;
};

export const ModelContextProvider = (props: Props) => {
  const [models, setModels] = useState([]);
  const [model, setModel] = useState(emptyModel);

  const updateModels = (exerciseId: any, modelType: any) => {
    ModelDataService.getAll(exerciseId, modelType)
      .then((response: any) => {
        if (response.status !== 200) return;
        setModels(response.data.data);

        setselectedModel(response.data.data);
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  const setselectedModel = (Models: any) => {
    let m = 0;

    for (let i = 1; i < Models.length; i++) {
      if (Models[m].createdAt <= Models[i].createdAt) {
        m = i;
      }
    }

    if (Models[m]) {
      setModel(Models[m]);
    }
  };

  const createModel = (data: any) => {
    ModelDataService.create(data)
      .then((response: any) => {
        if (response.status !== 200) return;
        //updateModels();
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  const deleteModel = (id: any, exerciseId: any, modelType: any) => {
    ModelDataService.delete(id)
      .then((response: any) => {
        console.log(response);
        if (response.data.success) {
          updateModels(exerciseId, modelType);
        } else {
          /** TODO SHOULD HANDLE UNSUCCESSFULL DELETING MOdel HERE. */
        }
      })
      .catch((e: any) => {
        /** TODO SHOULD HANDLE ERROR DELETING MOdel. */
        console.warn(e);
      });
  };

  const updateModel = (id: any, data: any) => {
    ModelDataService.updateModel(id, data)
      .then((response: any) => {
        if (response.data.success) {
          //updateModels();
          console.log(response);
        } else {
          /** TODO SHOULD HANDLE UNSUCCESSFULL DELETING ASSIGNMENT HERE. */
        }
      })
      .catch((e: any) => {
        /** TODO SHOULD HANDLE ERROR DELETING ASSIGNMENT. */
        console.warn(e);
      });
  };

  return (
    <ModelContext.Provider
      value={{
        models: models,
        model: model,
        create: createModel,
        update: updateModels,
        delete: deleteModel,
        updateModel: updateModel,
        setModel: setModel,
      }}
    >
      {props.children}
    </ModelContext.Provider>
  );
};
