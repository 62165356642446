// @flow

import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const marginOffset = 90;
const ModelEditorPort = (window as any)._env_.REACT_APP_EDITOR_UI_URL || "https://editor.keamod.de";
const ModelEditorURL = (window as any)._env_.REACT_APP_EDITOR_UI_PORT || "443";

export type PostLanguageMessage = {
  type: string;
  language: string;
};

export type PostKeydownMessage = {
  type: string;
  ctrlKey: boolean;
  key: string;
};

export const ModellingTool = (): any => {
  const { i18n } = useTranslation();

  useEffect(() => {
    function sendKeydownEventAsPostMessage(e: any) {
      // Should be HTMLIFrameElement, but apparantly flow has no type-conversion like Typescript (there the syntax would be "document.getElementById("modellingtool") as HTMLIFrameElement))
      const element: any = document.getElementById("modellingtool");

      if (element !== null) {
        const message: PostKeydownMessage = {
          type: "keydown",
          ctrlKey: e.ctrlKey,
          key: e.key,
        };
        element.contentWindow.postMessage(message, "*");
      }
    }

    window.addEventListener("keydown", sendKeydownEventAsPostMessage);

    return () => {
      window.removeEventListener("scroll", sendKeydownEventAsPostMessage);
    };
  }, []);

  useEffect(() => {
    // Currently, the modelling tool and StudentUI/AuthorUI are using different lnguage identifiers. Should be unified.
    if (i18n.language === "de-DE") {
      sendLanguageAsPostMessage("de");
      return;
    }
    if (i18n.language === "en-US") {
      sendLanguageAsPostMessage("en");
      return;
    }
  }, [i18n.language]);

  const sendLanguageAsPostMessage = (language: any) => {
    // Should be HTMLIFrameElement, but apparantly flow has no type-conversion like Typescript (there the syntax would be "document.getElementById("modellingtool") as HTMLIFrameElement))
    const element: any = document.getElementById("modellingtool");
    if (element !== null) {
      const message: PostLanguageMessage = {
        type: "language",
        language: language,
      };
      element.contentWindow.postMessage(message, "*");
    }
  };

  // Bulma does not support fullheight. Therefore, the calculation is needed as inline property.
  return (
    <div
      className="box is-centered is-fullwidth m-4 mx-4"
      style={{ height: `calc(100vh - ${marginOffset}px`, marginTop: "4.25rem" }}
    >
      <iframe
        src={ModelEditorPort + ":" + ModelEditorURL + "/app"}
        title="Modelling Tool"
        id="modellingtool"
        style={{
          width: "100%",
          height: "100%",
        }}
      />
    </div>
  );
};
