// @flow

import { Navigate, Route, Routes } from "react-router-dom";
import { Suspense } from "react";
import { AddAssignmentComponent } from "../components/Assignments/AddAssignmentComponent";
import { EditAssignmentPage } from "../pages/EditAssignmentPage";
import { AssignmentList } from "../pages/AssignmentListPage";
import { AssessmentComponent } from "../components/Assessment/AssessmentComponent";
import { ExerciseSubmissionListComponent } from "../components/Assessment/ExerciseSubmissionListComponent";
import { FeedbackCardMC } from "@kea-mod/common/components/feedback-mc.card";
import { FeedbackCardMU } from "@kea-mod/common/components/feedback-mu.card";
import { ModellingTool } from "../components/Models/modelling-tool";
import { AddExerciseContainer } from "../components/Exercises/AddExerciseContainer";
import { EditExerciseContainer } from "../components/Exercises/EditExerciseContainer";
import { ManualAssessment } from "../components/Assessment/ManualAssessmentComponent";
import { SubmissionPage } from "../components/Assessment/SubmissionPage";
import { StudentSubmissionComponent } from "../components/Assessment/StudentSubmissionListComponent";
import { SignIn } from "../components/login-screen";
import { ImpressumPage } from "../pages/Impressum";
import { routes } from "./routesconfig";

export const NewRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate replace to="/assignments" />} />
        <Route path={`${routes.assignments}`} element={<AssignmentList />} />
        <Route path={`${routes.addassignment}`} element={<AddAssignmentComponent />} />
        <Route path={`${routes.addassignmenteditassignment}`} element={<EditAssignmentPage />} />
        <Route path={`${routes.editassignment}`} element={<EditAssignmentPage />} />
        <Route path={`${routes.addexercise}`} element={<AddExerciseContainer />} />
        <Route path={`${routes.editexercise}`} element={<EditExerciseContainer />} />
        <Route path={`${routes.submissions}`} element={<SubmissionPage />} />
        <Route path={`${routes.impressum}`} element={<ImpressumPage />} />
        <Route
          path={`${routes.modellingTool}`}
          element={
            <>
              {" "}
              <Suspense fallback={<div>Loading...</div>}>
                <ModellingTool />
              </Suspense>
            </>
          }
        />
        <Route path="/auth" element={<SignIn />} />
        <Route path={`${routes.manualassess}`} element={<ManualAssessment />} />
        <Route path={`${routes.submissionlist}`} element={<ExerciseSubmissionListComponent />} />
        <Route path={`${routes.studentsubmissions}`} element={<StudentSubmissionComponent />} />
        <Route path={`${routes.assessment}`} element={<AssessmentComponent />} />
        <Route path={`${routes.feedbackMC}`} element={<FeedbackCardMC UI={"authorui"} />} />
        <Route path={`${routes.feedbackMU}`} element={<FeedbackCardMU UI={"authorui"} />} />
      </Routes>
    </>
  );
};
