// @flow

import { useEffect, useState } from "react";

import type { Assignment } from "../../types/Assignment";

import Tooltip from "@kea-mod/common/tooltip";
import { useExerciseContext } from "../../context/ExerciseContext";
import { useNavigate } from "react-router-dom";
import { useModelContext } from "../../context/ModelContext";
import { useTranslation } from "react-i18next";
import { ExerciseList } from "../../pages/ExerciseList";

type Props = {
  activeAssignment: Assignment;
  activeExercises: any[];
  updateAssignment: (id: number, assignment: {}) => void;
  assignmentId: number;
  deleteAssignment: Function;
};

export const EditAssignmentComponent = (props: Props) => {
  const navigate = useNavigate();
  const exerciseContext = useExerciseContext();
  const modelContext = useModelContext();
  const { t } = useTranslation();
  const [workingAssignment, setWorkingAssignment] = useState<Assignment>({
    description: "",
    endDate: "",
    exercises: [],
    id: -1,
    name: "",
    startDate: "",
    quickAccess: -1,
    updatedAt: "",
    autoAssessment: false,
  });

  useEffect(() => {
    exerciseContext.all();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setWorkingAssignment(props.activeAssignment);
  }, [props.activeAssignment, exerciseContext.exercises, modelContext.models]);

  const addExercise = () => {
    navigate("/add-exercise/" + props.assignmentId);
  };

  const goBackToList = () => {
    navigate("/");
  };

  const handleInputChange = (event: any) => {
    setWorkingAssignment({
      ...workingAssignment,
      [event.target.name]: event.target.value,
    });
  };

  const handleCheckboxChange = (event: any) => {
    setWorkingAssignment({
      ...workingAssignment,
      [event.target.name]: event.target.checked,
    });
  };

  const updateAssignment = () => {
    let assignmentToUpdate = {
      description: workingAssignment.description,
      endDate: workingAssignment.endDate,
      name: workingAssignment.name,
      startDate: workingAssignment.startDate,
      autoAssessment: workingAssignment.autoAssessment,
    };
    props.updateAssignment(workingAssignment.id, assignmentToUpdate);
  };

  let exerciseList = (
    <div>
      <ExerciseList assignmentId={props.assignmentId} exercises={props.activeExercises} />
    </div>
  );

  const localTimezone = (str: any) => {
    if (str !== "") {
      var newDate = new Date(str);
      var currentTimeZoneOffset = newDate.getTimezoneOffset() / 60; //get current Timezone Offset
      newDate.setHours(newDate.getHours() - currentTimeZoneOffset); // add Timezone Offset
      newDate.setSeconds(0, 0);
      const finalDate = newDate.toISOString().replace(/:00.000Z/, ""); //remove Seconds and Milliseconds
      return finalDate;
    } else return "";
  };

  return (
    <div className="container mt-6">
      <div className="section">
        <div className="columns is-centered">
          <div className="column is-three-quarters">
            <div className="box">
              <h4 className="title is-4">{t("assignment")}</h4>
              <div className="field">
                <label className="label">{t("name")}</label>
                <input
                  className="input"
                  type="text"
                  name="name"
                  value={workingAssignment.name}
                  onChange={handleInputChange}
                />
              </div>
              <div className="field">
                <label className="label">
                  {t("description")}
                  <Tooltip
                    icon="circle-info"
                    class="icon tooltip no-border is-white   has-tooltip-multiline has-tooltip-top"
                    content={t("descriptionTooltip")}
                  ></Tooltip>
                </label>
                <input
                  className="input"
                  type="text"
                  name="description"
                  value={workingAssignment.description}
                  onChange={handleInputChange}
                />
              </div>

              <div className="field mt-4">
                <label className="label">
                  {t("startDate")}
                  <Tooltip
                    icon="circle-info"
                    class="icon tooltip no-border is-white   has-tooltip-multiline has-tooltip-top"
                    content={t("datesTooltip")}
                  ></Tooltip>
                </label>
                <input
                  className="input"
                  type="datetime-local"
                  name="startDate"
                  value={localTimezone(workingAssignment.startDate)}
                  onChange={handleInputChange}
                />
              </div>
              <div className="field">
                <label className="label">{t("endDate")}</label>
                <input
                  className="input"
                  type="datetime-local"
                  name="endDate"
                  value={localTimezone(workingAssignment.endDate)}
                  onChange={handleInputChange}
                />
              </div>
              <div className="field">
                <div>
                  <div className="label">
                    {t("autoAssessmentAss")}
                    <Tooltip
                      icon="circle-info"
                      class="icon tooltip no-border is-white   has-tooltip-multiline has-tooltip-top"
                      content={t("autoAssessmentAssDesc")}
                    ></Tooltip>
                  </div>
                </div>
                <input
                  name="autoAssessment"
                  type="checkbox"
                  checked={workingAssignment.autoAssessment}
                  onChange={handleCheckboxChange}
                />
              </div>

              <label className="label">
                {t("quickAccessCode")}
                <Tooltip
                  icon="circle-info"
                  class="icon tooltip no-border is-white   has-tooltip-multiline has-tooltip-top"
                  content={t("qacTooltip")}
                ></Tooltip>
              </label>
              <div className="columns">
                <div className="column">{workingAssignment.quickAccess}</div>
              </div>

              <div className="field">
                <label className="label">{t("exercises")}</label>
              </div>
              <div className="columns mt-2 mb-2">
                <button className="button   is-info" onClick={() => addExercise()}>
                  {t("createExercise")}
                </button>
              </div>
              {exerciseList}

              <div className="columns mt-5">
                <button className="button is-danger" onClick={goBackToList}>
                  {t("cancel")}
                </button>
                <button className="button is-normal is-success" onClick={updateAssignment}>
                  {t("save")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
