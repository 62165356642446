// @flow

import { useState } from "react";

import { SchemeContext } from "./SchemeContext";
import SchemeDataService from "../axios/services/scheme.service";

interface Props {
  children: any;
}
export const SchemeContextProvider = (props: Props): any => {
  const [schemes, setSchemes] = useState([]);

  const updateSchemes = () => {
    SchemeDataService.getAll()
      .then((response: any) => {
        if (response.status !== 200) return;
        setSchemes(response.data.data);
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  return (
    <SchemeContext.Provider
      value={{
        schemes: schemes,
        all: updateSchemes,
      }}
    >
      {props.children}
    </SchemeContext.Provider>
  );
};
