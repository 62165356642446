// @flow

import { useEffect, useState } from "react";
import { b64DecodeUnicode, b64EncodeUnicode } from "../../../shared/b64";

import { LanguageField } from "../ExerciseFields/LanguageField";

import { NameField } from "../ExerciseFields/NameField";
import MDEditor from "@uiw/react-md-editor";
import { SchemeField } from "../ExerciseFields/SchemeField";
import Tooltip from "@kea-mod/common/tooltip";
import { emptyExercise } from "../../../types/Exercise";
import { useExerciseContext } from "../../../context/ExerciseContext";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { ModelList } from "../../../pages/Modellist";
import { FeedUpField } from "../ExerciseFields/FeedUpField";
import { FeedForwardPosField } from "../ExerciseFields/FeedForwardPosField";
import { FeedForwardNegField } from "../ExerciseFields/FeedForwardNegField";

type Props = {
  activeExercise: {
    creation: any;
    description: any;
    language: any;
    maxTotalScore: any;
    feedUp: any;
    feedForward_neg: any;
    feedForward_pos: any;
    feedForward_threshold: any;
  };
  activeModels: Object;
  assignmentId: number;
};

export const EditExerciseCreationComponent = (props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  let { id } = useParams();
  const exerciseContext = useExerciseContext();
  const [activeExercise, setActiveExercise] = useState<any>(emptyExercise);
  const [scenario, setScenario] = useState("");
  const [description, setDescription] = useState("");
  const [language, setLanguage] = useState("");
  const [maxScore, setMaxScore] = useState("0");
  const [feedForwardThreshold, setFeedForwardThreshold] = useState("");

  useEffect(() => {
    exerciseContext.all();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setActiveExercise(props.activeExercise);
    setScenario(b64DecodeUnicode(props.activeExercise.creation.scenario));
    setDescription(b64DecodeUnicode(props.activeExercise.description));
    setLanguage(props.activeExercise.language.name);
    setMaxScore(props.activeExercise.maxTotalScore);

    let threshold = props.activeExercise.feedForward_threshold;
    if (threshold !== null) {
      setFeedForwardThreshold(threshold);
    }
  }, [props.activeExercise]);

  const handleChangeLanguage = (event: any) => {
    setLanguage(event.target.value);
  };

  const handleChange = (event: any) => {
    let value = event.target.value;

    setActiveExercise({
      ...activeExercise,
      [event.target.name]: value,
    });
  };

  const handleChangeDescription = (event: any) => {
    setDescription(event);
  };

  const handleChangeScenario = (event: any) => {
    setScenario(event);
  };

  const handleCheckboxChange = (event: any) => {
    setActiveExercise({
      ...activeExercise,
      [event.target.name]: event.target.checked,
    });
  };

  const handleChangeThreshold = (event: any) => {
    let value = event.target.value;
    setFeedForwardThreshold(value);
  };

  const addFeedFields = (data: any) => {
    //only add fields to data if not empty
    if (activeExercise.feedUp !== "") {
      data.feedUp = activeExercise.feedUp;
    }

    if (activeExercise.feedForward_pos !== "") {
      data.feedForward_pos = activeExercise.feedForward_pos;
    }

    if (activeExercise.feedForward_neg !== "") {
      data.feedForward_neg = activeExercise.feedForward_neg;
    }

    if (feedForwardThreshold !== "") {
      data.feedForward_threshold = parseFloat(feedForwardThreshold);
    }
  };

  const updateExercise = async () => {
    if (activeExercise.name.trim() === "" || description.trim() === "" || scenario.trim() === "") {
      alert("Please make sure that all fields have been filled out!");
    } else {
      let data = {
        name: activeExercise.name,
        type: "creation",
        language: language,
        description: b64EncodeUnicode(description),
        scenario: b64EncodeUnicode(scenario),
        schemaId: parseInt(activeExercise.schemaId),
        assignmentId: props.assignmentId,
        directFeedback: activeExercise.directFeedback,
        maxTotalScore: maxScore,
      };

      addFeedFields(data);

      await exerciseContext.update(parseInt(activeExercise.id), data);

      navigate("/assignments/" + props.assignmentId);
    }
  };

  const goBackToAssignment = () => {
    navigate("/assignments/" + props.assignmentId);
  };
  function onChangeMaxScore(e: any) {
    let val = e.target.value;
    const v = e.target.value;
    const myArray = e.target.value.split(".");

    if (myArray[0].toString().length >= 11) {
      return;
    }
    if (myArray.length !== 1 && myArray[1].toString().length >= 3) {
      return;
    }

    if (e.target.validity.valid) {
      val = val.replace(/^0+/, "");
      if (val === "") {
        val = v;
      }

      if (val.startsWith(".")) {
        val = "0" + val;
      }

      setMaxScore(val);
    } else if (val === "" || val === "-") {
      val = val.replace(/^0+/, "");
      if (val === "") {
        val = v;
      }

      if (val.startsWith(".")) {
        val = "0" + val;
      }
      setMaxScore(val);
    }
  }
  let ScoreField = (
    <div>
      <div>
        <input
          size={5}
          className="input"
          type="text"
          value={maxScore}
          onChange={onChangeMaxScore}
          pattern="^[-+]?\d*\.?\d*$"
        />
      </div>
    </div>
  );

  return (
    <div className="container mt-6">
      <div className="section">
        <div className="columns is-centered">
          <div className="column is-three-quarters">
            <div className="box">
              <h4 className="title is-4">{t("exerciseModelCreation")}</h4>
              <NameField activeExercise={activeExercise} h={handleChange} />
              <LanguageField language={language} h={handleChangeLanguage} />
              <div className="field">
                <label className="label" htmlFor="description">
                  {t("description")}
                  <Tooltip
                    icon="circle-info"
                    class="icon tooltip no-border is-white   has-tooltip-multiline has-tooltip-top"
                    content={t("descriptionMCTooltip")}
                  ></Tooltip>
                </label>
                <div data-color-mode="light">
                  <MDEditor height={250} value={description} onChange={handleChangeDescription} />
                </div>
              </div>
              <div className="field">
                <label className="label" htmlFor="scenario">
                  {t("scenario")}
                  <Tooltip
                    icon="circle-info"
                    class="icon tooltip no-border is-white   has-tooltip-multiline has-tooltip-top"
                    content={t("scenarioTooltip")}
                  ></Tooltip>
                </label>
                <div data-color-mode="light">
                  <MDEditor height={250} value={scenario} onChange={handleChangeScenario} />
                </div>
              </div>
              <SchemeField activeExercise={activeExercise} h={handleChange} />
              <FeedUpField activeExercise={activeExercise} h={handleChange} />
              <FeedForwardPosField activeExercise={activeExercise} h={handleChange} />
              <FeedForwardNegField activeExercise={activeExercise} h={handleChange} />
              <div className="field">
                <label className="label" htmlFor="feedForward_threshold">
                  {t("feedForward_threshold")}
                </label>
                <input
                  type="text"
                  className="input"
                  id="feedForward_threshold"
                  name="feedForward_threshold"
                  value={feedForwardThreshold}
                  onChange={handleChangeThreshold}
                />
              </div>
              <div className="field">
                <div>
                  <div className="label">
                    {t("autoAssessmentEx")}
                    <Tooltip
                      icon="circle-info"
                      class="icon tooltip no-border is-white   has-tooltip-multiline has-tooltip-top"
                      content={t("autoAssessmentExDesc")}
                    ></Tooltip>
                  </div>
                </div>
                <input
                  name="directFeedback"
                  type="checkbox"
                  checked={activeExercise.directFeedback}
                  onChange={handleCheckboxChange}
                />
              </div>
              <div className="field">
                <div className="label">
                  {t("MaxScore")}
                  <Tooltip
                    icon="circle-info"
                    class="icon tooltip no-border is-white   has-tooltip-multiline has-tooltip-top"
                    content={t("maxscoreTooltip")}
                  ></Tooltip>
                </div>
                <div>{ScoreField}</div>{" "}
              </div>
              <div className="field">
                <label className="label">
                  {t("sampleSolution")}
                  <Tooltip
                    icon="circle-info"
                    class="icon tooltip no-border is-white   has-tooltip-multiline has-tooltip-top"
                    content={t("samplesolutionsTooltip")}
                  ></Tooltip>
                </label>
                <ModelList
                  modelList={props.activeModels}
                  modelingLanguage={language}
                  exerciseId={id}
                  exerciseType={activeExercise.type}
                  modelType={"sampleSolution"}
                />
              </div>

              <div className="columns mt-5">
                <button className="button is-danger" onClick={goBackToAssignment}>
                  {t("cancel")}
                </button>
                <button className="button is-success" onClick={updateExercise}>
                  {t("save")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
