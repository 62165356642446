// @flow

import { useState, useEffect } from "react";
import { useSubmissionContext } from "../../context/SubmissionContext";
import { useSchemeContext } from "../../context/SchemeContext";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import ExerciseDataService from "../../axios/services/exercise.service";
import { useExerciseContext } from "../../context/ExerciseContext";
import ConfirmButton from "../../shared/components/ConfirmButton";
import Tooltip from "@kea-mod/common/tooltip";
import { FaRedo } from "react-icons/fa";

const dateOptions = {
  weekday: "short",
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
} as any;

export const ExerciseSubmissionListComponent = () => {
  const { t, i18n } = useTranslation();
  let { exerciseId } = useParams(); // exerciseId
  const SubmissionContext = useSubmissionContext();
  const exerciseContext = useExerciseContext();
  const SchemeContext = useSchemeContext();
  const navigate = useNavigate();
  const [assessmentMap, setAssessmentMap] = useState({}) as any;
  const [refresh, setRefresh] = useState(true);
  const [refresh2, setRefresh2] = useState(true);
  const [sum_totalscore, setSum_totalscore] = useState(0.0);
  const [sum_maxscore, setSum_maxscore] = useState(0);
  const [scheme, setScheme] = useState({}) as any;
  const [currentSubmissionsForExercise, setCurrentSubmissionsForExercise] = useState([]) as any;

  useEffect(() => {}, [refresh]);

  useEffect(() => {
    getSubmissionsForExercise();
    SchemeContext.all();
    exerciseContext.all();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSubmissionsForExercise = async () => {
    let allSubmissionsForExercise = await SubmissionContext.getAllSubmissionsForExercise(
      parseInt(exerciseId as string),
    );
    setCurrentSubmissionsForExercise(allSubmissionsForExercise);
  };

  useEffect(() => {
    const submissionsForExercise = SubmissionContext.submissionsForExercise;
    if (submissionsForExercise === undefined) return;
    setCurrentSubmissionsForExercise(submissionsForExercise);

    if (submissionsForExercise.length !== 0) {
      checkforCompleteAssessment();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SubmissionContext.submissionsForExercise]);

  const getScheme = async () => {
    var schemaId = currentSubmissionsForExercise[0].exercise.schemaId;
    for (var i = 0; i < SchemeContext.schemes.length; i++) {
      if (schemaId === SchemeContext.schemes[i].id) {
        setScheme(SchemeContext.schemes[i]);
        break;
      }
    }
    setRefresh(!refresh);
  };

  const checkforCompleteAssessment = async () => {
    var bar = assessmentMap as any;
    for (var i = 0; i < currentSubmissionsForExercise.length; i++) {
      var submission = currentSubmissionsForExercise[i];

      let ex_type = currentSubmissionsForExercise[i].exercise.type;
      var booli = true;
      let assessment = (await SubmissionContext.getAssessment(submission.id)) as any;
      let as = { totalscore: "-", maxTotalScore: "-" } as any;

      if (!("message" in assessment && assessment["message"] === "incomplete")) {
        as["maxTotalScore"] = assessment.maxTotalScore;
      }

      as["totalscore"] = "-";

      if (assessment.status === "complete") {
        as = assessment;
        booli = false;
        if (ex_type === "creation") {
          as["totalscore"] = parseFloat((as["totalscore"] * as["maxTotalScore"]).toFixed(2));
        }
      }

      bar[submission.id] = { last_complete_assessment: as, status: booli };
    }
    setAssessmentMap(bar);
    setRefresh(!refresh);
    setRefresh2(!refresh2);
  };

  useEffect(() => {
    computeSumScores();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    if (currentSubmissionsForExercise.length !== 0) {
      getScheme();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh2]);

  const computeSumScores = () => {
    const keys = Object.keys(assessmentMap);
    let totalmax: any = 0.0;
    let maxmax: any = 0.0;
    let counter = 0;
    let countermax = 0;
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      let obj = assessmentMap[key]["last_complete_assessment"];

      if (obj.maxTotalScore === "-" || obj.maxTotalScore === undefined) {
        continue;
      }
      maxmax = maxmax + obj.maxTotalScore;
      countermax = countermax + 1;
      if (!("gradings" in obj)) {
        continue;
      }
      counter = counter + 1;

      totalmax = totalmax + parseFloat(obj.totalscore.toFixed(2));
    }

    if (counter === 0 || countermax === 0) {
      totalmax = "-";
      maxmax = "-";
    } else {
      totalmax = parseFloat((totalmax / counter).toFixed(2));
      maxmax = parseFloat((maxmax / countermax).toFixed(2));
    }

    setSum_totalscore(totalmax);
    setSum_maxscore(maxmax);
  };

  const showSubmissions = (submission: any) => {
    navigate("/subpreview/" + submission.id);
  };

  const showAssessment = async (submission: any) => {
    localStorage.setItem("originStudentTable", ""); //empty string corresponds to false (boolean) in OrientationBar.jsx
    let submissionId = submission.id;
    let assessment = assessmentMap[submissionId]["last_complete_assessment"];
    for (var i = 0; i < currentSubmissionsForExercise.length; i++) {
      if (currentSubmissionsForExercise[i].id === submission.id) {
        let name = currentSubmissionsForExercise[i].user.token;
        if (currentSubmissionsForExercise[i].user.matriculationNumber) {
          name = currentSubmissionsForExercise[i].user.givenName + " " + currentSubmissionsForExercise[i].user.familyName + " (" + currentSubmissionsForExercise[i].user.matriculationNumber + ")";
        }
        localStorage.setItem("manual_revision_user", name);
      }
    }

    if (submission.type === "creation") {
      navigate("/assessments-mc/" + submissionId + "/" + exerciseId + "/" + assessment.id);
    } else {
      navigate("/assessments-mu/" + submissionId + "/" + exerciseId + "/" + assessment.id);
    }
  };

  const manualAssessment = async (submission: any) => {
    localStorage.setItem("originStudentTable", ""); //empty string corresponds to false (boolean) in OrientationBar.jsx
    for (var i = 0; i < currentSubmissionsForExercise.length; i++) {
      if (currentSubmissionsForExercise[i].id === submission.id) {
        let name = currentSubmissionsForExercise[i].user.token;
        if (currentSubmissionsForExercise[i].user.matriculationNumber) {
          name = currentSubmissionsForExercise[i].user.givenName + " " + currentSubmissionsForExercise[i].user.familyName + " (" + currentSubmissionsForExercise[i].user.matriculationNumber + ")";
        }
        localStorage.setItem("manual_revision_user", name);
      }
    }

    if (submission.id in assessmentMap && Object.keys(assessmentMap[submission.id]).length > 0) {
      navigate("/manual-assessment/" + submission.id + "/" + exerciseId);
    }
  };

  const StartAssessmentForEntry = (id: any) => {
    delete assessmentMap[id];
    SubmissionContext.startAssessment(id);
    setRefresh(!refresh);
  };

  function getSubmissionTableItem(submission: any) {
    let name = submission.user.token;
    console.log(submission);
    if (submission.user.matriculationNumber) {
      name = submission.user.givenName + " " + submission.user.familyName + " (" + submission.user.matriculationNumber + ")";
    }
    return (
      <tr key={"Submission" + submission.id}>
        <th>{name}</th>
        <th>{new Date(submission.createdAt).toLocaleDateString(i18n.language, dateOptions)}</th>
        <td className="has-text-center has-text-weight-bold">
          {submission.id in assessmentMap &&
          "last_complete_assessment" in assessmentMap[submission.id] &&
          assessmentMap[submission.id]["last_complete_assessment"].maxTotalScore !== undefined
            ? assessmentMap[submission.id]["last_complete_assessment"].totalscore +
              "/" +
              assessmentMap[submission.id]["last_complete_assessment"].maxTotalScore
            : "-/-"}
        </td>
        <td>
          <button className="button is-info" onClick={() => showSubmissions(submission)}>
            {t("submissions")}
          </button>
          <ConfirmButton
            id={submission.id}
            name={submission.user.token}
            proceedAction={StartAssessmentForEntry}
            question={`startAssessmentSingleQuestion`}
            explanation={`startAssessmentSingleExplanation`}
            color="button is-primary"
            buttonLabel={`startAssessment`}
          />

          <button
            className="button is-success"
            disabled={
              submission.id in assessmentMap && "status" in assessmentMap[submission.id]
                ? assessmentMap[submission.id]["status"]
                : true
            }
            onClick={() => manualAssessment(submission)}
          >
            {t("ManualAssessment")}
          </button>
          <button
            className="button is-info"
            disabled={
              submission.id in assessmentMap && "status" in assessmentMap[submission.id]
                ? assessmentMap[submission.id]["status"]
                : true
            }
            onClick={() => showAssessment(submission)}
          >
            {t("showAssessment")}
          </button>
        </td>
        <td className="has-text-center has-text-weight-bold">
          {submission.id in assessmentMap &&
          "last_complete_assessment" in assessmentMap[submission.id] &&
          assessmentMap[submission.id]["last_complete_assessment"].isRevised !== undefined ? (
            assessmentMap[submission.id]["last_complete_assessment"].isRevised ? (
              <input type="checkbox" checked disabled />
            ) : (
              <input type="checkbox" disabled />
            )
          ) : (
            ""
          )}
        </td>
      </tr>
    );
  }

  function getSubmissionTable() {
    return (
      <div className="table-container">
        <table className="table is-striped is-narrow is-hoverable is-fullwidth">
          <thead className="is-italic">
            <tr>
              <td>{t("user")}</td>
              <td>{t("created")}</td>
              <td>{t("totalScore") + "/" + t("maxTotalScore")}</td>
              <td>{t("options")}</td>
              <td>{t("Revised")}</td>
            </tr>
          </thead>
          <tbody>
            {currentSubmissionsForExercise.map((submission: any) => {
              return getSubmissionTableItem(submission);
            })}
            <tr>
              <td></td>
              <td></td>
              <td className="has-text-center has-text-weight-bold">
                {sum_totalscore}/{sum_maxscore} {t("avgScores")}
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  function getEmptyMessageSubmission() {
    return <p className="is-size-7">{t("noSubmissions")} </p>;
  }

  let renderSubmissionTable = (
    <div className="card my-2">
      <div className="card-content">
        <div className="columns">
          <div className="column has-text-left">
            {currentSubmissionsForExercise.length > 0 && getSubmissionTable()}
            {currentSubmissionsForExercise.length === 0 && getEmptyMessageSubmission()}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="section">
      <div className="card mt-6">
        <header className="card-header">
          <p className="card-header-title">{t("submissions")}</p>
        </header>
        <div className="card-content">
          <div className="content">
            <div className="columns">
              <div className="column has-text-weight-light is-italic">
                {t("allSubmissionsForExercise")}
                {t("currentScheme") + scheme.name + ". "}
                {t("explainButton")}
                <br />
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <ConfirmButton
                  id={exerciseId}
                  name={localStorage.getItem("exerciseName")}
                  proceedAction={ExerciseDataService.assessAllExercises}
                  question={`startAssessmentQuestion`}
                  explanation={`startAssessmentExplanation`}
                  color="button is-primary mt-3 ml-0"
                  buttonLabel={`assessAllSubmissions`}
                />
              </div>
              <div className="column has-text-right has-text-bottom">
                <Tooltip
                  icon="circle-info"
                  class="mt-3 icon tooltip no-border is-white has-tooltip-multiline has-tooltip-top"
                  content={t("CheckfornewFeedback")}
                ></Tooltip>
                <div className="button mt-3 has-text-right is-primary" onClick={() => checkforCompleteAssessment()}>
                  <FaRedo />
                </div>
                <br />
              </div>
            </div>
            {renderSubmissionTable}
          </div>
        </div>
      </div>
    </div>
  );
};
