// @flow

import { createContext, useContext } from "react";
import type { Scheme } from "../types/Scheme";

type Context = {
  schemes: Scheme[];
  all: () => void;
};

const defaultState: Context = {
  schemes: [],
  all: () => console.warn("SchemeContext: not in scope."),
};

export const SchemeContext = createContext<Context>(defaultState);

export const useSchemeContext = () => useContext(SchemeContext);
