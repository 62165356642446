// @flow

import { ChangeEvent, useEffect, useState } from "react";

import Tooltip from "@kea-mod/common/tooltip";
import { useAssignmentContext } from "../../context/AssignmentContext";
import { useExerciseContext } from "../../context/ExerciseContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const AddAssignmentComponent = (): any => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const assignmentContext = useAssignmentContext();
  const exerciseContext = useExerciseContext();
  const [assignmentCreated, setAssignmentCreated] = useState(false);
  const [newAssignment, setNewAssignment] = useState({
    name: "",
    description: "",
    startDate: "",
    endDate: "",
    autoAssessment: false,
  });

  useEffect(() => {
    assignmentContext.updateAssignments();
    exerciseContext.all();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNewAssignment({
      ...newAssignment,
      [event.target.name]: event.target.value,
    });
  };

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNewAssignment({
      ...newAssignment,
      [event.target.name]: event.target.checked,
    });
  };

  const createAssignment = async () => {
    if (
      newAssignment.name.trim() === "" ||
      newAssignment.description.trim() === "" ||
      newAssignment.startDate.trim() === "" ||
      newAssignment.endDate.trim() === ""
    ) {
      alert("Please make sure that all fields have been filled out");
    } else {
      let assignmentId = await assignmentContext.createAssignment(newAssignment);
      setAssignmentCreated(true);
      navigate("assignments/" + assignmentId); // change to edit-assignment component
    }
  };

  const showCreateButton = () => {
    if (!assignmentCreated) {
      return (
        <div className="columns mt-1">
          <button className="button is-normal is-success" onClick={createAssignment}>
            {t("create")}
          </button>
        </div>
      );
    }
    return <></>;
  };

  return (
    <div className="container mt-6">
      <div className="section">
        <div className="columns is-centered">
          <div className="column is-three-quarters">
            <div className="box">
              <h4 className="title is-4">{t("assignment")}</h4>
              <div className="field">
                <label className="label">{t("name")}</label>
                <input
                  className="input"
                  type="text"
                  name="name"
                  value={newAssignment.name}
                  onChange={handleInputChange}
                />
              </div>
              <div className="field">
                <label className="label">
                  {t("description")}
                  <Tooltip
                    icon="circle-info"
                    class="icon tooltip no-border is-white has-tooltip-multiline has-tooltip-top"
                    content={t("descriptionTooltip")}
                  ></Tooltip>
                </label>
                <input
                  className="input"
                  type="text"
                  name="description"
                  value={newAssignment.description}
                  onChange={handleInputChange}
                />
              </div>
              <div className="field">
                <label className="label">
                  {t("startDate")}
                  <Tooltip
                    icon="circle-info"
                    class="icon tooltip no-border is-white has-tooltip-multiline has-tooltip-top"
                    content={t("datesTooltip")}
                  ></Tooltip>
                </label>
                <input
                  className="input"
                  type="datetime-local"
                  name="startDate"
                  value={newAssignment.startDate}
                  onChange={handleInputChange}
                />
              </div>
              <div className="field">
                <label className="label">{t("endDate")}</label>
                <input
                  className="input"
                  type="datetime-local"
                  name="endDate"
                  value={newAssignment.endDate}
                  onChange={handleInputChange}
                />
              </div>
              <div className="field">
                <div>
                  <div className="label">
                    {t("autoAssessmentAss")}
                    <Tooltip
                      icon="circle-info"
                      class="icon tooltip no-border is-white   has-tooltip-multiline has-tooltip-top"
                      content={t("autoAssessmentAssDesc")}
                    ></Tooltip>
                  </div>
                </div>
                <input
                  name="autoAssessment"
                  type="checkbox"
                  checked={newAssignment.autoAssessment}
                  onChange={handleCheckboxChange}
                />
              </div>
              <div className="mt-4">{showCreateButton()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
