import Tooltip from "@kea-mod/common/tooltip";
import { Assessment } from "@kea-mod/common/types/Assessment";
import { useTranslation } from "react-i18next";

type Props = {
  activeAssessment: Assessment;
  h: Function;
};

export const MaxscoreField = (props: Props) => {
  const { t } = useTranslation();
  const onChange = (event: any) => {
    props.h(event);
  };

  return (
    <div className="field">
      <label className="label" htmlFor="totalscore">
        {t("maxTotalScore")}
        <Tooltip
          icon="circle-info"
          class="icon tooltip no-border is-white   has-tooltip-multiline has-tooltip-top"
          content={t("maxscoreAssTooltip")}
        ></Tooltip>
      </label>
      <input
        type="text"
        className="input"
        value={props.activeAssessment.maxTotalScore}
        onChange={onChange}
        pattern="^[-+]?\d*\.?\d*$"
      />
    </div>
  );
};
