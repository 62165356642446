import http from "../http-common";

class FeedbackDataService {
  create(data) {
    return http.post("authorui/feedbacks", data);
  }
  getAll() {
    return http.get("authorui/feedbacks");
  }
  getFeed(id) {
    return http.get(`/authorui/feedbacks/${id}`);
  }

  getManFeed(id) {
    return http.get(`/authorui/manualfeedbacks/${id}`);
  }

  getAllManual() {
    return http.get("authorui/manualfeedbacks");
  }

  delete(id) {
    return http.delete(`authorui/feedbacks/${id}`);
  }

  update(id, data) {
    return http.put(`authorui/feedbacks/${id}`, data);
  }

  createManual(data) {
    return http.post("authorui/manualfeedbacks", data);
  }

  deleteManual(id) {
    return http.delete(`authorui/manualfeedbacks/${id}`);
  }

  updateManual(id, data) {
    return http.put(`authorui/manualfeedbacks/${id}`, data);
  }
}

export default new FeedbackDataService();
