import http from "../http-common";

class SubmissionExerciseDataService {
  getAllSubmissions(assignmentId) {
    return http.get(`/authorui/submissions`, {
      params: {
        submitted: true,
        assignmentId: assignmentId,
      },
    });
  }

  getAllSubmissionsForUser(assignmentId, userId) {
    return http.get(`/authorui/submissions`, {
      params: {
        submitted: true,
        assignmentId: assignmentId,
        userId: userId,
      },
    });
  }

  getAllSubmissionsForExercise(id) {
    return http.get(`/authorui/submissionexercises`, {
      params: { exerciseId: id },
    });
  }

  get(id) {
    return http.get(`/authorui/submissionexercises/${id}/assessments`);
  }

  getSubmissionExercise(id) {
    return http.get(`/authorui/submissionexercises/${id}`);
  }

  create(id) {
    console.log(`Call RQM to assess id ${id}`);
    return http.post(`/authorui/submissionexercises/${id}/assessments`);
  }
}

export default new SubmissionExerciseDataService();
