// @flow
import { useTranslation } from "react-i18next";

type Props = {
  openModellingTool: () => void;
};

export const AddModelCard = (props: Props) => {
  const { t } = useTranslation();
  return (
    <div className="column is-half">
      <div
        onClick={props.openModellingTool}
        className="box m-0 p-0 is-clipped is-relative has-background-primary"
        style={{ height: "150px" }}
      >
        <div
          onClick={props.openModellingTool}
          className="has-text-centered has-text-white is-size-5"
          style={{
            position: "absolute",
            bottom: "8px",
            left: 0,
            right: 0,
            margin: "auto",
          }}
        >
          {t("addModel")}
        </div>
      </div>
    </div>
  );
};
