class SessionService {
  cookie = undefined;

  constructor() {
    this.cookie = this.getCookie(`user.info`);
  }

  getCookie(name) {
    const cookieText = document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`)?.pop() || ``;
    let parsedCookie = undefined;
    try {
      parsedCookie = JSON.parse(decodeURIComponent(cookieText));
    } catch (e) {
      console.log(`Required cookie not found`);
    }
    return parsedCookie;
  }

  getRole() {
    this.cookie = this.getCookie(`user.info`);
    if (this.cookie === undefined) {
      return undefined;
    }
    return this.cookie.roles[0];
  }

  getName() {
    this.cookie = this.getCookie(`user.info`);
    if (this.cookie === undefined) {
      return undefined;
    }
    return this.cookie.user;
  }
}

export default new SessionService();
