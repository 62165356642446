// @flow
import { useEffect, useState } from "react";

import { EditExerciseCreationComponent } from "./ModelCreation/EditExerciseCreationComponent";
import { EditExerciseUnderstandingComponent } from "./ModelUnderstanding/EditExerciseUnderstandingComponent";
import { emptyExercise } from "../../types/Exercise";
import { useExerciseContext } from "../../context/ExerciseContext";
import { useModelContext } from "../../context/ModelContext";
import { useParams } from "react-router";

export const EditExerciseContainer = () => {
  let { id } = useParams();
  const exerciseContext = useExerciseContext();
  const modelContext = useModelContext();
  const [activeExercise, setActiveExercise] = useState(emptyExercise);
  const [activeModels, setActiveModels] = useState([]);
  const [activeAnswers, setActiveAnswers] = useState([]);

  useEffect(() => {
    exerciseContext.all();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getActiveExercise = () => {
      if (id === undefined) return;
      const exercise = exerciseContext.exercises.find((exercise: any) => exercise.id === parseInt(id as string));
      return exercise;
    };

    const exercise = getActiveExercise();
    if (exercise === undefined) return;

    if (exercise.type === "understanding") {
      exerciseContext.updateAllAnswers(parseInt(id as string));
    }

    setActiveExercise(exercise);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exerciseContext.exercises, id]);

  useEffect(() => {
    const getActiveModels = () => {
      if (id === undefined) return;
      else {
        const models = modelContext.models.filter((model: any) => model.parentId === parseInt(id as string));
        return models;
      }
    };

    const models = getActiveModels() as any;
    if (models === undefined) return;

    setActiveModels(models);
  }, [modelContext.models, id]);

  useEffect(() => {
    if (id === undefined) return;

    const answers = exerciseContext.answers as any;
    if (answers === undefined) return;

    setActiveAnswers(answers);
  }, [exerciseContext.answers, id]);

  const exerciseTypeCheck = () => {
    if (activeExercise.type === "creation") {
      return (
        <EditExerciseCreationComponent
          activeExercise={activeExercise}
          activeModels={activeModels}
          assignmentId={activeExercise.assignmentId}
        />
      );
    } else if (activeExercise.type === "understanding") {
      return (
        <EditExerciseUnderstandingComponent
          activeExercise={activeExercise}
          activeAnswers={activeAnswers}
          activeModels={activeModels}
          assignmentId={activeExercise.assignmentId}
        />
      );
    }
    return <></>;
  };
  return <div>{exerciseTypeCheck()}</div>;
};
