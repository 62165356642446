import axios from "axios";
import authService from "./services/auth.service";

const axiosInstance = axios.create({
  baseURL:
    (window as any)._env_.REACT_APP_REQUESTMANAGEMENT_URL +
    ":" +
    (window as any)._env_.REACT_APP_REQUESTMANAGEMENT_PORT +
    "/api/v1",
  headers: {
    Accept: "application/json",
    "Content-type": "application/json",
  },
  withCredentials: true,
});

axiosInstance.interceptors.request.use(function (config) {
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const url = (window as any)._env_.REACT_APP_AUTHOR_UI_URL;
    if (!url) return error;

    if (error.response.status === 401 || error.response.status === 403) {
      // TO DO : Destroy cookie
      authService.signOut();
    }
    return error;
  },
);

export default axiosInstance;
