import { useTranslation } from "react-i18next";
import type { Exercise } from "../../../types/Exercise";

type Props = {
  activeExercise: Exercise;
  h: Function;
};

export const NameField = (props: Props) => {
  const { t } = useTranslation();

  const onChange = (event: any) => {
    props.h(event);
  };

  return (
    <div className="field">
      <label className="label" htmlFor="name">
        {t("name")}
      </label>
      <input
        type="text"
        className="input"
        id="name"
        name="name"
        value={props.activeExercise.name}
        onChange={onChange}
      />
    </div>
  );
};
